import React, { useCallback, useContext } from 'react';
import { FilePreviewerContext } from 'features/Views/Conversations/context/FilePreviewerProvider/FilePreviewerContext';
// Components/ui
import { Box, TextField } from '@mui/material';

const MessageInput = () => {
  const { selectedFile, handleFileMsgChange } = useContext(FilePreviewerContext);

  const handleFileMsgChangeMemoized = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFileMsgChange(event, selectedFile?.id);
    },
    [handleFileMsgChange, selectedFile?.id]
  );
  return (
    <Box
      sx={{
        width: '65%',
        height: '50%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <TextField
        value={selectedFile?.caption}
        onChange={handleFileMsgChangeMemoized}
        size="small"
        placeholder="Escribe un mensaje"
        variant="outlined"
        fullWidth
        sx={{
          borderRadius: '13px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
          },
          border: '1px solid lightgray',
        }}
      />
    </Box>
  );
};

export default MessageInput;
