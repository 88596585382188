import { INavIcon } from '../types/types';

import InboxIcon from '@mui/icons-material/Inbox';

export const iconsData: INavIcon[] = [
  {
    title: 'navBar.conversations',
    to: '/a/chat/conversations',
    icon: <InboxIcon />,
  },
];
