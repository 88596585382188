// Redux
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import InitRequestDataReturn from "redux/types/InitRequestDataReturn";
import { initRequestData } from "redux/functions/initRequestData";
// Services
import quickResponseService from "./quickResponseService";
// Types
import { QuickResponseState } from "./types/QuickResponseState";
import { IQuickResponse, IQuickResponseCategory } from "@trii/types/dist/Conversations";
import { RootState } from "redux/store";
import { CreateQuickResponse } from "./types/CreateQuickResponse";
import { CreateCategory } from "./types/CreateCategory";

const initialState: QuickResponseState = {
  quickResponses: [],
  quickResponse: null,
  categories: [],
  category: null,
  status: {
    quickResponses: 'idle',
    quickResponse: 'idle',
    categories: 'idle',
    category: 'idle',
    create: 'idle',
    update: 'idle',
  },
}

// Quick Responses
export const fetchQuickResponses = createAsyncThunk(
  'conversations/fetchQuickResponses',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await quickResponseService.fetchQuickResponses(
      jwtToken,
      URL_CONVERSATIONS,
    );

    return response;
  }
);

export const fetchQuickResponseById = createAsyncThunk(
  'conversations/fetchQuickResponseById',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await quickResponseService.fetchQuickResponseById(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const fetchCreateQuickResponse = createAsyncThunk(
  'conversations/fetchCreateQuickResponse',
  async (data: CreateQuickResponse, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await quickResponseService.fetchCreateQuickResponse(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);

export const fetchUpdateQuickResponse = createAsyncThunk(
  'conversations/fetchUpdateQuickResponse',
  async (data: IQuickResponse, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await quickResponseService.fetchUpdateQuickResponse(
      jwtToken,
      URL_CONVERSATIONS,
      data,
    );

    return response;
  }
);

export const fetchDeleteQuickResponse = createAsyncThunk(
  'conversations/fetchDeleteQuickResponse',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    await quickResponseService.fetchDeleteQuickResponse(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return id;
  }
);

// Categories

export const fetchCategories = createAsyncThunk(
  'conversations/fetchCategories',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await quickResponseService.fetchCategories(
      jwtToken,
      URL_CONVERSATIONS,
    );

    return response;
  }
);

export const fetchCategoryById = createAsyncThunk(
  'conversations/fetchCategoriesById',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await quickResponseService.fetchCategoryById(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const fetchCreateCategory = createAsyncThunk(
  'conversations/fetchCreateCategory',
  async (data: CreateCategory, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await quickResponseService.fetchCreateCategory(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);

export const fetchUpdateCategory = createAsyncThunk(
  'conversations/fetchUpdateCategory',
  async (data: IQuickResponseCategory, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await quickResponseService.fetchUpdateCategory(
      jwtToken,
      URL_CONVERSATIONS,
      data,
    );

    return response;
  }
);

export const fetchDeleteCategory = createAsyncThunk(
  'conversations/fetchDeleteCategory',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    await quickResponseService.fetchDeleteCategory(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return id;
  }
);

const quickResponseSlice = createSlice({
  name: 'quickResponse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Quick Responses
      .addCase(fetchQuickResponses.pending, (state) => {
        state.status.quickResponses = 'loading';
      })
      .addCase(
        fetchQuickResponses.rejected,
        (state, action) => {
          state.status.quickResponses = 'rejected';
          console.log('fetchQuickResponses rejected: ', action.payload);
        })
      .addCase(
        fetchQuickResponses.fulfilled,
        (state, action: PayloadAction<IQuickResponse[]>) => {
          state.status.quickResponses = 'succeeded';
          state.quickResponses = action.payload;
          console.log('fetchQuickResponses succeeded: ', action.payload);
        })
      .addCase(fetchQuickResponseById.pending, (state) => {
        state.status.quickResponse = 'loading';
      })
      .addCase(
        fetchQuickResponseById.rejected,
        (state, action) => {
          state.status.quickResponse = 'rejected';
          console.log('fetchQuickResponseById rejected: ', action.payload);
        })
      .addCase(
        fetchQuickResponseById.fulfilled,
        (state, action: PayloadAction<IQuickResponse>) => {
          state.status.quickResponse = 'succeeded';
          state.quickResponse = action.payload;
          console.log('fetchQuickResponseById succeeded: ', action.payload);
        })
      .addCase(fetchCreateQuickResponse.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(
        fetchCreateQuickResponse.rejected,
        (state, action) => {
          state.status.create = 'rejected';
          console.log('fetchCreateQuickResponse rejected: ', action.payload);
        })
      .addCase(
        fetchCreateQuickResponse.fulfilled,
        (state, action: PayloadAction<IQuickResponse>) => {
          state.status.create = 'succeeded';
          const response = action.payload
          state.quickResponses.push(response);
          console.log('fetchCreateQuickResponse succeeded: ', response);
        })
      .addCase(fetchUpdateQuickResponse.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(
        fetchUpdateQuickResponse.rejected,
        (state, action) => {
          state.status.update = 'rejected';
          console.log('fetchUpdateQuickResponse rejected: ', action.payload);
        })
      .addCase(
        fetchUpdateQuickResponse.fulfilled,
        (state, action: PayloadAction<IQuickResponse>) => {
          state.status.update = 'succeeded';
          const response = state.quickResponses.map((res) => {
            if (res.id === action.payload.id) {
              return action.payload;
            }
            return res;
          })
          state.quickResponses = response;
          console.log('fetchUpdateQuickResponse succeeded: ', action.payload);
        })
      .addCase(fetchDeleteQuickResponse.pending, (state) => {
        state.status.quickResponse = 'loading';
      })
      .addCase(
        fetchDeleteQuickResponse.rejected,
        (state, action) => {
          state.status.quickResponse = 'rejected';
          console.log('fetchDeleteQuickResponse rejected: ', action.payload);
        })
      .addCase(
        fetchDeleteQuickResponse.fulfilled,
        (state, action) => {
          state.status.quickResponse = 'succeeded';
          const filteredResponses = state.quickResponses.filter((res) => res.id !== action.payload);
          state.quickResponses = filteredResponses;
        })
      // Categories
      .addCase(fetchCategories.pending, (state) => {
        state.status.categories = 'loading';
      })
      .addCase(
        fetchCategories.rejected,
        (state, action) => {
          state.status.categories = 'rejected';
          console.log('fetchCategories rejected: ', action.payload);
        })
      .addCase(
        fetchCategories.fulfilled,
        (state, action: PayloadAction<IQuickResponseCategory[]>) => {
          state.status.categories = 'succeeded';
          state.categories = action.payload;
          console.log('fetchCategories succeeded: ', action.payload);
        })
      .addCase(fetchCategoryById.pending, (state) => {
        state.status.category = 'loading';
      })
      .addCase(
        fetchCategoryById.rejected,
        (state, action) => {
          state.status.category = 'rejected';
          console.log('fetchCategoryById rejected: ', action.payload);
        })
      .addCase(
        fetchCategoryById.fulfilled,
        (state, action: PayloadAction<IQuickResponseCategory>) => {
          state.status.category = 'succeeded';
          state.category = action.payload;
          console.log('fetchCategoryById succeeded: ', action.payload);
        })
      .addCase(fetchCreateCategory.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(
        fetchCreateCategory.rejected,
        (state, action) => {
          state.status.create = 'rejected';
          console.log('fetchCreateCategory rejected: ', action.payload);
        })
      .addCase(
        fetchCreateCategory.fulfilled,
        (state, action: PayloadAction<IQuickResponseCategory>) => {
          state.status.create = 'succeeded';
          const category = action.payload
          state.category = category;
          state.categories.push(category);
          console.log('fetchCreateCategory succeeded: ', category);
        })
      .addCase(fetchUpdateCategory.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(
        fetchUpdateCategory.rejected,
        (state, action) => {
          state.status.update = 'rejected';
          console.log('fetchUpdateCategory rejected: ', action.payload);
        })
      .addCase(
        fetchUpdateCategory.fulfilled,
        (state, action: PayloadAction<IQuickResponseCategory>) => {
          state.status.update = 'succeeded';
          const category = action.payload
          const updateCategory = state.categories.map((cat) => {
            if (cat.id === category.id) {
              return action.payload;
            }
            return cat;
          })
          state.categories = updateCategory;
          console.log('fetchUpdateCategory succeeded: ', action.payload);
        })
      .addCase(fetchDeleteCategory.pending, (state) => {
        state.status.category = 'loading';
      })
      .addCase(
        fetchDeleteCategory.rejected,
        (state, action) => {
          state.status.category = 'rejected';
          console.log('fetchDeleteCategory rejected: ', action.payload);
        })
      .addCase(
        fetchDeleteCategory.fulfilled, (state, action: PayloadAction<string>) => {
          const filteredCategories = state.categories.filter((cat) => cat.id !== action.payload);
          state.categories = filteredCategories;
          state.status.category = 'succeeded';
        });
  }
})

const quickResponseState = (state: RootState) => state.QuickResponse;
export const selectQuickResponses = createSelector(
  quickResponseState,
  (conversations) => conversations.quickResponses
);
export const selectQuickResponsesFetchStatus = createSelector(
  quickResponseState,
  (conversations) => conversations.status.quickResponses
);
export const selectQuickResponse = createSelector(
  quickResponseState,
  (conversations) => conversations.quickResponse
);
export const selectQuickResponseFetchStatus = createSelector(
  quickResponseState,
  (conversations) => conversations.status.quickResponse
);
// Categories
export const selectCategories = createSelector(
  quickResponseState,
  (conversations) => conversations.categories
);
export const selectCategoriesFetchStatus = createSelector(
  quickResponseState,
  (conversations) => conversations.status.categories
);
export const selectCategory = createSelector(
  quickResponseState,
  (conversations) => conversations.category
);
export const selectCategoryFetchStatus = createSelector(
  quickResponseState,
  (conversations) => conversations.status.category
);
export const selectCreateFetchStatus = createSelector(
  quickResponseState,
  (conversations) => conversations.status.create
);
export const selectUpdateFetchStatus = createSelector(
  quickResponseState,
  (conversations) => conversations.status.update
);

export default quickResponseSlice.reducer;