import { ChangeEvent, Dispatch, SetStateAction, createContext } from 'react';
import MediaFile from '../MessagesProvider/types/MediaFile';
import FileType from '../MessagesProvider/types/FileType';
import { DocumentType } from '../MessagesProvider/types/DocumentType';

export type FilePreviewerContextType = {
  filesCopy: MediaFile[];
  selectedFile: MediaFile | null;
  selectedDocument: DocumentType | null;
  documentsCopy: DocumentType[];
  getFileType: (type: string) => FileType;
  handleFileSelect: (fileId: string) => void;
  handleFileRemove: (fileId: string) => void;
  handleFileMsgChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    fileId: string
  ) => void;
  handleDocumentRemove: (documentId: string) => void;
  handleDocumentSelect: (documentId: string) => void;
  endDocumentSelectorMode: () => void;
  sendImages: (files: MediaFile[], conversationId: string) => void;
  sendDocuments: (documents: DocumentType[], conversationId: string) => void;
  showActions: boolean;
  setShowActions: Dispatch<SetStateAction<boolean>>;
};

export const FilePreviewerContext = createContext<FilePreviewerContextType>({
  filesCopy: [],
  selectedFile: null,
  documentsCopy: [],
  selectedDocument: null,
  getFileType: (type: string): FileType => {
    return 'image';
  },
  handleFileSelect: (fileId: string) => {},
  handleFileRemove: (fileId: string) => {},
  handleFileMsgChange: (event: ChangeEvent<HTMLInputElement>, fileId: string) => {},
  handleDocumentRemove: (documentId: string) => {},
  handleDocumentSelect: (documentId: string) => {},
  endDocumentSelectorMode: () => {},
  sendImages: (files: MediaFile[], messageId: string) => {},
  sendDocuments: (documents: DocumentType[], conversationId: string) => {},
  showActions: false,
  setShowActions: (showActions: boolean) => {},
});
