import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { EndingState } from './types/EndingState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { IEnding } from '@trii/types/dist/Conversations';
import { NewEnding } from './types/NewEnding';
// Service
import endingService from './endingService';

const initialState: EndingState = {
  endings: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    update: 'idle',
    delete: 'idle',
  },
};

export const fetchEndings = createAsyncThunk(
  'ending/fetchEndings',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await endingService.fetchEndings(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const createEnding = createAsyncThunk(
  'ending/createEnding',
  async (ending: NewEnding, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await endingService.createEnding(
      jwtToken,
      URL_CONVERSATIONS,
      ending
    );

    return response;
  }
);

export const deleteEnding = createAsyncThunk(
  'ending/deleteEnding',
  async (endingId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await endingService.deleteEnding(
      jwtToken,
      URL_CONVERSATIONS,
      endingId
    );

    return response;
  }
);

export const updateEnding = createAsyncThunk(
  'ending/updateEnding',
  async (ending: IEnding, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    await endingService.updateEnding(jwtToken, URL_CONVERSATIONS, ending);

    return ending;
  }
);

const endingSlice = createSlice({
  name: 'ending',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEndings.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchEndings.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.endings = action.payload;
      })
      .addCase(createEnding.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createEnding.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.endings.push(action.payload);
      })
      .addCase(createEnding.rejected, (state) => {
        state.status.create = 'rejected';
      })
      .addCase(deleteEnding.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteEnding.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.endings = state.endings.filter(
          (ending) => ending.id !== action.payload
        );
      })
      .addCase(updateEnding.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateEnding.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        const editEnding = action.payload;
        state.endings = state.endings.map((ending) => {
          if (ending.id === editEnding.id) {
            return editEnding;
          }
          return ending;
        });
      })
      .addCase(updateEnding.rejected, (state) => {
        state.status.update = 'rejected';
      });
  },
});

const selectEndingState = (state: RootState) => state.Ending;
export const selectAllEndings = createSelector(
  selectEndingState,
  (state) => state.endings
);
export const selectEndingsFetchStatus = createSelector(
  selectEndingState,
  (state) => state.status.fetch
);
export const selectEndingCreateStatus = createSelector(
  selectEndingState,
  (state) => state.status.create
);
export const selectEndingDeleteStatus = createSelector(
  selectEndingState,
  (state) => state.status.delete
);
export const selectEndingUpdateStatus = createSelector(
  selectEndingState,
  (state) => state.status.update
);

export default endingSlice.reducer;
