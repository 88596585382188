import { useContext } from "react"
// Context
import { audioMessagesContext } from "../../../../features/Views/Conversations/components/components/Conversation/components/Footer/components/ChatMode/context/AudioMessagesProvider/AudioMessagesProvider"
// Components/ui
import {
  Box,
  Typography
} from "@mui/material"
// Icons
import { Mic } from "@mui/icons-material"
interface AudioProps {
  messageId: string
}

const Audio = ({ messageId }: AudioProps) => {
  const { durations } = useContext(audioMessagesContext)
  const duration = durations.find((duration) => duration.id === messageId)?.duration

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "1rem",
        overflow: "hidden",
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "0%",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Mic
          sx={{
            color: (theme) => theme.palette.text.disabled,
            fontSize: "1rem",
          }}
        />
        <Typography
          sx={{
            color: (theme) => theme.palette.text.disabled,
            fontSize: ".8rem",
          }}
        >
          {duration}
        </Typography>
      </Box>
    </Box>
  )
}

export default Audio