import { useContext, useEffect, useState } from 'react';
// Redux
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, TextField, Typography } from '@mui/material';
import { EmailMessageContext } from '../../../../context/EmailMessageContext';

const Subject = () => {
  const { subject, handleSubjectChange } = useContext(EmailMessageContext);
  const { t } = useTranslation();

  return (
    <Box height="3rem" width="100%" display="flex" alignItems="center" gap={1}>
      <Typography
        variant="body2"
        fontSize=".8rem"
        width="4rem"
        color={(theme) => theme.palette.text.primary}
      >
        {t('conversations.message.email.subject')}
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          value={subject}
          onChange={handleSubjectChange}
          size="small"
        />
      </Box>
    </Box>
  );
};

export default Subject;
