import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Utils
import templatesService from './utils/templatesService';
// Types
import { initRequestData } from 'redux/functions/initRequestData';
import { TemplatesSliceState } from './types/TemplatesSliceState';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import { TemplatesList } from 'features/Views/Templates/context/types/Lists';

const initialState: TemplatesSliceState = {
  templates: [],
  status: {
    fetch: 'idle',
  },
};

export const fetchTemplates = createAsyncThunk(
  'templates/fetchTemplates',
  async (channelId: string, { dispatch }) => {
    const { jwtToken, URL_TEMPLATES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await templatesService.fetch(
      URL_TEMPLATES,
      jwtToken,
      channelId
    );

    return response;
  }
);

export const postTemplate = createAsyncThunk(
  'templates/postTemplate',
  async (template: any, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await templatesService.post(URL_MESSAGES, jwtToken, template);
    return response;
  }
);

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTemplates.pending, (state) => {
      state.status.fetch = 'loading';
    });
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      state.status.fetch = 'succeeded';
      state.templates = action.payload as TemplatesList[];
    });
  },
});

export default templatesSlice.reducer;
