// Components/ui
import { Box, Typography } from '@mui/material';
import { style } from './style';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// Translations
import { useTranslation } from 'react-i18next';

const FormTypeTitle = () => {
  const { t } = useTranslation();

  return (
    <Box style={style.formTitleContainer}>
      <FormatListBulletedIcon
        sx={{
          color: (theme) => theme.palette.text.primary,
          fontSize: '1rem',
        }}
      />
      <Typography
        color={'text.primary'}
        sx={{ userSelect: 'none' }}
        variant="caption"
      >
        {t('global.form')}
      </Typography>
    </Box>
  );
};

export default FormTypeTitle;
