import { useCallback, useContext, useEffect, useRef } from 'react';
// Router
import { useParams, useSearchParams } from 'react-router-dom';
// Context
import { conversationsContext } from '../context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from '../context/MessagesProvider/MessagesProvider';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Components/ui
import Box from '@mui/material/Box';
import {
  Conversation,
  SidebarContactList,
  ChatDetailsSidebar,
  SelectConversationScreen,
  FilePreviewer,
  ImageViewer,
  ModalList,
  SearchMessage,
} from './components';
import { Slide } from '@mui/material';
// Redux
import {
  selectConversationSelected,
  setConversationSelected,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';
// Hooks
import useNotificationSound from '../hooks/useNotificationSound';
import FilePreviewerProvider from '../context/FilePreviewerProvider/FilePreviewerProvider';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useOpenCallPanelWidth } from 'features/Layout/MainLayout/utils/useOpenCallPanelWidth';

const ConversationsContainer = () => {
  const {
    conversations,
    handleEscapeConversation,
    getInternalChats,
    handleSelectConversation,
    getUsers,
    setContactInfo,
  } = useContext(conversationsContext);
  const conversationSelected = useSelector(selectConversationSelected);
  const dispatch = useAppDispatch();

  const { isSearching, fileSelectorMode } = useContext(messagesContext);
  const { subscribeEvent, unsubscribeEvent, socketConnection } =
    useContext(socketContext);
  const notificationSound = useNotificationSound();
  const { contactId } = useParams<{ contactId: string }>();
  const [searchParams] = useSearchParams();
  const containerRef = useRef(null);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.shiftKey && event.key === 'F') {
      console.log(`Key pressed: ${event.key}`);
    }
    if (event.key === 'Escape') {
      dispatch(setConversationSelected(null));
      setContactInfo(null);
    }
  };

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    const originalFavicon = favicon?.getAttribute('href');
    const newFavicon = '/favicon/TriiNotification.svg';

    const setActiveFavicon = () => {
      favicon?.setAttribute('href', originalFavicon);
    };

    const setInactiveFavicon = () => {
      favicon?.setAttribute('href', newFavicon);
    };

    const handleInactiveNotification = () => {
      if (document.hidden) {
        setInactiveFavicon();
        notificationSound.playNotification();
      }
    };
    if (socketConnection) {
      subscribeEvent('message_new', handleInactiveNotification);
      subscribeEvent('conversation_finalize', handleInactiveNotification);
      subscribeEvent('conversation_update', handleInactiveNotification);

      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          setActiveFavicon();
        }
      });
    }

    return () => {
      unsubscribeEvent('message_new');
      unsubscribeEvent('conversation_finalize');
      unsubscribeEvent('conversation_update');

      document.removeEventListener('visibilitychange', () => {
        favicon.setAttribute('href', originalFavicon);
      });
    };
  }, [subscribeEvent, unsubscribeEvent]);

  useEffect(() => {
    getInternalChats();
    getUsers();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [conversationSelected, contactId]);

  useEffect(() => {
    if (searchParams.has('conversationId')) {
      const conversationId = searchParams.get('conversationId');
      if (conversationId) {
        const findConversation = conversations.find(
          (conversation) => conversation.id === conversationId
        );
        if (findConversation) {
          handleSelectConversation(findConversation);
        }
      }
    }
  });
  const { boxChatWidth } = useOpenCallPanelWidth();

  return (
    <Box
      display="flex"
      margin="1rem"
      sx={{
        height: 'calc(100% - 2rem)',
        width: 'calc(100vw - 5rem)',
        backgroundColor: (theme) => theme.palette.background.default,
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        maxWidth: boxChatWidth,
      }}
      component="div"
    >
      <Box display="flex" width={'100%'} height={'100%'} ref={containerRef}>
        <SidebarContactList />
        {conversationSelected || contactId ? (
          <>
            <Conversation conversationSelected={conversationSelected} />
            <FilePreviewerProvider>
              {fileSelectorMode && <FilePreviewer />}
            </FilePreviewerProvider>
            <ImageViewer />
            <Box
              sx={{
                minWidth: '25%',
                maxWidth: '25%',
                height: '100%',
              }}
            >
              {isSearching ? (
                <Slide
                  direction="left"
                  in={isSearching}
                  mountOnEnter
                  unmountOnExit
                  container={containerRef.current}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <SearchMessage />
                  </Box>
                </Slide>
              ) : (
                <ChatDetailsSidebar />
              )}
            </Box>
          </>
        ) : (
          <SelectConversationScreen />
        )}
      </Box>
      <ModalList />
    </Box>
  );
};

export default ConversationsContainer;
