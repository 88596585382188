import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { SmsState } from './types/SmsState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewSms } from './types/NewSms';
// Service
import smsService from './smsService';

const initialState: SmsState = {
  sms: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchSms = createAsyncThunk('sms/fetchSms', async (_, { dispatch }) => {
  const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const response = await smsService.fetchSms(jwtToken, URL_CONVERSATIONS);

  return response;
});

export const createSms = createAsyncThunk(
  'sms/createSms',
  async (sms: NewSms, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await smsService.createSms(jwtToken, URL_CONVERSATIONS, sms);

    return response;
  }
);

export const deleteSms = createAsyncThunk(
  'sms/deleteSms',
  async (smsId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await smsService.deleteSms(jwtToken, URL_CONVERSATIONS, smsId);

    return response;
  }
);

const smsSlice = createSlice({
  name: 'sms',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSms.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchSms.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.sms = action.payload;
      })
      .addCase(createSms.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createSms.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.sms.push(action.payload);
      })
      .addCase(deleteSms.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteSms.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.sms = state.sms.filter((sms) => sms.id !== action.payload);
      });
  },
});

const selectSmsState = (state: RootState) => state.Sms;
export const selectAllSms = createSelector(selectSmsState, (state) => state.sms);
export const selectSmsFetchStatus = createSelector(
  selectSmsState,
  (state) => state.status.fetch
);
export const selectSmsCreateStatus = createSelector(
  selectSmsState,
  (state) => state.status.create
);
export const selectSmsDeleteStatus = createSelector(
  selectSmsState,
  (state) => state.status.delete
);

export default smsSlice.reducer;
