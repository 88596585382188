import { useContext } from 'react';
import { FilePreviewerContext } from 'features/Views/Conversations/context/FilePreviewerProvider/FilePreviewerContext';
// Components/ui
import { Box, Card, CardMedia } from '@mui/material';

const FilePreview = () => {
  const { selectedFile, getFileType } = useContext(FilePreviewerContext);
  const fileType = getFileType(selectedFile?.mimeType);

  return (
    <Box
      sx={{
        width: '100%',
        height: '70%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          borderRadius: '1px',
          boxShadow: 1,
        }}
      >
        <CardMedia
          component={`${fileType === 'image' ? 'img' : 'video'}`}
          image={selectedFile.url}
          alt="preview"
          sx={{
            width: '100%',
            height: '100%',
            maxHeight: 400,
            maxWidth: 450,
            objectFit: 'cover',
          }}
          controls={fileType === 'video'}
        />
      </Card>
    </Box>
  );
};

export default FilePreview;
