import { useState, useContext, useEffect } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import {
  selectConversationSelected,
  selectConversationsFetchStatus,
  selectGroups,
  selectGroupsFetchStatus,
  setConversationSelected,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { selectUsers } from 'redux/features/userSlice/userSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
} from '@mui/material';
// Icons
import { Group, Person } from '@mui/icons-material';
// Types
import { UserInfo } from '@trii/types/dist/Users';
import { IGroup } from '@trii/types/dist/Conversations/Groups/Group';
import { useAppDispatch } from 'hooks/useAppDispatch';

const USER_TAB = 'userId';
const GROUP_TAB = 'groupId';

const TransferTo = () => {
  const dispatch = useAppDispatch();
  const { handleTransferTo, setOpenModalList } = useContext(conversationsContext);
  const conversationSelected = useSelector(selectConversationSelected);

  const [value, setValue] = useState<string>(USER_TAB);
  const [listToRender, setListToRender] = useState<UserInfo[] | IGroup[]>([]);
  const users = useSelector(selectUsers);
  const usersStatus = useSelector(selectConversationsFetchStatus);
  const isLoadingUsers = usersStatus === 'loading' && value === USER_TAB;
  const groups = useSelector(selectGroups);
  const groupsStatus = useSelector(selectGroupsFetchStatus);
  const isLoadingGroups = groupsStatus === 'loading' && value === GROUP_TAB;
  const { t } = useTranslation();

  const handleChange = (tab: string) => {
    setValue(tab);
  };

  useEffect(() => {
    if (value === USER_TAB) {
      setListToRender(users);
    } else {
      setListToRender(groups);
    }
  }, [value, users, groups]);

  const handleSelect = (id: string) => {
    if (value === USER_TAB) {
      const data = {
        conversationId: conversationSelected.id,
        userId: id,
        groupId: '',
      };
      handleTransferTo(data);
    } else {
      const data = {
        conversationId: conversationSelected.id,
        userId: '',
        groupId: id,
      };
      handleTransferTo(data);
    }
    dispatch(setConversationSelected(null));
    setOpenModalList(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        overflow: 'auto',
      }}
    >
      <Tabs
        value={value}
        onChange={(e, tab) => handleChange(tab)}
        aria-label="basic tabs example"
        variant="fullWidth"
      >
        <Tab label={t('global.users')} value={USER_TAB} />
        <Tab label={t('settingsView.groups.groups')} value={GROUP_TAB} />
      </Tabs>
      {isLoadingUsers || isLoadingGroups ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: '1em',
          }}
        >
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        listToRender &&
        listToRender.length > 0 &&
        listToRender.map((data: UserInfo | IGroup, i: number) => (
          <ListItem
            key={data.id}
            onClick={() => handleSelect(data.id)}
            sx={{
              padding: 0,
            }}
          >
            <ListItemButton
              sx={{
                borderBottom: (theme) =>
                  i === listToRender.length - 1
                    ? 'none'
                    : `1px solid ${theme.palette.divider}`,
              }}
            >
              <ListItemIcon>
                {value === USER_TAB ? <Person /> : <Group />}
              </ListItemIcon>
              <ListItemText
                primary={data.name}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))
      )}
    </Box>
  );
};

export default TransferTo;
