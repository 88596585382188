// Components/ui
import { Box, Typography, TextField } from '@mui/material';

interface VariableInputProps {
  variableName: string;
  variableValue: string;
  handleChange: (variableName: string, newValue: string) => void;
}

const VariableInput = ({
  variableValue,
  handleChange,
  variableName,
}: VariableInputProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
      }}
    >
      <Typography
        variant="caption"
        sx={{ marginRight: '10px', whiteSpace: 'nowrap' }}
      >
        {`${variableName}: `}
      </Typography>
      <TextField
        fullWidth
        value={variableValue}
        size={'small'}
        className="conversations-smallInput"
        onChange={(event) => handleChange(variableName, event.target.value)}
      />
    </Box>
  );
};

export default VariableInput;
