import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { IIntegrationButton } from '@trii/types/dist/Contacts';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { IinitialState } from './types/IInitialState';
import { RootState } from 'redux/store';
import { initRequestData } from 'redux/functions/initRequestData';

const initialState: IinitialState = {
  buttons: {
    buttons: [],
    status: {
      fetch: 'idle',
      create: 'idle',
      edit: 'idle',
      delete: 'idle',
      updateOrder: 'idle',
    },
  },
};


export const fetchButtons = createAsyncThunk<IIntegrationButton[], void>(
  'config/fetchButtons',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    const response = await axios.get(`${URL_CONTACTS}/buttons`, config);

    return response.data;
  }
);
export const addButton = createAsyncThunk(
  'config/addButton',
  async (buttonDataJSON: IIntegrationButton, { dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.post(
        `${URL_CONTACTS}/buttons`,
        buttonDataJSON,
        config
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteButton = createAsyncThunk(
  'config/deleteButton',
  async (buttonId: number, { dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    try {
      await axios.delete(`${URL_CONTACTS}/buttons/${buttonId}`, config);

      return buttonId;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const updateButton = createAsyncThunk(
  'config/updateButton',
  async (buttonDataJSON: IIntegrationButton, { dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.put(
        `${URL_CONTACTS}/buttons`,
        buttonDataJSON,
        config
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);


const buttonsSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {

    setButtons(state, action) {
      const newButtons = action.payload;

      state.buttons.buttons = newButtons;
    },
  },
  extraReducers(builder) {
    builder     
      .addCase(fetchButtons.fulfilled, (state, action) => {
        state.buttons.status.fetch = 'succeeded';
        state.buttons.buttons = action.payload;
      })
      .addCase(fetchButtons.rejected, (state, action) => {
        state.buttons.status.fetch = { error: action.payload as string };
      })
      .addCase(fetchButtons.pending, (state) => {
        state.buttons.status.fetch = 'loading';
      })
      .addCase(addButton.fulfilled, (state, action) => {
        state.buttons.status.create = 'idle';
        state.buttons.buttons.push(action.payload);
      })
      .addCase(addButton.rejected, (state, action) => {
        state.buttons.status.create = { error: action.payload as string };
      })
      .addCase(addButton.pending, (state) => {
        state.buttons.status.create = 'loading';
      })
      .addCase(deleteButton.fulfilled, (state, action) => {
        const deletedButtonId = action.payload;
        const newButtons = state.buttons.buttons.filter(
          (button) => button.id !== deletedButtonId
        );

        state.buttons.status.delete = 'idle';
        state.buttons.buttons = newButtons;
      })
      .addCase(deleteButton.rejected, (state, action) => {
        state.buttons.status.delete = { error: action.payload as string };
      })
      .addCase(deleteButton.pending, (state) => {
        state.buttons.status.delete = 'loading';
      })
      .addCase(updateButton.pending, (state) => {
        state.buttons.status.edit = 'loading';
      })
      .addCase(updateButton.fulfilled, (state, action) => {
        const updatedButton = action.payload;
        const newButtons = state.buttons.buttons.map((button) => {
          if (button.id === action.payload) {
            return updatedButton;
          }

          return button;
        });

        state.buttons.status.edit = 'idle';
        state.buttons.buttons = newButtons;
      })
      .addCase(updateButton.rejected, (state, action) => {
        state.buttons.status.edit = { error: action.payload as string };
      })     
  },
});

// Selectors
const selectConfig = (state: RootState) => state.Buttons;

export const selectButtons = createSelector(
  selectConfig,
  (config) => config.buttons.buttons
);
export const selectButtonsFetchStatus = createSelector(
  selectConfig,
  (config) => config.buttons.status.fetch
);
export const selectButtonsAddStatus = createSelector(
  selectConfig,
  (config) => config.buttons.status.create
);
export const selectButtonsDeleteStatus = createSelector(
  selectConfig,
  (config) => config.buttons.status.delete
);
export const selectButtonEditStatus = createSelector(
  selectConfig,
  (config) => config.buttons.status.edit
);
export const selectButtonsUpdateOrderStatus = createSelector(
  selectConfig,
  (config) => config.buttons.status.updateOrder
);

// Actions
export const {
  setButtons,
} = buttonsSlice.actions;

export default buttonsSlice.reducer;