import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
import { UserSliceState } from './types/UserSliceState';
// Service
import userSliceService from './userSliceService';
// Types
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { UserInfo, UserTrii } from '@trii/types/dist/Users';
import { RootState } from 'redux/store';
import { IUserUpdate } from '@trii/types/dist/Common/EventsQueue';

const initialState: UserSliceState = {
  user: null,
  userById: null,
  users: [],
  status: {
    userById: 'idle',
    users: 'idle',
  },
};

export const getUser = createAsyncThunk<UserTrii, undefined, { state: RootState }>(
  'User/setUser',
  async (_, { dispatch }) => {
    const { jwtToken, URL_USER } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const triiUser = await userSliceService.fetchUserInfo(jwtToken, URL_USER);

    return triiUser;
  }
);

export const updateSas = createAsyncThunk(
  'contacts/updateSas',
  async (_, { dispatch }) => {
    const response = await dispatch(getUser());
    const userTrii = response.payload as UserTrii;
    const { storageAzureSAS } = userTrii;

    return storageAzureSAS;
  }
);

export const fetchUserById = createAsyncThunk<
  UserTrii,
  string,
  { state: RootState }
>('User/fetchUserById', async (userId: string, { dispatch }) => {
  const { jwtToken, URL_USERS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const triiUser = await userSliceService.fetchUserById(jwtToken, URL_USERS, userId);

  return triiUser;
});

export const fetchUsers = createAsyncThunk<
  UserInfo[],
  undefined,
  { state: RootState }
>('User/fetchUsers', async (_, { dispatch }) => {
  const { jwtToken, URL_USERS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const triiUsers = await userSliceService.fetchUsers(jwtToken, URL_USERS);

  return triiUsers;
});

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserTrii>) => {
      state.user = action.payload;
    },
    updateUser: (state, action: PayloadAction<IUserUpdate>) => {
      const updateData = action.payload;
      const userIndex = state.users.findIndex(
        (user) => user.id === updateData.userId
      );

      if (userIndex !== -1 && userIndex !== undefined) {
        if (
          updateData.status !== undefined &&
          state.users[userIndex].status !== updateData.status
        ) {
          state.users[userIndex].status = updateData.status;
        }
        if (
          updateData.imageUrl !== undefined &&
          state.users[userIndex].imageUrl !== updateData.imageUrl
        ) {
          state.users[userIndex].imageUrl = updateData.imageUrl;
        }
        if (
          updateData.name !== undefined &&
          state.users[userIndex].name !== updateData.name
        ) {
          state.users[userIndex].name = updateData.name;
        }
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUser.fulfilled, (state, action: PayloadAction<UserTrii>) => {
        console.log('success fetching user: ', action.payload);

        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        console.log('error fetching user: ', action.payload);
      })
      .addCase(fetchUserById.pending, (state) => {
        state.status.userById = 'loading';
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.userById = action.payload;
        state.status.userById = 'succeeded';
      })
      .addCase(fetchUserById.rejected, (state) => {
        state.status.userById = 'rejected';
      })
      .addCase(fetchUsers.pending, (state, action) => {
        state.status.users = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<UserInfo[]>) => {
        state.status.users = 'succeeded';
        console.log('user slice: ', action.payload);
        state.users = action.payload;
      });
  },
});

export const { setUser, updateUser } = userSlice.actions;

// selectors
const userState = (state: RootState) => state.User;
export const selectUser = createSelector(userState, (user) => user.user);
export const selectUserById = createSelector(userState, (user) => user.userById);
export const selectUserByIdStatus = createSelector(
  userState,
  (user) => user.status.userById
);
export const selectUsers = createSelector(userState, (user) => user.users);
export const selectUsersStatus = createSelector(
  userState,
  (user) => user.status.users
);

export default userSlice.reducer;
