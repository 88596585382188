import axios from 'axios';
import { IFlow } from '@trii/types/dist/Conversations/Flows';
import { NewFlow } from './types/NewFlow';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchFlows = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/flows`, config);
  return response.data;
};
const createFlow = async (jwt: string, URL: string, flow: NewFlow) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const flowJson = JSON.stringify(flow);
  const response = await axios.post(`${URL}/flows`, flowJson, config);
  return response.data;
};
const deleteFlow = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/flows/${id}`, config);

  return id;
};
const updateFlow = async (jwt: string, URL: string, flow: IFlow) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const flowJson = JSON.stringify(flow);

  const response = await axios.put(`${URL}/flows/${flow.id}`, flowJson, config);
  return response.data;
};

const flowService = {
  fetchFlows,
  createFlow,
  deleteFlow,
  updateFlow,
};

export default flowService;
