import chmIcon from './images/chm-file.png';
import docIcon from './images/doc-file.png';
import docxIcon from './images/docx-file.png';
import docmIcon from './images/docm-file.png';
import dotIcon from './images/dot-file.png';
import dotxIcon from './images/dotx-file.png';
import emlIcon from './images/eml-file.png';
import hwpIcon from './images/hwp-file.png';
import logIcon from './images/log-file.png';
import txtIcon from './images/txt-file.png';
import wpdIcon from './images/wpd-file.png';
import wpsIcon from './images/wps-file.png';
import xpsIcon from './images/xps-file.png';
import xmlIcon from './images/xml-file.png';
import msgIcon from './images/msg-file.png';
import pdfIcon from './images/pdf-file.png';
import odtIcon from './images/odt-file.png';
import pagesIcon from './images/pages-file.png';
import pubIcon from './images/pub-file.png';
import rtfIcon from './images/rtf-file.png';
import texIcon from './images/tex-file.png';
import sxwIcon from './images/sxw-file.png';
import imageIcon from './images/image.png';
import videoIcon from './images/video.png';

const textFiles = [
  {
    name: 'chm',
    src: chmIcon,
  },
  {
    name: 'doc',
    src: docIcon,
  },
  {
    name: 'docx',
    src: docxIcon,
  },
  {
    name: 'docm',
    src: docmIcon,
  },
  {
    name: 'dot',
    src: dotIcon,
  },
  {
    name: 'dotx',
    src: dotxIcon,
  },
  {
    name: 'eml',
    src: emlIcon,
  },
  {
    name: 'hwp',
    src: hwpIcon,
  },
  {
    name: 'log',
    src: logIcon,
  },
  {
    name: 'txt',
    src: txtIcon,
  },
  {
    name: 'wpd',
    src: wpdIcon,
  },
  {
    name: 'wps',
    src: wpsIcon,
  },
  {
    name: 'xps',
    src: xpsIcon,
  },
  {
    name: 'xml',
    src: xmlIcon,
  },
  {
    name: 'msg',
    src: msgIcon,
  },
  {
    name: 'pdf',
    src: pdfIcon,
  },
  {
    name: 'odt',
    src: odtIcon,
  },
  {
    name: 'pages',
    src: pagesIcon,
  },
  {
    name: 'pub',
    src: pubIcon,
  },
  {
    name: 'rtf',
    src: rtfIcon,
  },
  {
    name: 'tex',
    src: texIcon,
  },
  {
    name: 'sxw',
    src: sxwIcon,
  },
  {
    name: 'image',
    src: imageIcon,
  },
  {
    name: 'video',
    src: videoIcon,
  },
];

export default textFiles;
