import { useEffect, useRef, useState } from 'react';
// Idb
import db from 'db/db';
// Types
import { IMessage } from '@trii/types/dist/Common/Messages';
import useCheckbox, { UseCheckboxType } from 'hooks/useCheckbox';
import useField from 'hooks/useField';
import { fieldInitialDef } from 'utils/fieldInitialDef';

/**
 * Custom hook to filter messages based on a search field and a checkbox.
 * @param conversationId - The ID of the conversation for which the messages need to be filtered.
 * @returns An object containing the filtered messages, loading state, search field state/handler and checkbox state/handler.
 */

export interface UseFilterMessage {
  filterLoading: boolean;
  cantFindAny: boolean;
  filteredMessages: IMessage[];
  searchField: ReturnType<typeof useField>;
  highligthMessagesCheckbox: UseCheckboxType;
}

export const useFilterMessageInitialDef = {
  filterLoading: false,
  filteredMessages: [],
  searchField: fieldInitialDef,
  highligthMessagesCheckbox: {
    actions: { resetValue: () => {} },
    attributes: { onChange: () => {}, checked: false },
  },
  cantFindAny: false,
};

const useFilterMessage = (conversationId: string): UseFilterMessage => {
  const highligthMessagesCheckbox = useCheckbox();
  const searchField = useField('text');

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [filteredMessages, setFilteredMessages] = useState<IMessage[]>([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [cantFindAny, setCantFindAny] = useState(false);

  async function searchAllHighlightedMessages() {
    try {
      setFilterLoading(true);

      // Get all the messages from the database for the given conversation
      const dbMessages = await db.getAllMessages(conversationId);

      // Filter the messages based on the isHighlighted property
      const filteredMessages = dbMessages.filter((message) => message.isHighlighted);

      // If there are no messages, set the `cantFindAny` state to true
      if (!filteredMessages.length) {
        setCantFindAny(true);
      }

      setFilteredMessages(filteredMessages);
    } catch (error) {
      console.error('Error filtering highligthed messages:', error);
    } finally {
      setFilterLoading(false);
    }
  }

  async function searchMessages() {
    const filterValue = searchField.attributes.value.toLowerCase();
    // If the search field is empty, set the filtered messages to [] and return
    setCantFindAny(false);

    if (!filterValue) {
      setFilteredMessages([]);

      return;
    }

    try {
      setFilterLoading(true);

      // Get all the messages from the database for the given conversation
      const dbMessages = await db.getAllMessages(conversationId);

      // Filter the messages based on the search field value
      let filteredMessages = dbMessages.filter((message) =>
        message.text?.body.toLocaleLowerCase().includes(filterValue)
      );

      // If the checkbox for highlighting messages is checked, further filter the messages based on the `isHighlighted` property
      if (highligthMessagesCheckbox.attributes.checked) {
        filteredMessages = filteredMessages.filter(
          (message) => message.isHighlighted
        );
      }

      // If there are no messages, set the `cantFindAny` state to true
      if (!filteredMessages.length) {
        setCantFindAny(true);
      }

      setFilteredMessages(filteredMessages);
    } catch (error) {
      console.error('Error filtering messages:', error);
    } finally {
      setFilterLoading(false);
    }
  }

  useEffect(() => {
    // Search the messages every time the checkbox is checked or unchecked
    if (
      !searchField.attributes.value &&
      highligthMessagesCheckbox.attributes.checked
    ) {
      searchAllHighlightedMessages();
    } else {
      searchMessages();
    }
  }, [highligthMessagesCheckbox.attributes.checked]);

  useEffect(() => {
    // Clear the previous timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer to delay the filtering process
    timerRef.current = setTimeout(async () => {
      await searchMessages();
    }, 150);

    // Clean up the timer when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [searchField.attributes.value]);

  // Use effect state reset
  useEffect(() => {
    return () => {
      highligthMessagesCheckbox.actions.resetValue();
      searchField.actions.resetValue();
      setFilteredMessages([]);
      setFilterLoading(false);
      setCantFindAny(false);
    };
  }, []);

  return {
    cantFindAny,
    filterLoading,
    filteredMessages,
    searchField,
    highligthMessagesCheckbox,
  };
};

export default useFilterMessage;
