import { useEffect, useState } from 'react';
// Components/ui
import { Box } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface Props {
  isRecording: boolean;
  audioDuration: number;
}

const Recorder = ({ isRecording, audioDuration }: Props) => {
  const [timeRecord, setTimeRecord] = useState(audioDuration * 1000);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setTimeRecord((prevTime) => prevTime + 1000);
    }, 1000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording]);

  return (
    <Box display="flex" alignItems="center">
      <FiberManualRecordIcon
        style={{ marginRight: '10px', color: 'red' }}
        className="redDotAudioRecorder"
      />
      <span>{('0' + Math.floor((timeRecord / 60000) % 60)).slice(-2)}:</span>
      <span>{('0' + Math.floor((timeRecord / 1000) % 60)).slice(-2)}</span>
    </Box>
  );
};

export default Recorder;
