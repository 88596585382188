import { useEffect, useState, useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  selectContacts,
  selectContactsFetchStatus,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  InputAdornment,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
// Icons
import { Person, Search } from '@mui/icons-material';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Types
import { Pagination } from 'redux/features/contactInfoSlice/types/Pagination';

const DATA = {
  currentPage: 1,
  perPage: 10,
  order: 'ASC',
  orderColumn: 'name',
  format: 'IContact',
  operator: 'AND',
  filter: [
    {
      column: 'name',
      value: '',
      condition: '',
    },
  ],
};

interface DestinationProps {
  id: string;
  name: string;
  contact: string;
}

interface Props {
  addressId: string[];
  setAddressId: (value: string[]) => void;
}

const Destination = ({ addressId, setAddressId }: Props) => {
  const { t } = useTranslation();

  const { handleSearchContact } = useContext(messagesContext);

  const [search, setSearch] = useState<string>('');
  const [contactList, setContactList] = useState<DestinationProps[]>([]);
  const [contactLength, setContactLength] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isNextPage, setIsNextPage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>(null);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);

  const contacts = useSelector(selectContacts);
  const contactsFetchStatus = useSelector(selectContactsFetchStatus);

  const handleSearch = (value: string) => {
    setSearch(value);
    setIsNextPage(false);
    setAllLoaded(false);
    setCurrentPage(1);
    setContactLength(0);
    setContactList([]);
    clearTimeout(timer);

    if (value) {
      setIsLoading(true);
      setTimer(
        setTimeout(async () => {
          const newData = {
            ...DATA,
            filter: [
              {
                ...DATA.filter[0],
                value,
              },
            ],
          } as Pagination;
          handleSearchContact(newData);
        }, 1000)
      );
    } else {
      setIsLoading(false);
    }
  };

  const handleSelectContact = (contact: DestinationProps) => {
    const id = contact.id;
    if (addressId?.includes(id)) {
      const newContactSelected = addressId.filter((contactId) => contactId !== id);
      setAddressId(newContactSelected);
      return;
    }
    setAddressId([...addressId, id]);
  };

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    const bottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (bottom && !allLoaded) {
      setIsNextPage(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      const newData = {
        ...DATA,
        currentPage: newPage,
        filter: [
          {
            ...DATA.filter[0],
            value: search,
          },
        ],
      } as Pagination;
      handleSearchContact(newData);
    }
  };

  useEffect(() => {
    if (contacts && contacts?.contactos) {
      const newPhones = contacts.contactos
        .map((contact) =>
          contact.phones?.map((phone) => ({
            id: phone.id,
            name: contact.name,
            contact: phone.address,
          }))
        )
        .flat();
      const newEmails = contacts.contactos
        .map((contact) =>
          contact.emails?.map((email) => ({
            id: email.id,
            name: contact.name,
            contact: email.address,
          }))
        )
        .flat();
      const newContacts = [...newPhones, ...newEmails];

      setContactLength((prev) => prev + contacts?.contactos?.length);

      if (isNextPage) {
        setIsNextPage(false);

        if (contacts?.paginacion.total === contactLength) {
          setAllLoaded(true);

          return;
        }
        setContactList([...contactList, ...newContacts]);
      } else {
        setContactList(newContacts);
      }
    }
  }, [contacts]);

  useEffect(() => {
    setIsLoading(contactsFetchStatus === 'loading');
  }, [contactsFetchStatus]);

  return (
    <Box display="flex" width="100%" height="100%" flexDirection="column">
      <Typography variant="body2" color="textSecondary">
        {t('conversations.message.destination')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box position="absolute" top="2rem" width="100%">
          {isLoading && (
            <LinearProgress
              sx={{
                borderRadius: 1,
              }}
            />
          )}
        </Box>
        <TextField
          placeholder={t('conversations.message.searchContact')}
          sx={{
            width: '100%',
          }}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  fontSize="small"
                  sx={{
                    color: (theme) => theme.palette.text.disabled,
                  }}
                />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <List
          component="nav"
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
          onScroll={handleScroll}
        >
          {search &&
            contactList?.map((contact, i) => (
              <ListItemButton
                key={i}
                sx={{
                  width: '100%',
                }}
                onClick={() => handleSelectContact(contact)}
                selected={addressId?.includes(contact?.id)}
              >
                <ListItemIcon>
                  <Person
                    sx={{
                      color: (theme) => theme.palette.text.disabled,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: (theme) => theme.palette.text.disabled,
                  }}
                  primary={t(contact?.name)}
                  secondary={contact?.contact}
                />
              </ListItemButton>
            ))}
        </List>
      </Box>
    </Box>
  );
};

export default Destination;
