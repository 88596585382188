export const style = {
  container: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.4rem',
    paddingX: '0.8rem',
    paddingY: '0.4rem',
    transition: 'all 0.05s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
    borderRadius: '0.2rem',
    userSelect: 'none',
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  messageContainer: {
    display: 'flex',
    alignContent: 'center',
    gap: '0.4rem',
  },
};
