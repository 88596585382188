import React from 'react';
import GoogleBusinessImage from '../image/google-business.png';

interface Props {
  size: number;
}
const GoogleBusinessIcon = ({ size }: Props) => {
  return (
    <img
      src={GoogleBusinessImage}
      alt="Google Business"
      width={size}
      height="auto"
    />
  );
};

export default GoogleBusinessIcon;
