import { useRef, useState, useEffect } from 'react';
// Components/ui
import { Box, CardMedia } from '@mui/material';

interface ImageProps {
  url: string;
}

const ZOOM_SPEED = 0.1;

const Image = ({ url }: ImageProps) => {
  const containerImgRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragStartX, setDragStartX] = useState<number>(0);
  const [dragStartY, setDragStartY] = useState<number>(0);
  const [dragOffsetX, setDragOffsetX] = useState<number>(0);
  const [dragOffsetY, setDragOffsetY] = useState<number>(0);
  const [scaleZoom, setScaleZoom] = useState<number>(1);

  useEffect(() => {
    setScaleZoom(1);
    setDragOffsetX(0);
    setDragOffsetY(0);
  }, [url]);

  useEffect(() => {
    if (scaleZoom < 1) {
      setDragOffsetX(0);
      setDragOffsetY(0);
    }
  }, [scaleZoom]);


  const handleWheel = (e) => {
    if (e.deltaY > 0) {
      setScaleZoom(scaleZoom - ZOOM_SPEED);
    } else {
      setScaleZoom(scaleZoom + ZOOM_SPEED);
    }
  };

  const handleMouseDown = (e) => {
    if (e.button === 0) { // left click
      setIsDragging(true);
      const startX = e.clientX - dragOffsetX;
      const startY = e.clientY - dragOffsetY;
      setDragStartX(startX);
      setDragStartY(startY);
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && scaleZoom > 1) {
      const containerImgCurrent = containerImgRef.current;
      const imgCurrent = imgRef.current;
      if (!containerImgCurrent || !imgCurrent) return;
      const containerImgWidth = containerImgCurrent.clientWidth;
      const containerImgHeight = containerImgCurrent.clientHeight;
      const imgWidth = imgCurrent.clientWidth;
      const imgHeight = imgCurrent.clientHeight;
      const offsetX = e.clientX - dragStartX;
      const offsetY = e.clientY - dragStartY;
      const maxOffsetX = ((imgWidth * scaleZoom) - containerImgWidth) / 2;
      const maxOffsetY = ((imgHeight * scaleZoom) - containerImgHeight) / 2;
      if (offsetX > maxOffsetX) {
        setDragOffsetX(maxOffsetX);
      } else if (offsetX < -maxOffsetX) {
        setDragOffsetX(-maxOffsetX);
      } else {
        setDragOffsetX(offsetX);
      }
      if (offsetY > maxOffsetY) {
        setDragOffsetY(maxOffsetY);
      } else if (offsetY < -maxOffsetY) {
        setDragOffsetY(-maxOffsetY);
      } else {
        setDragOffsetY(offsetY);
      }
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '100%',
        maxWidth: '100%',
      }}
      onWheel={handleWheel}
      ref={containerImgRef}
    >
      <CardMedia
        component='img'
        ref={imgRef}
        image={url}
        alt="imagen"
        sx={{
          borderRadius: '2px',
          cursor: isDragging && scaleZoom > 1 ? 'grabbing' : scaleZoom > 1 ? 'grab' : 'default',
          scale: `${scaleZoom}`,
          translate: `${dragOffsetX}px ${dragOffsetY}px`,
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
        }}
        draggable={false}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      />
    </Box>
  )
}

export default Image;