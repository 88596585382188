import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
// Utils
import { initRequestData } from 'redux/functions/initRequestData';
import businessSliceService from './service';
// import contactHelper from '../contactsSlice/helper/contactHelper';
// Types
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import { BusinessSlice } from './types/BusinessSlice';
import { IBusiness, IContactField } from '@trii/types/dist/Contacts';
import { RootState } from 'redux/store';
import { FieldsFilter } from './types/FieldsFilter'

const initialState: BusinessSlice = {
  status: {
    create: 'idle',
    update: 'idle',
    updatePhoto: 'idle',
    deletePhoto: 'idle',
    fetchBusinessById: 'idle',
    fetchBussinesFields: 'idle',
  },
  businessData: null,
  businessFields: null,
};

export const createBusiness = createAsyncThunk(
  'business/createBusiness',
  async (businessJson: string, { dispatch }) => {
    const { URL_CONTACTS, jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await businessSliceService.create(jwtToken, URL_CONTACTS, businessJson);
  }
);
export const updateBusiness = createAsyncThunk(
  'business/updateBusiness',
  async (updatedBusiness: any, { dispatch }) => {
    const { URL_CONTACTS, jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const JSONbusiness = JSON.stringify(updatedBusiness);

    await businessSliceService.update(
      jwtToken,
      URL_CONTACTS,
      JSONbusiness,
      updatedBusiness.id
    );
  }
);
export const fetchBusinessById = createAsyncThunk(
  'business/fetchBusinessById',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await businessSliceService.fetchById(
      jwtToken,
      URL_CONTACTS,
      id
    );

    return response;
  }
); // To do
export const updateBusinessPhoto = createAsyncThunk(
  'business/updateBusinessPhoto',
  async (file: File, { dispatch }) => {
    const { jwtToken, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const fd = new FormData();

    fd.append('photo', file, file.name);

    const response = await businessSliceService.updatePhoto(jwtToken, URL_MEDIA, fd);

    return response;
  }
);
export const deleteBusinessPhoto = createAsyncThunk(
  'business/deleteBusinessPhoto',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await businessSliceService.deletePhoto(jwtToken, URL_CONTACTS, id);
  }
);
export const fetchBusinessFields = createAsyncThunk<
  IContactField[],
  FieldsFilter,
  { state: RootState }
>('business/fetchBusinessFields', async (filterBy, { dispatch }) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await businessSliceService.fetchFields(
    jwtToken,
    URL_CONTACTS,
    filterBy
  );

  return response;
});
const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createBusiness.pending, (state, action) => {
        state.status.create = 'loading';
      })
      .addCase(createBusiness.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
      })
      .addCase(updateBusiness.pending, (state, action) => {
        state.status.update = 'loading';
      })
      .addCase(updateBusiness.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
      })
      .addCase(fetchBusinessById.pending, (state) => {
        state.status.fetchBusinessById = 'loading';
      })
      .addCase(
        fetchBusinessById.fulfilled,
        (state, action: PayloadAction<IBusiness>) => {
          const business = action.payload;
          state.status.fetchBusinessById = 'succeeded';

          state.businessData = business;
        }
      )
      .addCase(updateBusinessPhoto.pending, (state) => {
        state.status.updatePhoto = 'loading';
      })  
      .addCase(
        updateBusinessPhoto.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.status.updatePhoto = 'succeeded';
          if (state.businessData) state.businessData.imageUrl = action.payload;
        }
      )
      .addCase(deleteBusinessPhoto.pending, (state) => {
        state.status.deletePhoto = 'loading';
      })
      .addCase(deleteBusinessPhoto.fulfilled, (state) => {
        state.status.deletePhoto = 'succeeded';
        state.businessData.imageUrl = '';

      })
      .addCase(fetchBusinessFields.pending, (state) => {
        state.status.fetchBussinesFields = 'loading';
      })

  },
});

export default businessSlice.reducer;
