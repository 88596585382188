// Components/ui
import { Box, Typography, TextField } from '@mui/material';

interface VariableButtonInputProps {
  variableName: string;
  variableValue: string;
  buttonNumber: number;
  handleChange: (
    buttonNumber: number,
    variableName: string,
    newValue: string
  ) => void;
}

const VariableButtonInput = ({
  variableValue,
  handleChange,
  variableName,
  buttonNumber,
}: VariableButtonInputProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
      }}
    >
      <Typography
        variant="caption"
        sx={{ marginRight: '10px', whiteSpace: 'nowrap' }}
      >
        {`${buttonNumber} - ${variableName}: `}
      </Typography>
      <TextField
        fullWidth
        value={variableValue}
        size={'small'}
        className="conversations-smallInput"
        onChange={(event) =>
          handleChange(buttonNumber, variableName, event.target.value)
        }
      />
    </Box>
  );
};

export default VariableButtonInput;
