import { useContext, useEffect, useState } from 'react';
// Context
// @ts-ignore
import { EmailMessageContext } from './EmailMessageContext.ts';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Types
// @ts-ignore
import type { EmailMessageContextType } from './EmailMessageContext.ts';

const EmailMessageProvider = ({ children }: { children: React.ReactNode }) => {
  const { subjectDraft, bodyDraft, sendEmail, emailConversationHasDraft } =
    useContext(messagesContext);

  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  };

  const sendEmailAndReset = (
    conversationId: string,
    newBody: string,
    subject: string
  ) => {
    sendEmail(conversationId, newBody, subject);
    setSubject('');
  };

  useEffect(() => {
    setSubject(subjectDraft);
  }, [subjectDraft, emailConversationHasDraft]);

  useEffect(() => {
    if (emailConversationHasDraft) {
      setBody(bodyDraft);
    } else {
      setBody('');
    }
  }, [bodyDraft, emailConversationHasDraft]);

  const value: EmailMessageContextType = {
    subject,
    handleSubjectChange,
    setSubject,
    sendEmailAndReset,
    body,
    setBody,
  };

  return (
    <EmailMessageContext.Provider value={value}>
      {children}
    </EmailMessageContext.Provider>
  );
};

export default EmailMessageProvider;
