// Context
import { DateTimeContext } from './DateTimeContext';
// Types
import { DateTimeContextType } from './DateTimeContext';
import useDateTime from './hooks/useDateTime';

const DateTimeProvider = ({ children }: { children: React.ReactNode }) => {
  const datetime = useDateTime();

  const value: DateTimeContextType = {
    datetime,
  };

  return (
    <DateTimeContext.Provider value={value}>{children}</DateTimeContext.Provider>
  );
};

export default DateTimeProvider;
