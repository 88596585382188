import axios from 'axios';
import { NewSms } from './types/NewSms';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchSms = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/sms`, config);
  return response.data;
};
const createSms = async (jwt: string, URL: string, sms: NewSms) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const smsJson = JSON.stringify(sms);
  const response = await axios.post(`${URL}/channels/sms`, smsJson, config);
  return response.data;
};
const deleteSms = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/sms/${id}`, config);

  return id;
};
const updateSms = async (jwt: string, URL: string, sms: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const smsJson = JSON.stringify(sms);

  const response = await axios.put(`${URL}/channels/sms/${sms.id}`, smsJson, config);
  return response.data;
};

const emailService = {
  fetchSms,
  createSms,
  deleteSms,
  updateSms,
};

export default emailService;
