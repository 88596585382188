import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';

const updateResponses = async (jwt: string, URL: string, data: any) => {
  const configJson = JSON.stringify(data.config);

  const config = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.put(
    `${URL}/AutoResponses/${data.id}`,
    configJson,
    config
  );
  return response.data;
};

const getResponses = async (jwt: string, URL: string, id: string) => {
  // const config = getRequestConfig.contentTypeJson(jwt);
  const config = getRequestConfig.basic(jwt);
  const response = await axios.get(`${URL}/AutoResponses/${id}`, config);

  return response.data;
};

const responsesService = {
  getResponses,
  updateResponses,
};

export default responsesService;
