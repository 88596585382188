import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchChats } from 'redux/features/conversationsSlice/conversationsSlice';
// Components/ui
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
// Utils
import chatFilters from '../../utils/chatFilters';
// Types
import { ChatFilter } from '../../types/ChatFilter';
type FilterProps = {
  condition: ChatFilter;
  setCondition: (condition: ChatFilter) => void;
};const Filter = ({ condition, setCondition }: FilterProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConditionChange = (condition: ChatFilter) => {
    setCondition(condition);
    dispatch(fetchChats(condition.value));

    // Guardar la condición en localStorage
    localStorage.setItem('chatFilter', condition.value);
  };

  // Set the condition from the local storage if it exists on first load
  useEffect(() => {
    const localCondition = localStorage.getItem('chatFilter');
    if (localCondition) {
      const condition = chatFilters.find(
        (filter) => filter.value === localCondition
      );
      if (condition) {
        setCondition(condition);
      }
    }
  }, []);

  const menuItems = useMemo(
    () =>
      chatFilters.map((filter) => (
        <MenuItem key={filter.id} onClick={() => handleConditionChange(filter)}>
          <ListItemIcon sx={{ color: 'text.disabled' }}>{filter.icon}</ListItemIcon>
          <ListItemText>
            {t(filter.label)}
          </ListItemText>
        </MenuItem>
      )),
    [chatFilters, handleConditionChange, t]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        height: '100%',
      }}
    >
      <IconButton
        onClick={handleOpen}
        aria-describedby={'popupPrincipal'}
        size="small"
        aria-controls={openMenu ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        sx={{
          borderRadius: '25px',
          padding: '10px',
          paddingY: '5px',
          color: 'text.disabled',
        }}
      >
        {condition && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            {condition.icon}
            <Typography>{t(condition.label)}</Typography>
          </Box>
        )}
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={openMenu}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor:
            //@ts-ignore
            (theme) => theme.palette.background.panel,
          },
        }}
      >
        {menuItems}
      </Menu>
    </Box>
  );
};

export default Filter;
