export const style = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusIcon: {
    height: '13px',
    width: '13px',
  },
};
