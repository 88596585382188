import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
// Interfaces
import { IFlow } from '@trii/types/dist/Conversations/Flows';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { EndingState } from './types/EditFlowState.js';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
// Service
import editFlowService from './editFlowService';

const initialState: EndingState = {
  flow: null,
  status: {
    fetch: 'idle',
    edit: 'idle',
    charge: 'idle',
    email: 'idle',
    groups: 'idle',
    forms: 'idle',
    flows: 'idle',
    searchBy: 'idle',
    schedules: 'idle',
  },
  channels: [],
  endings: [],
  labels: [],
  file: '',
  emailChannels: [],
  groups: [],
  forms: [],
  flows: [],
  searchBy: [],
  schedules: [],
};

export const fetchEditFlow = createAsyncThunk(
  'editFlow/fetchEditFlow',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchFlow(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const editFlows = createAsyncThunk(
  'editFlow/editFlows',
  async (flow: IFlow, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const data = {
      nodes: flow.nodes,
      edges: flow.edges,
    };
    const response = await editFlowService.updateFlow(
      jwtToken,
      URL_CONVERSATIONS,
      flow.id,
      data
    );
    return response;
  }
);

export const fetchChannels = createAsyncThunk(
  'editFlow/fetchChannels',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchChannels(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

export const fetchEndings = createAsyncThunk(
  'editFlow/fetchEndings',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchEndings(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const fetchLabels = createAsyncThunk(
  'editFlow/fetchLabels',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchLabels(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);
export const fetchContactDates = createAsyncThunk(
  'editFlow/fetchContactDates',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchContactDates(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

export const fetchChargeFile = createAsyncThunk(
  'editFlow/fetchChargeFile',
  async (file: FormData, { dispatch }) => {
    const { jwtToken, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchChargeFile(
      jwtToken,
      URL_MEDIA,
      file
    );

    return response;
  }
);

export const fetchEmailChannels = createAsyncThunk(
  'editFlow/fetchEmailChannels',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchEmailChannels(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

export const fetchGroups = createAsyncThunk(
  'editFlow/fetchGroups',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchGroups(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const fetchForms = createAsyncThunk(
  'editFlow/fetchForms',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchForms(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const fetchFlows = createAsyncThunk(
  'editFlow/fetchFlows',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchFlows(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const fetchSearchBy = createAsyncThunk(
  'editFlow/fetchSearchBy',
  async (searchBy: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchSearchBy(
      jwtToken,
      URL_CONTACTS,
      searchBy
    );

    return response;
  }
);

export const fetchSchedules = createAsyncThunk(
  'editFlow/fetchSchedules',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await editFlowService.fetchSchedules(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

const editFlowSlice = createSlice({
  name: 'editFlow',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEditFlow.pending, (state) => {
        state.status.edit = 'loading';
      })
      .addCase(fetchEditFlow.fulfilled, (state, action) => {
        state.status.edit = 'succeeded';
        state.flow = action.payload;
      })
      .addCase(editFlows.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(editFlows.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.flow = action.payload;
      })
      .addCase(fetchChannels.pending, (state) => {
        state.status.edit = 'loading';
      })
      .addCase(fetchChannels.fulfilled, (state, action) => {
        state.status.edit = 'succeeded';
        state.channels = action.payload;
      })
      .addCase(fetchEndings.pending, (state) => {
        state.status.edit = 'loading';
      })
      .addCase(fetchEndings.fulfilled, (state, action) => {
        state.status.edit = 'succeeded';
        state.endings = action.payload;
      })
      .addCase(fetchLabels.pending, (state) => {
        state.status.edit = 'loading';
      })
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.status.edit = 'succeeded';
        state.labels = action.payload;
      })
      .addCase(fetchContactDates.pending, (state) => {
        state.status.edit = 'loading';
      })
      .addCase(fetchContactDates.fulfilled, (state, action) => {
        state.status.edit = 'succeeded';
        state.labels = action.payload;
      })
      .addCase(fetchChargeFile.pending, (state) => {
        state.status.charge = 'loading';
      })
      .addCase(fetchChargeFile.fulfilled, (state, action) => {
        state.status.charge = 'succeeded';
        state.file = action.payload;
      })
      .addCase(fetchEmailChannels.pending, (state) => {
        state.status.email = 'loading';
      })
      .addCase(fetchEmailChannels.fulfilled, (state, action) => {
        state.status.email = 'succeeded';
        state.emailChannels = action.payload;
      })
      .addCase(fetchGroups.pending, (state) => {
        state.status.groups = 'loading';
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.status.groups = 'succeeded';
        state.groups = action.payload;
      })
      .addCase(fetchForms.pending, (state) => {
        state.status.forms = 'loading';
      })
      .addCase(fetchForms.fulfilled, (state, action) => {
        state.status.forms = 'succeeded';
        state.forms = action.payload;
      })
      .addCase(fetchFlows.pending, (state) => {
        state.status.flows = 'loading';
      })
      .addCase(fetchFlows.fulfilled, (state, action) => {
        state.status.flows = 'succeeded';
        state.flows = action.payload;
      })
      .addCase(fetchSearchBy.pending, (state) => {
        state.status.searchBy = 'loading';
      })
      .addCase(fetchSearchBy.fulfilled, (state, action) => {
        state.status.searchBy = 'succeeded';
        state.searchBy = action.payload;
      })
      .addCase(fetchSchedules.pending, (state) => {
        state.status.schedules = 'loading';
      })
      .addCase(fetchSchedules.fulfilled, (state, action) => {
        state.status.schedules = 'succeeded';
        state.schedules = action.payload;
      });
  },
});

const selectEditFlowState = (state: RootState) => state.EditFlow;
export const selectChannelsData = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.channels
);
export const selectEndingsData = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.endings
);
export const selectLabelsData = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.labels
);
export const selectFlow = createSelector(selectEditFlowState, (state) => state.flow);
export const selectFileUrl = createSelector(
  selectEditFlowState,
  (state) => state.file
);
export const selectFlowsFetchStatus = createSelector(
  selectEditFlowState,
  (state) => state.status.fetch
);
export const selectEmailChannels = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.emailChannels
);
export const selectGroups = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.groups
);
export const selectForms = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.forms
);
export const selectFlows = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.flows
);

export const selectSearchBy = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.searchBy
);
export const selectSchedules = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.schedules
);
export const saveEditFlowStatus = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.status.fetch
);
export const chargeFileStatus = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.status.charge
);
export const selectEmailsStatus = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.status.email
);
export const selectGroupsStatus = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.status.groups
);
export const selectFormsStatus = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.status.forms
);
export const selectFlowsStatus = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.status.flows
);
export const selectSearchByStatus = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.status.searchBy
);
export const selectSchedulesStatus = createSelector(
  selectEditFlowState,
  (state: EndingState) => state.status.schedules
);
export default editFlowSlice.reducer;
