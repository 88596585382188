import { useContext, useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  selectAllLabels,
  selectLabelsFetchStatus,
} from 'redux/features/labelSlice/labelSlice';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Components/ui
import {
  Box,
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// Icons
import { Label as LabelIcon } from '@mui/icons-material';
// Types
import { ILabel } from '@trii/types/dist/Conversations';
// DB
import db, { dbWorker } from 'db/db';
import {
  selectConversationSelected,
  setConversationSelected,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

const Label = () => {
  const dispatch = useAppDispatch();
  const { handleAddLabel } = useContext(conversationsContext);
  const conversationSelected = useSelector(selectConversationSelected);
  const labels = useSelector(selectAllLabels);
  const labelsFetchStatus = useSelector(selectLabelsFetchStatus);
  const isLoading = labelsFetchStatus === 'loading';
  const [selectedLabels, setSelectedLabels] = useState<ILabel[]>([]);
  const [labelIdsSelected, setLabelIdsSelected] = useState<string[]>([]);

  const handleSelect = (label: ILabel) => {
    const labelIds = selectedLabels.map((label) => label.id);
    if (labelIds.includes(label.id)) {
      const data = {
        conversationId: conversationSelected.id,
        add: '',
        remove: label.id,
      };
      handleAddLabel(data);
      setLabelIdsSelected(
        labelIdsSelected.filter((oldLabelId) => oldLabelId !== label.id)
      );
      const newLabels = selectedLabels.filter(
        (oldLabel) => oldLabel.id !== label.id
      );
      setSelectedLabels(newLabels);
      dispatch(
        setConversationSelected({
          ...conversationSelected,
          labels: newLabels,
        })
      );
    } else {
      const data = {
        conversationId: conversationSelected.id,
        add: label.id,
        remove: '',
      };
      handleAddLabel(data);
      setLabelIdsSelected([...labelIdsSelected, label.id]);
      const newLabels = [...selectedLabels, label];
      setSelectedLabels(newLabels);
      dispatch(
        setConversationSelected({
          ...conversationSelected,
          labels: newLabels,
        })
      );
    }
  };

  useEffect(() => {
    const newLabels = {
      ...conversationSelected,
      labels: selectedLabels,
    };
    // db.updateConversation(newLabels)
    dbWorker.postMessage({
      action: 'updateConversation',
      data: newLabels,
    });
  }, [selectedLabels]);

  useEffect(() => {
    if (conversationSelected) {
      const labelIds = conversationSelected.labels.map((label) => label.id);
      setLabelIdsSelected(labelIds);
      setSelectedLabels(conversationSelected.labels);
    }
  }, [conversationSelected.id]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        overflow: 'auto',
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: '1em',
          }}
        >
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        labels &&
        labels.length > 0 &&
        labels.map((label, i) => (
          <ListItem
            key={label.id}
            onClick={() => handleSelect(label)}
            sx={{
              padding: 0,
            }}
          >
            <ListItemButton
              sx={{
                borderBottom: (theme) =>
                  i === labels.length - 1
                    ? 'none'
                    : `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <Checkbox
                checked={labelIdsSelected.includes(label.id)}
                color="primary"
                disableRipple
                size="small"
              />
              <ListItemIcon>
                <LabelIcon
                  sx={{
                    color: label.color,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={label.name}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))
      )}
    </Box>
  );
};

export default Label;
