import { useContext } from 'react';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Star } from '@mui/icons-material';

const FilterFavorites = () => {
  const { chatFilter } = useContext(messagesContext);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Typography
        sx={{
          color: (theme) => theme.palette.text.disabled,
          fontWeight: 'bold',
          fontSize: '1rem',
        }}
      >
        {t('conversations.message.search.filterFavorites')}
      </Typography>
      <FormControlLabel
        control={<Checkbox {...chatFilter.highligthMessagesCheckbox.attributes} />}
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <Star
              sx={{
                color: (theme) => theme.palette.text.disabled,
                width: '1rem',
                height: '1rem',
              }}
            />
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              {t('conversations.message.search.seeOnlyFavorites')}
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default FilterFavorites;
