export const style = {
  container: {
    position: 'absolute',
    bottom: '-1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    paddingY: '4px',
    paddingX: '6px',
    borderRadius: '4rem',
    border: '1px solid lightgray',
  },
};
