import React from 'react';
// Components/ui
import { Box, Typography } from '@mui/material';

interface Props {
  currentTime: string;
  duration: string;
  waveformRef: React.MutableRefObject<any>;
  handleWaveformClick: (e: any) => void;
}

const SoundWave = ({
  currentTime,
  duration,
  waveformRef,
  handleWaveformClick,
}: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={'center'}
      gap={1}
      width={'100%'}
    >
      <Box px={1} py={0.4} bgcolor="primary.light" borderRadius={7} width="80%">
        <Box
          id="waveFormId"
          onClick={handleWaveformClick}
          ref={waveformRef}
          width="100%"
        />
      </Box>
      <Box>
        <Typography fontSize={14}>
          {currentTime} / {duration}
        </Typography>
      </Box>
    </Box>
  );
};

export default SoundWave;
