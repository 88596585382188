import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { PbxState } from './types/PbxState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewPbx } from './types/NewPbx';
// Service
import pbxService from './pbxService';

const initialState: PbxState = {
  pbxs: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchPbxs = createAsyncThunk(
  'pbx/fetchPbxs',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await pbxService.fetchPbxs(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const createPbx = createAsyncThunk(
  'pbx/createpbx',
  async (pbx: NewPbx, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await pbxService.createPbx(jwtToken, URL_CONVERSATIONS, pbx);

    return response;
  }
);

export const deletePbx = createAsyncThunk(
  'pbx/deletePbx',
  async (pbxId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await pbxService.deletePbx(jwtToken, URL_CONVERSATIONS, pbxId);

    return response;
  }
);

const pbxSlice = createSlice({
  name: 'pbx',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPbxs.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchPbxs.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.pbxs = action.payload;
      })
      .addCase(createPbx.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createPbx.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.pbxs.push(action.payload);
      })
      .addCase(deletePbx.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deletePbx.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.pbxs = state.pbxs.filter((pbx) => pbx.id !== action.payload);
      });
  },
});

const selectPbxState = (state: RootState) => state.Pbx;
export const selectAllPbxs = createSelector(selectPbxState, (state) => state.pbxs);
export const selectPbxsFetchStatus = createSelector(
  selectPbxState,
  (state) => state.status.fetch
);
export const selectPbxCreateStatus = createSelector(
  selectPbxState,
  (state) => state.status.create
);
export const selectPbxDeleteStatus = createSelector(
  selectPbxState,
  (state) => state.status.delete
);

export default pbxSlice.reducer;
