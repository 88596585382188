// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Chip } from '@mui/material';
interface NewMessagesProps {
  onChipClick: (event: React.MouseEvent) => void;
  isScrollAtBottom: boolean;
}

const NewMessages: React.FC<NewMessagesProps> = ({
  onChipClick,
  isScrollAtBottom,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: isScrollAtBottom ? 'none' : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pr: 1,
        pl: 1,
        position: 'sticky',
        top: '46.5rem',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Chip
          onClick={(event) => onChipClick(event)}
          label={t('conversations.newMessages')}
          sx={{
            backgroundColor:
              //@ts-ignore
              (theme) => theme.palette.primary.main,
            fontWeight: 'normal',
            fontSize: '1em',
            color: (theme) => theme.palette.text.primary,
            borderRadius: '0.3rem',
            height: 'max-content',
            padding: '.2rem',
          }}
        />
      </Box>
    </Box>
  );
};

export default NewMessages;
