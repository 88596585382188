// Components/ui
import { Box, useTheme } from '@mui/material';
// Components
import { InternalChat } from './components';

const SidebarContactList = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="background.default"
      sx={{
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: '25%',
        maxWidth: '25%',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
      }}
    >
      <InternalChat />
    </Box>
  );
};

export default SidebarContactList;
