export const style = {
  container: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    transition: 'height 0.3s',
    backgroundSize: '100%',
  },
  progressContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
  secondaryContainer: {
    width: '100%',
    height: '100%',
    paddingX: '4px',
    overflow: 'auto',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
  },
};
