import { useContext } from 'react';
import { FilePreviewerContext } from 'features/Views/Conversations/context/FilePreviewerProvider/FilePreviewerContext';
// Components/ui
import { Box } from '@mui/material';
import { FileItem, AddButton } from './components';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';

const FileSelector = () => {
  const { handleFileUpload } = useContext(messagesContext);
  const { filesCopy, selectedFile, handleFileRemove, handleFileSelect } =
    useContext(FilePreviewerContext);

  const UploadedFiles = filesCopy.map((file, i: number) => (
    <FileItem
      key={i}
      item={file}
      handleFileRemove={handleFileRemove}
      handleFileSelect={handleFileSelect}
      selectedFile={selectedFile}
    />
  ));

  return (
    <Box
      sx={{
        width: '60%',
        display: 'flex',
        gap: 2,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {UploadedFiles}
      <AddButton handleFileUpload={handleFileUpload} />
    </Box>
  );
};

export default FileSelector;
