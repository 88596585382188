import { Box, Button, Divider } from '@mui/material';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap } from './components';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

const SendLocation = () => {
  const { t } = useTranslation();

  const conversationSelected = useSelector(selectConversationSelected);

  const { handleLocation, userLocationContext } = useContext(messagesContext);
  const { setOpenModalList } = useContext(conversationsContext);

  const handleClose = () => {
    setOpenModalList(false);
  };
  const handleSendLocation = useCallback(() => {
    if (userLocationContext) {
      try {
        handleLocation(userLocationContext, conversationSelected.id);
      } catch (e) {}
    }
    setOpenModalList(false);
  }, [userLocationContext]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: '100%',
            overflow: 'auto',
          }}
        >
          <GoogleMap />
        </Box>
        <Divider
          sx={{
            width: '100%',
            padding: 0,
          }}
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '.5rem',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              borderRadius: '0.5rem',
              margin: '.5rem',
            }}
            onClick={() => handleSendLocation()}
          >
            {t('conversations.message.actions.sendLocation')}
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            sx={{
              borderRadius: '0.5rem',
              margin: '.5rem',
            }}
            onClick={handleClose}
          >
            {t('global.cancel')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SendLocation;
