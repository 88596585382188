export const style = {
  container: {
    position: 'relative',
    width: '50%',
    textAlign: 'center',
    margin: '0 auto',
    marginTop: '15px',
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    boxShadow: '-3px 3px 3px rgb(0 0 0 / 15%)',
    maxWidth: '50%',
  },
};
