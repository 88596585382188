import React from 'react';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SelectConversationScreen = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      height={'100%'}
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h6" component="div" gutterBottom sx={{  color:"text.primary" }}>
        {t('conversations.emptyConversation')}
      </Typography>
    </Box>
  );
};

export default SelectConversationScreen;
