import { useNavigate, useLocation } from "react-router-dom";
// Translation
import { useTranslation } from "react-i18next";
import "./NavMenu.css";
import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
// Utils
import { iconsData } from "./utils/iconsData";

type TooltipProps = {
  className?: string;
  title: string;
  placement: "right";
  children: React.ReactElement;
};

const TooltipPers = styled(({ className, children, placement, title, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    title={title}
    placement={placement}
  >
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    //@ts-ignore
    backgroundColor: theme.palette.background.tooltip,
  },
}));

const NavMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (route) => {
    navigate(route);
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "center",
          gap: 2,
          marginTop: 2,
        }}
      >
        {
          iconsData.map((icon, index) => (
            <TooltipPers title={t(icon.title)} placement="right" key={index}>
              <IconButton
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                  borderRadius: 1,
                  backgroundColor: location.pathname === icon.to || location.pathname.includes(icon.to) ? theme => `${theme.palette.grey[200]}30` : "transparent",
                  '&:focus': {
                    outline: 'none',
                  },
                  '&:hover': {
                    backgroundColor: theme => `${theme.palette.grey[200]}30`,
                    // backgroundColor: onSelectedTheme.palette.text.secondary,
                    color: (theme) => theme.palette.primary.contrastText
                  },
                  width: '1.5em',
                  height: '1.5em',
                }}
                disableRipple
                onClick={() => handleNavigate(icon.to)}>
                {icon.icon}
              </IconButton>
            </TooltipPers>
          ))
        }
      </Box>
    </Box>
  );
};

export default NavMenu;
