// Components/ui
import { Box, Typography } from '@mui/material';
import { style } from './style';

type FormBodyProps = {
  data: any;
};
const FormBody = ({ data }: FormBodyProps) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        color={'text.primary'}
        sx={style.title}
        style={{ whiteSpace: 'nowrap' }}
      >
        {data.title}
      </Typography>
      <Typography
        color={'text.secondary'}
        variant="subtitle2"
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        {data.subTitle}
      </Typography>
      <Box>
        <Box
          sx={{
            maxWidth: 'max-content',
            margin: 'auto',
          }}
        >
          {data.responses?.map((value, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '50px',
                margin: '13px 0 13px 0',
                borderRadius: '7px',
                backgroundColor: '#F9F9F9',
                padding: '0',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
              }}
              style={{ borderLeft: '8px solid red', padding: '5px 10px' }}
            >
              <Box>
                <Box
                  style={{
                    border: 0,
                    margin: 0,
                    display: 'flex',
                    padding: 0,
                    position: 'relative',
                    minWidth: 0,
                    flexDirection: 'column',
                    verticalAlign: 'top',
                  }}
                >
                  <label
                    data-shrink="false"
                    style={{
                      color: 'black',
                      textAlign: 'start',
                      paddingBottom: '0px',
                      paddingTop: '10px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    {value.question}
                  </label>
                </Box>
                <Box
                  style={{
                    overflowWrap: 'break-word',
                    textAlign: 'justify',
                    marginLeft: '5px',
                  }}
                >
                  <span data-bind="text: response">{value.response}</span>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default FormBody;
