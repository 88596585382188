import { useContext, useEffect, useMemo, useState } from 'react';
// Components/ui
import { Avatar, Badge, Box, Chip, Typography } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Types
import {
  ChatType,
  ConversationSatus,
  IConversation,
} from '@trii/types/dist/Conversations';
import { UserInfo, UserStatus } from '@trii/types/dist/Users';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { selectUsers } from 'redux/features/userSlice/userSlice';
import { Markdown } from 'functions/Markdown';

interface Props {
  chat: IConversation;
}

const ChatItem = ({ chat }: Props) => {

  const [userInfo, setUserInfo] = useState<UserInfo>(null);
  const conversationSelected = useSelector(selectConversationSelected);
  const users = useSelector(selectUsers);

  const userStatus = users.find((user) => user.id === userInfo?.id)?.status;
  const { handleSelectConversation } = useContext(conversationsContext);
  const [date, setDate] = useState<string>('');
  const DATE_FORMAT = 'DD/MM/YYYY';
  const { t } = useTranslation();
  const markdown = Markdown(chat?.lastMessage, false);
  const newText = markdown.replace(/\n/g, '');

  const getBadgeColor = (status: UserStatus) => {
    switch (status) {
      case UserStatus.ONLINE:
        return 'success';
      case UserStatus.AWAY:
        return 'warning';
      case UserStatus.BUSY:
        return 'error';
      default:
        return 'default';
    }
  };

  useEffect(() => {
    if (chat.type === ChatType.DIRECT) {
      const member = chat.chatMembers?.find(
        (member) => member.userId !== chat.ownerId
      );
      if (member) {
        setUserInfo(member.user);
      }
    }
  }, [chat]);

  useEffect(() => {
    const updateDate = moment(chat.updatedAt).format(DATE_FORMAT)
    const today = moment().format(DATE_FORMAT)
    const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT)

    if (updateDate === today) {
      setDate(`${t('conversations.today')} ${moment(chat.updatedAt).format('HH:mm')}`)
    } else if (updateDate === yesterday) {
      setDate(`${t('conversations.yesterday')} ${moment(chat.updatedAt).format('HH:mm')}`)
    } else {
      setDate(updateDate)
    }
  }, [chat])

  const component = useMemo(
    () => (
      <Box
        onClick={() => handleSelectConversation(chat)}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          height: "4rem",
          cursor: "pointer",
          position: "relative",
          p: 1,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
          backgroundColor: (theme) =>
            conversationSelected?.id === chat.id
              ? theme.palette.action.hover
              : theme.palette.background.default,
        }}
      >
        {chat.type === ChatType.DIRECT && (
          <Badge
            color={getBadgeColor(userStatus)}
            overlap="circular"
            badgeContent=" "
            variant="dot"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              ".MuiBadge-badge": {
                backgroundColor:
                  userStatus === UserStatus.OFFLINE ? "grey" : undefined,
              },
            }}
          >
            <Avatar src={userInfo?.imageUrl} />
          </Badge>
        )}
        <Box position={"absolute"} top={0} right={"0.5rem"}>
          <Typography variant="caption" color="text.primary">
            {date}
          </Typography>
        </Box>
        {chat.type !== ChatType.DIRECT && <Avatar src={chat?.chatImage} />}
        <Box display="flex" flexDirection="column" flexGrow={1} width="20%">
          <Typography
            variant="body1"
            width="100%"
            noWrap
            fontSize={"0.875rem"}
            color="text.primary"
            fontWeight={(theme) => theme.typography.fontWeightBold}
          >
            {chat.type === ChatType.DIRECT ? userInfo?.name : chat?.chatName}
          </Typography>
            <Typography  variant="body2"
            width="100%"
            noWrap
            fontSize={'0.75rem'}
            color="text.primary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            dangerouslySetInnerHTML={{ __html: newText }}
            />
        </Box>

        {chat.status === ConversationSatus.ARCHIVED && (
          <InventoryIcon
            sx={{
              color: "text.disabled",
              marginLeft: "auto",
            }}
            fontSize="small"
          />
        )}
        {chat?.newMessagesCount > 0 && (
          <Chip
            size="small"
            label={chat.newMessagesCount}
            sx={{
              width: "max-content",
              visibility: chat?.newMessagesCount > 0 ? "visible" : "hidden",
              "&.MuiChip-root": {
                height: "22px",
              },
              marginTop: '15px'
            }}
            color="error"
          />
        )}
      </Box>
    ),
    [chat, conversationSelected, handleSelectConversation, userInfo, userStatus]
  );

  return component;
};

export default ChatItem;
