import emojiRegex from "emoji-regex";

export const Markdown = (text: string, addMarkdown: boolean) => {
  if (text) {
    const regex = /\*\*\s*([^**]+?)\s*\*\*|\*\s*([^*]+?)\s*\*|`([^`]+)`|~~\s*([^~~]+?)\s*~~|__\s*([^__]+?)\s*__/g;
    const regexEmoji = emojiRegex();

    const html = text.replace(regex, (
      match: string,
      strong: string,
      italic: string,
      code: string,
      strike: string,
      underline: string,
    ) => {
      if (strong) {
        const regexLineStart = /\*\*\n/
        const regexLineEnd = /\n\*\*/
        const span = !addMarkdown ? '' : '<span style="color: #888888; ">**</span>';
        return `${span}${regexLineStart.test(match) ? (addMarkdown ? '\n' : '') : ''}<strong>${strong}</strong>${regexLineEnd.test(match) ? (addMarkdown ? '\n' : '') : ''}${span}`;
      } else if (italic) {
        const regexLineStart = /\*\n/
        const regexLineEnd = /\n\*/
        const span = !addMarkdown ? '' : '<span style="color: #888888; ">*</span>';
        return `${span}${regexLineStart.test(match) ? (addMarkdown ? '\n' : '') : ''}<em>${italic}</em>${regexLineEnd.test(match) ? (addMarkdown ? '\n' : '') : ''}${span}`;
      } else if (code) {
        const span = !addMarkdown ? '' : '<span style="color: #888888; ">`</span>';
        return `${span}<code>${code}</code>${span}`;
      } else if (strike) {
        const regexLineStart = /~~\n/
        const regexLineEnd = /\n~~/
        const span = !addMarkdown ? '' : '<span style="color: #888888; ">~~</span>';
        return `${span}${regexLineStart.test(match) ? (addMarkdown ? '\n' : '') : ''}<s>${strike}</s>${regexLineEnd.test(match) ? (addMarkdown ? '\n' : '') : ''}${span}`;
      } else if (underline) {
        const regexLineStart = /__\n/
        const regexLineEnd = /\n__/
        const span = !addMarkdown ? '' : '<span style="color: #888888; ">__</span>';
        return `${span}${regexLineStart.test(match) ? (addMarkdown ? '\n' : '') : ''}<u>${underline}</u>${regexLineEnd.test(match) ? (addMarkdown ? '\n' : '') : ''}${span}`;
      }
    });


    const modifiedHTML = html.replace(regexEmoji, (match: string) => {
      const unicode = Array.from(match)
        .map((char) => char.codePointAt(0).toString(16))
        .join('-');
      const url = `https://cdn.jsdelivr.net/npm/emoji-datasource-facebook@14.0.0/img/facebook/64/${unicode}.png`;
      return `<span style="background-image: url(${url});" data-lexical-text="true" class="emoji"><span class="children-emoji">${match}</span></span>`;
    });
    return modifiedHTML;
  }
  return '';
};