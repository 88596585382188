import { useContext, useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, Divider, TextareaAutosize, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Slice
import { selectAddCommentStatus } from 'redux/features/messagesSlice/messagesSlice';
// DB
import db, { dbWorker } from 'db/db';

const AddComment = () => {
  const { setOpenModalList } = useContext(conversationsContext);
  const { message, addComment, setMessage } = useContext(messagesContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const addCommentStatus = useSelector(selectAddCommentStatus);
  const isLoading = addCommentStatus === 'loading';
  const [text, setText] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleClose = () => {
    setOpenModalList(false);
  };

  const handleAddComment = async () => {
    const { id: messageId, shardKey } = message;
    const data = {
      messageId,
      shardKey,
      text,
    };
    const response = await addComment(data);
    // db.updateMessage(response)
    await dbWorker.postMessage({
      action: 'updateMessage',
      data: response,
    });

    setMessage(null);
    handleClose();
  };

  useEffect(() => {
    if (message) {
      setText(message.comment?.text);
    } else {
      setText('');
    }
  }, [message]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          width: '100%',
          overflow: 'auto',
        }}
      >
        <TextareaAutosize
          style={{
            width: '-webkit-fill-available',
            margin: '1rem',
            borderRadius: '0.5rem',
            border: '1px solid #E0E0E0',
            minHeight: '4rem',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          }}
          value={text}
          onChange={handleChange}
        />
      </Box>
      <Divider
        sx={{
          width: '100%',
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '.5rem',
        }}
      >
        <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          onClick={handleClose}
        >
          {t('global.cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          onClick={handleAddComment}
          loading={isLoading}
        >
          {t('global.save')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default AddComment;
