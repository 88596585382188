// Icons
import { Inbox, Inventory } from '@mui/icons-material';
import { ChatFilter } from '../types/ChatFilter';

const chatFilters: ChatFilter[] = [
  {
    id: 1,
    label: 'conversations.internalChat.title',
    value: 'chat',
    icon: <Inbox />,
  },
  {
    id: 2,
    label: 'conversations.internalChat.archived',
    value: 'archived',
    icon: <Inventory />,
  },
];

export default chatFilters;
