import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import React, { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import { FilteredMessage } from './components';
import { style } from './style';
import ResultStateContainer from './layout/ResultStateContainer';

const FilterMessageContent = () => {
  const { t } = useTranslation();
  const FilteredMessageMemo = React.memo(FilteredMessage);
  const {
    chatFilter: { filterLoading, filteredMessages, searchField, cantFindAny },
  } = useContext(messagesContext);

  return (
    <Box sx={style.container}>
      <Typography sx={style.title}>
        {t('conversations.message.search.filterMessageContent')}
      </Typography>
      <TextField
        fullWidth
        size="small"
        label={t('conversations.message.search.search')}
        {...searchField.attributes}
      />
      <Box sx={style.resultsContainer}>
        {filterLoading && (
          <ResultStateContainer>
            <CircularProgress color="info" sx={style.loading} />
          </ResultStateContainer>
        )}
        {!filterLoading && cantFindAny && (
          <ResultStateContainer>
            <Typography variant="h6">
              {t('conversations.searchPanel.notFound')}
            </Typography>
          </ResultStateContainer>
        )}
        {!filterLoading &&
          filteredMessages.length > 0 &&
          filteredMessages.map((message) => (
            <FilteredMessageMemo key={message.id} data={message} />
          ))}
      </Box>
    </Box>
  );
};

export default FilterMessageContent;
