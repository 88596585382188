// Components/ui
import { Box, ListItemButton, ListItemText, Radio } from "@mui/material";
// Types
import { IChannelInfo } from "@trii/types/dist/Common/Channels";
import { IContactAddress } from "@trii/types/dist/Contacts";

interface ListSelectorProps {
  list: IChannelInfo[] | IContactAddress[];
  selectedId: string;
  onSelect: (item: string[]) => void;
}

const ListSelector = ({
  list,
  selectedId,
  onSelect,
}: ListSelectorProps) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      overflow: 'auto',
    }}
  >
    {
      list?.map((item) => (
        <ListItemButton
          key={item.id}
          sx={{
            width: '100%',
          }}
          onClick={() => onSelect([item.id])}
          selected={selectedId === item.id}
        >
          <ListItemText
            primary={item.name || item.address}
          />
          <Radio
            checked={selectedId === item.id}
            color="primary"
            inputProps={{ 'aria-label': item.name || item.address }}
          />
        </ListItemButton>
      ))
    }
  </Box>
);

export default ListSelector;