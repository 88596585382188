import { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
// Components
import { Actions } from './components';
// Icons
import {
  ArrowForward,
  ConfirmationNumber,
  Print,
  Reply,
  ReplyAll,
} from '@mui/icons-material';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { MessageEmail } from '@trii/types/dist/Common/Messages';

interface Props {
  email: MessageEmail;
  date: string;
  onClose: () => void;
}

const ActionButtons = ({ email, date, onClose }: Props) => {
  const {
    setTo,
    setIsCc,
    setCc,
    setIsBcc,
    setBcc,
    setSubjectDraft,
    setOpenEmailModal,
  } = useContext(messagesContext);
  const { t } = useTranslation();

  const iconProps = {
    fontSize: 'medium',
    color: 'text.disabled',
  };

  const handleResponse = (type: string) => {
    setSubjectDraft(`RE: ${email.subject}`);
    if (type === 'reply') {
      setTo(email.to);
    } else if (type === 'replyAll') {
      setTo(email.to);
      if (email.cc.length > 0) {
        setIsCc(true);
        setCc(email.cc);
      }
      if (email.bcc.length > 0) {
        setIsBcc(true);
        setBcc(email.bcc);
      }
    } else if (type === 'forward') {
      setSubjectDraft(`FW: ${email.subject}`);
    }
    onClose();
    setOpenEmailModal(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        gap: 1,
      }}
    >
      <Typography variant="body2" color="textSecondary">
        {date}
      </Typography>
      <Actions
        title={t('conversations.channel.email.reply')}
        onClick={() => handleResponse('reply')}
        icon={<Reply sx={iconProps} />}
      />
      <Actions
        title={t('conversations.channel.email.replyAll')}
        onClick={() => handleResponse('replyAll')}
        icon={<ReplyAll sx={iconProps} />}
      />
      <Actions
        title={t('conversations.channel.email.forward')}
        onClick={() => handleResponse('forward')}
        icon={<ArrowForward sx={iconProps} />}
      />
      <Actions
        title={t('conversations.channel.email.createTicket')}
        onClick={() => {}}
        icon={<ConfirmationNumber sx={iconProps} />}
      />
      <Actions
        title={t('conversations.channel.email.print')}
        onClick={() => {}}
        icon={<Print sx={iconProps} />}
      />
    </Box>
  );
};

export default ActionButtons;
