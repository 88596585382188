import { ChannelType } from '@trii/types/dist/Common/Channels';
// Icons
import {
  MailOutline,
  Facebook,
  ListAlt,
  Instagram,
  SettingsPhone,
  DialerSip,
  Sms,
  Forum,
  WhatsApp,
  DoNotDisturb,
} from '@mui/icons-material';
import GoogleBusinessIcon from 'utils/icons/GoogleBusinessIcon';
import MercadoLibreIcon from 'utils/icons/MercadoLibreIcon';
// Types
import { ImageIcon } from 'types/ImageIcon';

const getImage = (type: ChannelType, size?: number): ImageIcon => {
  const style = {
    color: 'text.disabled',
    fontSize: size || 20,
  }
  const facebook = {
    title: 'conversations.channel.facebook',
    icon: <Facebook fontSize="small" sx={style} />,
    channel: 'facebook',
  }
  switch (type) {
    case ChannelType.INSTAGRAM:
      return {
        title: 'conversations.channel.instagram',
        icon: <Instagram fontSize="small" sx={style} />,
        channel: 'instagram',
      };
    case ChannelType.FACEBOOK:
      return facebook;
    case ChannelType.FACEBOOKPAGE:
      return facebook;
    case ChannelType.WEBCHAT:
      return {
        title: 'conversations.channel.webchat',
        icon: <Forum fontSize="small" sx={style} />,
        channel: 'webchat',
      };
    case ChannelType.WHATSAPP:
      return {
        title: 'conversations.channel.whatsapp',
        icon: <WhatsApp fontSize="small" sx={style} />,
        channel: 'whatsapp',
      };
    case ChannelType.GOOGLEBUSINESS:
      return {
        title: 'conversations.channel.googlebusiness',
        icon: <GoogleBusinessIcon size={size || 20} />,
        channel: 'googlebusiness',
      };
    case ChannelType.MERCADOLIBRE:
      return {
        title: 'conversations.channel.mercadolibre',
        icon: <MercadoLibreIcon size={size || 20} />,
        channel: 'mercadolibre',
      };
    case ChannelType.EMAIL:
      return {
        title: 'conversations.channel.email.title',
        icon: <MailOutline fontSize="small" sx={style} />,
        channel: 'email',
      };
    case ChannelType.SMS:
      return {
        title: 'conversations.channel.sms',
        icon: <Sms fontSize="small" sx={style} />,
        channel: 'sms',
      };
    case ChannelType.PHONE:
      return {
        title: 'conversations.channel.phone',
        icon: <SettingsPhone fontSize="small" sx={style} />,
        channel: 'phone',
      };
    case ChannelType.SIP:
      return {
        title: 'conversations.channel.sip',
        icon: <DialerSip fontSize="small" sx={style} />,
        channel: 'sip',
      };
    case ChannelType.FORM:
      return {
        title: 'conversations.channel.form',
        icon: <ListAlt fontSize="small" sx={style} />,
        channel: 'form',
      };
    default:
      return {
        title: 'conversations.channel.unknown',
        icon: <DoNotDisturb fontSize="small" sx={style} />,
        channel: 'unknown',
      };
  }
};

export default getImage;
