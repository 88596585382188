import { useContext } from 'react';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { Delete } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { CommentData, IMessage } from '@trii/types/dist/Common/Messages';
import moment from 'moment';
// DB
import db, { dbWorker } from 'db/db';

const FORMAT_DATE = 'DD/MM/YYYY HH:mm';

interface Props {
  message: IMessage;
  commentName: string;
  comment: CommentData;
}

const CommentMessage = ({ message, commentName, comment }: Props) => {
  const { deleteComment } = useContext(messagesContext);

  const handleDeleteComment = async () => {
    const data = {
      messageId: message.id,
      shardKey: message.shardKey,
    };
    const response = await deleteComment(data);

    await dbWorker.postMessage({
      action: 'updateMessage',
      data: response,
    });
    // db.updateMessage(response)
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '0.5rem',
        gap: 1,
        mt: 1,
        p: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
        }}
      >
        <Typography
          variant="body2"
          color={(theme) => theme.palette.text.primary}
          fontWeight={'bold'}
          fontSize={'0.75rem'}
          textAlign={'center'}
        >
          {commentName} - {moment(comment?.timestamp).format(FORMAT_DATE)}
        </Typography>
        <IconButton size="small" onClick={handleDeleteComment}>
          <Delete
            fontSize="small"
            sx={{
              color: (theme) => theme.palette.error.light,
            }}
          />
        </IconButton>
      </Box>
      <Typography
        variant="body2"
        color={(theme) => theme.palette.text.primary}
        sx={{
          overflow: 'hidden',
          wordBreak: 'break-word',
        }}
      >
        {comment?.text}
      </Typography>
    </Box>
  );
};

export default CommentMessage;
