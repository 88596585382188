// Components/ui
import { Box, IconButton, Badge, Snackbar, useTheme } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

type ArrowDownButtonProps = {
  handleScrollToBottom: (event: React.MouseEvent<HTMLButtonElement>) => void;
  // newMessageCount: number;
  showSnackbar: boolean;
  setShowSnackbar: (showSnackbar: boolean) => void;
  unreadMessagesIds: string[];
};

const ArrowDownButton = ({
  handleScrollToBottom,
  showSnackbar,
  setShowSnackbar,
  unreadMessagesIds,
}: ArrowDownButtonProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 2,
        right: '20px',
        bottom: '20px',
      }}
    >
      <IconButton
        onClick={(event) => handleScrollToBottom(event)}
        sx={{
          //@ts-ignore
          backgroundColor: theme.palette.background.dropdownMenu,
          color: theme.palette.text.primary,
          '&:hover': {
            //@ts-ignore
            backgroundColor: theme.palette.background.dropdownMenu,
          },
        }}
      >
        <Badge
          badgeContent={unreadMessagesIds.length > 0 && unreadMessagesIds.length}
          color="error"
          invisible={unreadMessagesIds.length === 0}
        >
          <ArrowDownwardIcon />
        </Badge>
      </IconButton>
      {/* New Messages */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Hay mensajes nuevos"
      />
    </Box>
  );
};

export default ArrowDownButton;
