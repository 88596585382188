import { useState, useContext } from 'react';
// Context
// import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { FilePreviewerContext } from 'features/Views/Conversations/context/FilePreviewerProvider/FilePreviewerContext';
// Types
import DocumentFile from 'features/Views/Conversations/context/MessagesProvider/types/DocumentFile';
import DocumentExtension from '../../../../../DocumentIcon/types/DocumentExtension';
import { MessageDocument } from '@trii/types/dist/Common/Messages';
// Components/ui
import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import Gradient from '../Gradient/index.js';
import DocumentIcon from '../../../../../DocumentIcon';

interface Props {
  item: MessageDocument;
  selectedDocument: DocumentFile;
  handleDocumentRemove: (id: string) => void;
  handleDocumentSelect: (id: string) => void;
}

const DocumentItem = ({
  item,
  selectedDocument,
  handleDocumentRemove,
  handleDocumentSelect,
}: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  // const { getFileType } = useContext(messagesContext);
  const { getFileType } = useContext(FilePreviewerContext);
  const fileType = getFileType(item.mimeType);

  return (
    <Box
      sx={{
        border: (theme) =>
          selectedDocument?.id === item.id
            ? `2.8px solid ${theme.palette.primary.main}`
            : '1px solid #d1d5db',
        borderRadius: '4px',
      }}
      style={{ position: 'relative' }}
    >
      <Box
        sx={{
          width: 60,
          height: 55,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '2px',
        }}
        onClick={() => handleDocumentSelect(item.id)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {isHovering && <Gradient />}
        <DocumentIcon
          documentType={
            fileType === 'image' || fileType === 'video'
              ? fileType
              : (item?.filename?.split('.')[1] as DocumentExtension)
          }
        />
        {isHovering && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0.5,
              borderRadius: '50%',
              width: 23,
              height: 23,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleDocumentRemove(item.id);
            }}
          >
            <Close
              sx={{
                fontSize: '20px',
                color: '#fff',
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DocumentItem;
