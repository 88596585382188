import { useTranslation } from 'react-i18next';
// Components/ui
import { Typography, useTheme } from '@mui/material';

const NewMessagesLabel = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Typography
      variant="caption"
      sx={{
        bgcolor:
          theme.palette.mode === 'light'
            ? theme.palette.background.default
            : //@ts-ignore
              theme.palette.background.dropdownMenu,
        paddingY: '4px',
        paddingX: '8px',
        marginY: '5px',
        borderRadius: '20px',
      }}
      color={theme.palette.text.primary}
    >
      {t('conversations.newMessages')}
    </Typography>
  );
};

export default NewMessagesLabel;
