import { useContext } from 'react';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import { Box, Typography } from '@mui/material';
// Types
import DocumentExtension from 'features/Views/Conversations/components/components/DocumentIcon/types/DocumentExtension';
import {
  MessageDocument,
  MessageHeaderType,
} from '@trii/types/dist/Common/Messages';
// Components
import DocumentIcon from 'features/Views/Conversations/components/components/DocumentIcon/DocumentIcon';
import { Loader } from './components';
// Hooks
import useSAS from 'hooks/useSAS';

interface Props {
  documents: MessageDocument[];
  messageId: string;
  templateFileType?: MessageHeaderType;
}

const Document = ({ documents, messageId, templateFileType }: Props) => {
  const { getFileType, getTemplateHeaderFileType, handleUpload } =
    useContext(messagesContext);

  const URLHandler = useSAS();

  const handleTryAgain = () => {
    const filterDocument = documents.filter((document) => document.url === '');
    handleUpload(filterDocument, messageId);
  };

  const handleDownload = async (url: string) => {
    const link = document.createElement('a');
    const fileName = url.split('/').pop();
    const accessURL = await URLHandler.getURLWithAccessToken(url);

    link.download = fileName || '';
    link.href = accessURL;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Box>
      {documents &&
        documents.length > 0 &&
        documents.map((document, i) => {
          const { id, mimeType, filename, url } = document;
          const documentType =
            getFileType(mimeType) || getTemplateHeaderFileType(templateFileType);

          return (
            <Box
              key={i}
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto 2rem',
                gap: '1em',
                placeItems: 'center',
                padding: '0.5em 1em',
                justifyContent: 'space-between',
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
                margin: '3px 0',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
              onClick={() => handleDownload(url)}
            >
              <Box
                sx={{
                  width: '3rem',
                  height: '3rem',
                }}
              >
                <DocumentIcon
                  documentType={
                    documentType === 'image' || documentType === 'video'
                      ? documentType
                      : (filename?.split('.').pop() as DocumentExtension)
                  }
                />
              </Box>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  textAlign: 'center',
                  marginTop: 1,
                  color: (theme) => theme.palette.text.secondary,
                }}
                noWrap
              >
                {filename}
              </Typography>
              <Loader
                url={url}
                documentId={id}
                handleTryAgain={handleTryAgain}
                handleDownload={handleDownload}
              />
            </Box>
          );
        })}
    </Box>
  );
};

export default Document;
