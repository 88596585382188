import React from 'react';
// Components/ui
import { IconButton, useTheme } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

interface Props {
  isPlaying: boolean;
  playAudio: () => void;
  stopAudio: () => void;
}

const ToggleAudioButton = ({ isPlaying, playAudio, stopAudio }: Props) => {
  const theme = useTheme();
  return (
    <>
      {
        isPlaying ? (
          <IconButton
            disableRipple
            style={{
              padding: 0,
              color: theme.palette.text.disabled,
            }}
            onClick={stopAudio}
          >
            <PauseIcon />
          </IconButton>
        ) : (
          <IconButton
            disableRipple
            style={{
              padding: 0,
              color: theme.palette.text.disabled,
            }}
            onClick={playAudio}
          >
            <PlayArrowIcon />
          </IconButton>

        )
      }
    </>
  );
};

export default ToggleAudioButton;
