import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { AccessTime, Check, DoneAll } from '@mui/icons-material';
// Types
import { MessageAck, ackLog } from '@trii/types/dist/Common/Messages';
import moment from 'moment';

type StatusProps = {
  data: ackLog;
};
const Status = ({ data }: StatusProps) => {
  const { t } = useTranslation();

  const getStatus = (ack: number) => {
    const style = {
      fontSize: 'small',
      color: 'text.disabled',
    };

    switch (ack) {
      case MessageAck.ACK_PENDING:
        return {
          title: t('conversations.message.status.pending'),
          icon: <AccessTime sx={style} />,
        };
      case MessageAck.ACK_SERVER:
        return {
          title: t('conversations.message.status.sent'),
          icon: <Check sx={style} />,
        };
      case MessageAck.ACK_DEVICE:
        return {
          title: t('conversations.message.status.delivered'),
          icon: <DoneAll sx={style} />,
        };
      case MessageAck.ACK_READ:
        return {
          title: t('conversations.message.status.read'),
          icon: <DoneAll sx={{ ...style, color: 'blue' }} />,
        };
      case MessageAck.ACK_ERROR:
        return {
          title: t('conversations.message.status.failed'),
          icon: (
            <Check sx={{ ...style, color: (theme) => theme.palette.error.main }} />
          ),
        };
      default:
        return {
          title: t('conversations.message.status.pending'),
          icon: <AccessTime sx={style} />,
        };
    }
  };

  const status = getStatus(data.ack);

  return (
    <ListItem
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box width="100%" display="flex" alignItems="center">
        <ListItemIcon>{status.icon}</ListItemIcon>
        <Box>
          <ListItemText
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {status.title}
          </ListItemText>
          {data?.errorMessage !== '' && (
            <ListItemText
              sx={{
                color: (theme) => theme.palette.error.main,
              }}
            >
              <Typography fontSize={'11px'}>{data.errorMessage}</Typography>
            </ListItemText>
          )}
        </Box>
      </Box>
      <ListItemText
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          color: (theme) => theme.palette.text.disabled,

        }}
      >
        {moment(data.timestamp).format('DD/MM/YYYY HH:mm:ss')}
      </ListItemText>
    </ListItem>
  );
};

export default Status;
