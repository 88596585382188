import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
//Types
import { IChannel } from '@trii/types/dist/Common/Channels';

const fetchWebChat = async (
  jwt: string,
  URL: string,
  id: string
): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/webChat/${id}`, config);
  return response.data;
};
const updateWebChat = async (jwt: string, URL: string, webChat: IChannel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const webChatJson = JSON.stringify(webChat);

  const response = await axios.put(
    `${URL}/channels/webChat/${webChat.id}`,
    webChatJson,
    config
  );
  return response.data;
};
const uploadImage = async (jwt: string, URL: string, image: File) => {
  const config = getRequestConfig.contentTypeFormData(jwt);
  const formData = new FormData();
  formData.append('image', image);

  console.log(URL, formData, config);

  const response = await axios.post(URL, formData, config);
  return response.data;
};

const webChatEditService = {
  fetchWebChat,
  updateWebChat,
  uploadImage,
};

export default webChatEditService;
