import { useContext, useRef } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  resetContacts,
  selectContacts,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import { selectContactsFetchStatus } from 'redux/features/contactInfoSlice/contactInfoSlice';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Types
import { ArrowDropDown, Clear, Person } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Pagination } from 'redux/features/contactInfoSlice/types/Pagination';
import { IContactAddress } from '@trii/types/dist/Contacts';
// ID
import { v4 as uuidv4 } from 'uuid';
interface DestinationBoxProps {
  title: string;
  isCc: boolean;
  setIsCc: (value: boolean) => void;
  isBcc: boolean;
  setIsBcc: (value: boolean) => void;
  selectedList: IContactAddress[];
  handleChangeEmailTo: (
    event: React.ChangeEvent<{}>,
    newValue: IContactAddress[]
  ) => void;
  openModal: boolean;
}

const EMAIL_REGEX = /\S+@\S+\.\S+/;

const PER_PAGE = 10;
const ORDER = 'DESC';
const ORDER_COLUMN = 'name';
const FORMAT = 'IContact';
const OPERATOR = 'OR';

const DATA = {
  currentPage: 1,
  perPage: PER_PAGE,
  order: ORDER,
  orderColumn: ORDER_COLUMN,
  format: FORMAT,
  operator: OPERATOR,
  filter: [
    {
      column: 'Name',
      condition: '',
      value: '',
    },
    {
      column: 'Email',
      condition: '',
      value: '',
    },
  ],
};

const DestinationBox = ({
  title,
  isCc,
  setIsCc,
  isBcc,
  setIsBcc,
  selectedList,
  handleChangeEmailTo,
  openModal,
}: DestinationBoxProps) => {
  const { handleSearchContact } = useContext(messagesContext);
  const dispatch = useAppDispatch();
  const [contacts, setContacts] = useState<IContactAddress[]>([]);
  const [globalTimer, setGlobalTimer] = useState<NodeJS.Timeout | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [inputValue, setInputValue] = useState<string>('');
  const [isNextPage, setIsNextPage] = useState<boolean>(false);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const [isLoadingTimer, setIsLoadingTimer] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const newContacts = useSelector(selectContacts);
  const contactInfoFetchStatus = useSelector(selectContactsFetchStatus);
  const isLoading = contactInfoFetchStatus === 'loading';

  // Currying function
  const handleClick = (setState: (value: boolean) => void) => () => {
    setState(true);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    setIsNextPage(false);
    setAllLoaded(false);
    setContacts([]);
    setCurrentPage(1);
    if (!value) return;
    if (globalTimer) clearTimeout(globalTimer);
    setIsLoadingTimer(true);
    const timer = setTimeout(() => {
      dispatch(resetContacts());
      const data = {
        ...DATA,
        filter: [
          {
            ...DATA.filter[0],
            value,
          },
          {
            ...DATA.filter[1],
            value,
          },
        ],
      } as Pagination;
      handleSearchContact(data);
      setIsLoadingTimer(false);
    }, 3000);
    setGlobalTimer(timer);
    return () => clearTimeout(timer);
  };

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    const bottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (bottom && !allLoaded) {
      setIsNextPage(true);
      const newPage = currentPage + 1;
      console.log('newPage', newPage);
      setCurrentPage(newPage);
      const newData = {
        ...DATA,
        currentPage: newPage,
        filter: [
          {
            ...DATA.filter[0],
            value: inputValue,
          },
        ],
      } as Pagination;
      handleSearchContact(newData);
    }
  };

  const handleClose = () => {
    setInputValue('');
    setContacts([]);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      if (
        EMAIL_REGEX.test(inputValue) &&
        !isLoading &&
        !isLoadingTimer &&
        contacts.length === 0
      ) {
        const id = uuidv4();
        const newEmail = {
          id,
          channelType: 0,
          channelId: null,
          address: inputValue,
          profileName: '',
          profileUrl: '',
          telInfo: null,
          note: '',
          isVerified: false,
          isFavorite: false,
          dateLastMsgIn: null,
          dateLastMsgOut: null,
          createdAt: new Date(),
          verifiedAt: new Date(),
          verifiedBy: '',
          subscriptionsAllowed: null,
          subscriptionsRejected: null,
        };
        handleChangeEmailTo(e, [...selectedList, newEmail]);
        setInputValue('');
      }
    }
  };

  useEffect(() => {
    if (newContacts && newContacts?.contactos.length > 0) {
      const newEmails = newContacts.contactos.map((contact) => {
        return contact.emails;
      });
      if (isNextPage) {
        if (newContacts?.paginacion.total === contacts.length) {
          setAllLoaded(true);
          return;
        }
        setContacts([...contacts, ...newEmails.flat()]);
      } else {
        setContacts(newEmails.flat());
      }
    }
  }, [newContacts]);

  return (
    <Box
      maxHeight='3rem'
      height='100%'
      width='100%'
      display='flex'
      alignItems='center'
      gap={1}
    >
      <Typography
        variant='body2'
        fontSize='.8rem'
        width='4rem'
        color={(theme) => theme.palette.text.primary}
      >
        {title}
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <Autocomplete
          id='asynchronous-search-contact'
          sx={{
            height: '3rem',
          }}
          fullWidth
          multiple
          disableCloseOnSelect
          noOptionsText={t('conversations.message.email.selectEmail')}
          limitTags={isBcc && isCc ? (openModal ? 6 : 3) : openModal ? 5 : 2}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option?.address}
          options={contacts}
          value={selectedList}
          onChange={handleChangeEmailTo}
          onClose={handleClose}
          onOpen={handleClose}
          filterOptions={(x) => x}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                sx={{
                  height: '1.2rem',
                  fontSize: '.8rem',
                  '& .MuiChip-deleteIcon': {
                    height: '1rem',
                    width: '1rem',
                  },
                }}
                clickable={false}
                label={option?.address}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              ref={inputRef}
              placeholder={t('conversations.message.email.search')}
              fullWidth
              size='small'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {title === t('conversations.message.email.to') &&
                      (!isCc || !isBcc) && (
                        <InputAdornment position='end'>
                          {!isCc && (
                            <Typography
                              variant='body2'
                              fontSize='.8rem'
                              width='3rem'
                              sx={{
                                color: (theme) => theme.palette.text.disabled,
                                '&:hover': {
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                },
                              }}
                              onClick={handleClick(setIsCc)}
                            >
                              CC
                            </Typography>
                          )}
                          {!isBcc && (
                            <Typography
                              variant='body2'
                              fontSize='.8rem'
                              width='3rem'
                              sx={{
                                color: (theme) => theme.palette.text.disabled,
                                '&:hover': {
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                },
                              }}
                              onClick={handleClick(setIsBcc)}
                            >
                              BCC
                            </Typography>
                          )}
                        </InputAdornment>
                      )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              value={inputValue}
              onChange={handleSearch}
              onKeyDown={handleKeyDown}
            />
          )}
          ListboxProps={{
            sx: {
              maxHeight: '15rem',
              overflowY: 'auto',
            },
            onScroll: (e) => handleScroll(e),
          }}
          popupIcon={
            <ArrowDropDown
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
              fontSize='small'
            />
          }
          clearIcon={
            <Clear
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
              fontSize='small'
            />
          }
        />
        {((isLoading && inputRef.current?.value === inputValue) ||
          isLoadingTimer) && (
          <LinearProgress
            sx={{
              width: '100%',
              position: 'absolute',
              top: '2.2rem',
              borderRadius: 1,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default DestinationBox;
