import { TemplatesContext } from 'features/Views/Templates/context/TemplatesContext';
import { useEffect, useState, useContext, useRef } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  selectTemplates,
  selectTemplatesFetchStatus,
} from 'redux/features/templatesSlice/utils/selectors';
// Components/ui
import {
  Box,
  CircularProgress,
  IconButton,
  MenuList,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';
import { Send, Settings } from '@mui/icons-material';
import { MessagePreview, TemplateItem } from './components';
// Types
import { TemplatesList } from 'features/Views/Templates/context/types/Lists';

type VariableProps = {
  variable: string;
  anchorEl: HTMLDivElement | null;
  caretPosition: number;
  handleClose: () => void;
  onSelect: (variable: string) => void;
  handleSendMessage: (value: string) => void;
};

const Templates = ({
  variable,
  anchorEl,
  caretPosition,
  handleClose,
  handleSendMessage,
}: VariableProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const {
    setFocusType,
    setIsEditing,
    sendTemplateMessage,
    selectedTemplate,
    setSelectedTemplate,
  } = useContext(TemplatesContext);

  const menuListRef = useRef(null);

  const templates = useSelector(selectTemplates);
  const templatesFetchStatus = useSelector(selectTemplatesFetchStatus);

  const isLoading = templatesFetchStatus === 'loading';

  const [editedMessage, setEditedMessage] = useState<string | null>(null);
  const [popoverFocused, setPopoverFocused] = useState(false);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState<number | null>(
    null
  );

  const handleOpenTemplateConfig = () => {
    window.open('/a/chat/templates', '_blank');
  };

  const handleKeyDownPopover = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      handleClose();
    }
  };

  useEffect(() => {
    setFocusType('templateList');
  }, []);

  useEffect(() => {
    if (caretPosition > 0) {
      const sel = window.getSelection();
      if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        setPopoverFocused(true);
      }
    } else {
      setPopoverFocused(false);
    }

    setSelectedTemplateIndex(null);
  }, [anchorEl, variable, popoverFocused]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            width: 'calc(100vw - 580px)',
            maxWidth: '-webkit-fill-available',
            maxHeight: '100%',
            minWidth: 100,
            //@ts-ignore
            backgroundColor: theme.palette.background.panel,
            backgroundImage: 'unset',
            [theme.breakpoints.down('lg')]: {
              left: '150px !important',
              width: '80%',
            },
          },
        }}
        onKeyDown={handleKeyDownPopover}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
              padding: '1rem',
              //@ts-ignore
              backgroundColor: theme.palette.background.panel,
            }}
            tabIndex={-1}
          >
            <Typography variant="body2">{t('templates.templateList')}</Typography>
            <IconButton
              onClick={handleOpenTemplateConfig}
              size="small"
              tabIndex={-1}
            >
              <Settings />
            </IconButton>
          </Box>
          <Box display={'flex'} sx={{ height: '550px' }}>
            {/* lista de templates */}
            <MenuList
              sx={{
                width: '45%',
                //@ts-ignore
                bgcolor: (theme) => theme.palette.background.panelCenter,
                overflowY: 'auto',
                overflowX: 'hidden',
                scrollBehavior: 'smooth',
                maxHeight: '100%',
                border: `1px solid ${theme.palette.divider}`,
                '&:focus-visible': {
                  outline: 'none',
                },
              }}
              ref={menuListRef}
              autoFocus={false}
            >
              {isLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    margin: '1rem',
                  }}
                />
              ) : templates.length > 0 ? (
                templates.map((template, index) => (
                  <TemplateItem
                    key={index}
                    index={index}
                    template={template}
                    setSelectedTemplate={setSelectedTemplate}
                    setSelectedTemplateIndex={setSelectedTemplateIndex}
                    selectedTemplateIndex={selectedTemplateIndex}
                    setFocusType={setFocusType}
                    setIsEditing={setIsEditing}
                    setEditedMessage={setEditedMessage}
                  />
                ))
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    width: '100%',
                    height: '100%',
                    padding: '1rem',
                  }}
                >
                  {t('conversations.message.variableNoResults')}
                </Typography>
              )}
            </MenuList>
            {/* Preview */}
            <MessagePreview selectedTemplate={selectedTemplate} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 1,
              padding: '0 1rem',
              paddingRight: '5px',
              marginY: '5px',
              //@ts-ignore
              backgroundColor: theme.palette.background.panel,
            }}
          >
            <IconButton
              onClick={sendTemplateMessage}
              size="small"
              sx={{ color: 'text.disabled' }}
            >
              <Send />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default Templates;
