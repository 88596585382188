import { useState, useContext } from 'react';
// Context
// import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { FilePreviewerContext } from 'features/Views/Conversations/context/FilePreviewerProvider/FilePreviewerContext';
// Types
import ImageFile from '../../../../../../../context/MessagesProvider/types/ImageFile';
import MediaFile from 'features/Views/Conversations/context/MessagesProvider/types/MediaFile';
// Components/ui
import { Box, CardMedia } from '@mui/material';
import { Close } from '@mui/icons-material';
import Gradient from '../Gradient/index.js';

interface Props {
  item: MediaFile;
  selectedFile: ImageFile;
  handleFileRemove: (id: string) => void;
  handleFileSelect: (id: string) => void;
}

const FileItem = ({
  item,
  selectedFile,
  handleFileRemove,
  handleFileSelect,
}: Props) => {
  // const { getFileType } = useContext(messagesContext);
  const { getFileType } = useContext(FilePreviewerContext);
  const [isHovering, setIsHovering] = useState(false);
  const fileType = getFileType(item.mimeType);

  return (
    <Box
      sx={{
        border: (theme) =>
          selectedFile?.id === item.id
            ? `2.8px solid ${theme.palette.primary.main}`
            : '1px solid #d1d5db',
        borderRadius: '4px',
      }}
      style={{ position: 'relative' }}
    >
      <Box
        sx={{
          width: 60,
          height: 55,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '2px',
        }}
        onClick={() => handleFileSelect(item.id)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {isHovering && <Gradient />}
        <CardMedia
          component={`${fileType === 'image' ? 'img' : 'video'}`}
          src={item.previewUrl || item.url}
          alt={`preview`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 'p2x',
          }}
        />
        {isHovering && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0.5,
              borderRadius: '50%',
              width: 23,
              height: 23,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleFileRemove(item.id);
            }}
          >
            <Close
              sx={{
                fontSize: '20px',
                color: '#fff',
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FileItem;
