// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { useContext } from 'react';
// Date
import moment from 'moment';
// Components/ui
import { Box, Divider, Typography } from '@mui/material';
// Utils
import getImage from 'functions/getImage';
// Types
import { IMessage } from '@trii/types/dist/Common/Messages';
import { style } from './style';

type FilteredMessageProps = {
  data: IMessage;
};
const FilteredMessage = ({ data }: FilteredMessageProps) => {
  const { handleNavigateToRepliedMessage } = useContext(messagesContext);

  const date = moment(data.timestamp).format('DD/MM/YYYY');
  const time = moment(data.timestamp).format('HH:mm');

  const { icon } = getImage(data.channelInfo?.type);

  return (
    <Box
      onClick={() => handleNavigateToRepliedMessage(data.id)}
      sx={style.container}
    >
      <Box sx={style.dateContainer}>
        <Typography variant={'caption'} color={'text.secondary'}>
          {date}
        </Typography>
        <Typography variant={'caption'} color={'text.secondary'}>
          {time}
        </Typography>
      </Box>
      <Box sx={style.messageContainer}>
        {icon}
        <Typography>{data.text?.body}</Typography>
      </Box>
      <Divider sx={{ marginTop: '0.1rem' }} />
    </Box>
  );
};

export default FilteredMessage;
