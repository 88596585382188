import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { MercadoLibreState } from './types/MercadoLibreState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewMercadoLibre } from './types/NewMercadoLibre';
// Service
import mercadoLibreService from './mercadoLibreService';

const initialState: MercadoLibreState = {
  mercadoLibres: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchMercadoLibres = createAsyncThunk(
  'mercadoLibre/fetchMercadoLibres',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await mercadoLibreService.fetchMercadoLibres(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

export const createMercadoLibre = createAsyncThunk(
  'mercadoLibre/createMercadoLibre',
  async (mercadoLibre: NewMercadoLibre | null, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await mercadoLibreService.createMercadoLibre(
      jwtToken,
      URL_CONVERSATIONS,
      mercadoLibre
    );

    return response;
  }
);

export const deleteMercadoLibre = createAsyncThunk(
  'mercadoLibre/deleteMercadoLibre',
  async (mercadoLibreId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await mercadoLibreService.deleteMercadoLibre(
      jwtToken,
      URL_CONVERSATIONS,
      mercadoLibreId
    );

    return response;
  }
);

const mercadoLibreSlice = createSlice({
  name: 'mercadoLibre',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMercadoLibres.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchMercadoLibres.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.mercadoLibres = action.payload;
      })
      .addCase(createMercadoLibre.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createMercadoLibre.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        if (typeof action.payload !== 'string')
          state.mercadoLibres.push(action.payload);
      })
      .addCase(deleteMercadoLibre.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteMercadoLibre.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.mercadoLibres = state.mercadoLibres.filter(
          (mercadoLibre) => mercadoLibre.id !== action.payload
        );
      });
  },
});

const selectMercadoLibreState = (state: RootState) => state.MercadoLibre;
export const selectAllMercadoLibres = createSelector(
  selectMercadoLibreState,
  (state) => state.mercadoLibres
);
export const selectMercadoLibresFetchStatus = createSelector(
  selectMercadoLibreState,
  (state) => state.status.fetch
);
export const selectMercadoLibreCreateStatus = createSelector(
  selectMercadoLibreState,
  (state) => state.status.create
);
export const selectMercadoLibreDeleteStatus = createSelector(
  selectMercadoLibreState,
  (state) => state.status.delete
);

export default mercadoLibreSlice.reducer;
