import { useContext, useEffect, useState } from 'react';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import Box from '@mui/material/Box';
// Components
import { Body, Header, Footer } from './components';
// Types
import { IConversation } from '@trii/types/dist/Conversations';

type ConversationProps = {
  conversationSelected: IConversation;
};

const Conversation = ({ conversationSelected }: ConversationProps) => {
  const { fileSelectorMode } = useContext(messagesContext);
  const [closeAction, setCloseAction] = useState<boolean>(false);

  const handleClose = (code: string) => {
    if (code === 'Escape') {
      setCloseAction(true);
    }
  };

  return (
    <Box
      display={fileSelectorMode ? 'none' : 'flex'}
      flexGrow={1}
      flexDirection="column"
      height="100%"
      minWidth="50%"
      maxWidth="50%"
      onKeyDown={(e) => handleClose(e.code)}
    >
      <Header />
      <Body conversationSelected={conversationSelected} />
      <Footer closeAction={closeAction} setCloseAction={setCloseAction} />
    </Box>
  );
};

export default Conversation;
