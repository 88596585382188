import React from 'react';
// Components/ui
import { IconButton, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddButton = ({ handleFileUpload }: Props) => {
  return (
    <Box display={'flex'} height={'100%'} pt={0.7}>
      <input
        type="file"
        accept="image/*, video/*"
        multiple
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        id="file-upload"
      />
      <label htmlFor="file-upload">
        <IconButton
          disableRipple
          sx={{
            border: '1px solid #d1d5db',
            width: 60,
            height: 55,
            borderRadius: '4px',
          }}
          component="span"
        >
          <AddIcon />
        </IconButton>
      </label>
    </Box>
  );
};

export default AddButton;
