import { useContext, useEffect } from 'react';
// Router
import { useParams } from 'react-router-dom';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import { Box, useTheme } from '@mui/material';
// Components
import { ChatMode, EmailMode, SelectMode } from './components';
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

type Props = {
  closeAction: boolean;
  setCloseAction: (value: boolean) => void;
};

const Footer = ({ closeAction, setCloseAction }: Props) => {
  const { contactId } = useParams<{ contactId: string }>();
  const theme = useTheme();
  const conversationSelected = useSelector(selectConversationSelected);
  const { selectMessagesMode, isEmailMode } = useContext(conversationsContext);
  const { footerSize, setFooterSize } = useContext(messagesContext);

  useEffect(() => {
    if (isEmailMode) {
      setFooterSize('3rem');
    } else {
      setFooterSize('auto');
    }
  }, [conversationSelected]);

  return (
    <Box width="100%" maxWidth="100%" position="relative">
      {(contactId || conversationSelected.finalizedAt) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 'var(--toastify-z-index)',
            backgroundColor: `${theme.palette.background.default}90`,
            '&:hover': {
              cursor: 'not-allowed',
            },
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: theme.palette.background.default,
          color: theme.palette.text.primary,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          padding: '.21rem .5rem',
          borderTop: `1px solid ${theme.palette.divider}`,
          height: footerSize,
        }}
      >
        {!selectMessagesMode ? (
          isEmailMode ? (
            <EmailMode />
          ) : (
            <ChatMode closeAction={closeAction} setCloseAction={setCloseAction} />
          )
        ) : (
          <SelectMode />
        )}
      </Box>
    </Box>
  );
};

export default Footer;
