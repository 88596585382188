import axios from 'axios';
import { NewPbx } from './types/NewPbx';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchPbxs = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);
  const response = await axios.get(`${URL}/channels/pbx`, config);
  return response.data;
};
const createPbx = async (jwt: string, URL: string, pbx: NewPbx) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const pbxJson = JSON.stringify(pbx);
  const response = await axios.post(`${URL}/channels/pbx`, pbxJson, config);
  return response.data;
};
const deletePbx = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/pbx/${id}`, config);

  return id;
};
const updatePbx = async (jwt: string, URL: string, pbx: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const pbxJson = JSON.stringify(pbx);

  const response = await axios.put(`${URL}/channels/pbx/${pbx.id}`, pbxJson, config);
  return response.data;
};

const pbxService = {
  fetchPbxs,
  createPbx,
  deletePbx,
  updatePbx,
};

export default pbxService;
