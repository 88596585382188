import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
//Types
import { IChannel } from '@trii/types/dist/Common/Channels';

const fetchFacebook = async (
  jwt: string,
  URL: string,
  id: string
): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/facebook/${id}`, config);
  return response.data;
};
const updateFacebook = async (jwt: string, URL: string, facebook: IChannel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const facebookJson = JSON.stringify(facebook);

  const response = await axios.put(
    `${URL}/channels/facebook/${facebook.id}`,
    facebookJson,
    config
  );
  return response.data;
};

const facebookEditService = {
  fetchFacebook,
  updateFacebook,
};

export default facebookEditService;
