import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { FacebookEditState } from './types/FacebookEditState';
import { PermissionReadMessagesPayload } from 'redux/types/PermissionReadMessagesPayload';
import { PermissionStartConversationsPayload } from 'redux/types/PermissionStartConversationsPayload';
import { Permission } from '@trii/types/dist/Common/Channels';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ISchedule } from '@trii/types/dist/Common/Schedules';
import { IChannel } from '@trii/types/dist/Common/Channels';
// Service
import facebookEditService from './facebookEditService';

const initialState: FacebookEditState = {
  channel: null,
  facebookName: null,
  status: {
    fetch: 'idle',
    update: 'idle',
  },
};

export const fetchFacebook = createAsyncThunk(
  'facebookEdit/fetchFacebook',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await facebookEditService.fetchFacebook(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const updateFacebook = createAsyncThunk<IChannel, void, { state: RootState }>(
  'facebookEdit/updateFacebook',
  async (_, { getState, dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const facebook = getState().FacebookEdit.channel;
    console.log(facebook);
    await facebookEditService.updateFacebook(jwtToken, URL_CONVERSATIONS, facebook);
    return facebook;
  }
);

const facebookEditSlice = createSlice({
  name: 'facebookEdit',
  initialState,
  reducers: {
    changeName(state, action: PayloadAction<string>) {
      state.channel.name = action.payload;
    },
    changeToken(state, action: PayloadAction<string>) {
      state.channel.facebook.token = action.payload;
    },
    changeReassign(state, action: PayloadAction<boolean>) {
      state.channel.facebook.reassign = action.payload;
    },
    changeReassignGroup(state, action: PayloadAction<string>) {
      state.channel.facebook.reassignGroupId = action.payload;
    },
    changeAutoResponseBusinessHours(state, action: PayloadAction<boolean>) {
      state.channel.facebook.autoResponseBusinessHours = action.payload;
    },
    changeAutoResponseOutOfBusinessHours(state, action: PayloadAction<boolean>) {
      state.channel.facebook.autoResponseOutOfBusinessHours = action.payload;
    },
    changeAutoResponseHoliday(state, action: PayloadAction<boolean>) {
      state.channel.facebook.autoResponseHoliday = action.payload;
    },
    changeSchedule(state, action: PayloadAction<ISchedule>) {
      const schedule = action.payload;

      state.channel.facebook.schedule = schedule;
    },
    changeSubscribedEvents(state, action: PayloadAction<string[]>) {
      const subscribedEvents = action.payload;

      state.channel.facebook.fbSubscribedEvents = subscribedEvents;
    },
    addPermission: {
      prepare: (groupId: string): { payload: Permission } => ({
        payload: {
          groupId,
          readMessages: false,
          startConversations: false,
        },
      }),
      reducer: (state, action: PayloadAction<Permission>) => {
        state.channel.permissions.push(action.payload);
      },
    },
    removePermission(state, action: PayloadAction<string>) {
      const groupId = action.payload;
      const index = state.channel.permissions.findIndex(
        (permission) => permission.groupId === groupId
      );

      state.channel.permissions.splice(index, 1);
    },
    changePermissionReadMessages(
      state,
      action: PayloadAction<PermissionReadMessagesPayload>
    ) {
      const { permissionGroupId, readMessages } = action.payload;
      const permission = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      ) as Permission;

      permission.readMessages = readMessages;
    },
    changePermissionStartConversation(
      state,
      action: PayloadAction<PermissionStartConversationsPayload>
    ) {
      const { permissionGroupId, startConversations } = action.payload;
      const permission = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );

      permission.startConversations = startConversations;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFacebook.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchFacebook.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('Fetched Facebook: ', action.payload);
        state.channel = action.payload;
        state.facebookName = action.payload.name;
      })
      .addCase(updateFacebook.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateFacebook.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        state.facebookName = action.payload.name;
      })
      .addCase(updateFacebook.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      });
  },
});

const selectFacebookEditState = (state: RootState) => state.FacebookEdit;
export const selectFacebook = createSelector(
  selectFacebookEditState,
  (state) => state.channel
);
export const selectFacebookName = createSelector(
  selectFacebookEditState,
  (state) => state.facebookName
);
export const selectFacebookFetchStatus = createSelector(
  selectFacebookEditState,
  (state) => state.status.fetch
);
export const selectFacebookUpdateStatus = createSelector(
  selectFacebookEditState,
  (state) => state.status.update
);

export const {
  changeName,
  changeToken,
  changeReassign,
  changeReassignGroup,
  changeAutoResponseBusinessHours,
  changeAutoResponseOutOfBusinessHours,
  changeAutoResponseHoliday,
  changeSchedule,
  changeSubscribedEvents,
  addPermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
} = facebookEditSlice.actions;

export default facebookEditSlice.reducer;
