import { useContext } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  selectAllEndings,
  selectEndingsFetchStatus,
} from 'redux/features/endingSlice/endingSlice';
import {
  finalizeConversation,
  setConversationSelected,
} from 'redux/features/conversationsSlice/conversationsSlice';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Components/ui
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// Icons
import { Bookmark } from '@mui/icons-material';
import { style } from '../../style';

const EndConversations = () => {
  const {
    conversationId,
    conversations,
    conversationsFinalizedOfContact,
    conversationsActiveOfContact,
    setConversationId,
    setOpenModalList,
    setConversationsFinalizedOfContact,
    setConversationsActiveOfContact,
  } = useContext(conversationsContext);
  const endings = useSelector(selectAllEndings);
  const endingsFetchStatus = useSelector(selectEndingsFetchStatus);
  const isLoading = endingsFetchStatus === 'loading';
  const dispatch = useAppDispatch();

  const handleSelectLabel = (endId: string) => {
    dispatch(
      finalizeConversation({
        conversationId,
        endId,
      })
    );
    const getConversation = conversations.find(
      (conversation) => conversation.id === conversationId
    );
    if (getConversation) {
      const newConversation = [
        ...conversationsFinalizedOfContact,
        {
          ...getConversation,
          finalizedAt: new Date(),
        },
      ];
      setConversationsFinalizedOfContact(newConversation);
    }
    const filterConversations = conversationsActiveOfContact.filter(
      (conversation) => conversation.id !== conversationId
    );
    setConversationsActiveOfContact(filterConversations);
    setOpenModalList(false);
    dispatch(setConversationSelected(null));
    setConversationId('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        overflow: 'auto',
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        endings &&
        endings.length > 0 &&
        endings.map((ending, i) => (
          <ListItem
            key={ending.id}
            onClick={() => handleSelectLabel(ending.id)}
            sx={{
              padding: 0,
            }}
          >
            <ListItemButton
              sx={{
                borderBottom: (theme) =>
                  i === endings.length - 1
                    ? 'none'
                    : `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <ListItemIcon>
                <Bookmark />
              </ListItemIcon>
              <ListItemText primary={ending.name} sx={style.nameFinal} />
            </ListItemButton>
          </ListItem>
        ))
      )}
    </Box>
  );
};

export default EndConversations;
