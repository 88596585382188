import { useContext, useEffect } from "react"
// Redux
import { useSelector } from "react-redux"
// Context
import { messagesContext } from "features/Views/Conversations/context/MessagesProvider/MessagesProvider"
// Slice
import {
  selectUploadMedia,
  selectUploadMediaStatus,
} from "redux/features/messagesSlice/messagesSlice"
// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, Chip, CircularProgress, Typography } from "@mui/material"
// Icons
import { Close } from "@mui/icons-material"
import { EmailDocument } from "@trii/types/dist/Common/Messages"

const Attachments = () => {
  const {
    filesToUpload,
    setFilesToUpload,
    messageLoading,
    setMessageLoading,
    attachments,
    setAttachments,
  } = useContext(messagesContext)
  const { t } = useTranslation()
  const newFile = useSelector(selectUploadMedia)
  const filesFetchStatus = useSelector(selectUploadMediaStatus)
  const isLoading = filesFetchStatus === 'loading'

  const handleDelete = (id: string) => {
    const filterFiles = attachments.filter((file) => file.id !== id)
    setAttachments(filterFiles)
  }

  useEffect(() => {
    const filesIds = attachments.map((file) => file.id)
    if (newFile && filesIds.includes(newFile.id)) {
      const newFiles = attachments.map((file) => {
        if (file.id === newFile.id) {
          return {
            ...file,
            url: newFile.url,
          }
        }
        return file
      })
      setAttachments(newFiles)
      //@ts-ignore
      const filterFiles = filesToUpload.filter((file) => file.id !== newFile.id)
      setFilesToUpload(filterFiles)
      const filterMessagesLoading = messageLoading.filter((id) => id !== newFile.id)
      setMessageLoading(filterMessagesLoading)
    }
  }, [newFile])

  useEffect(() => {
    if (filesToUpload.length > 0) {
      //@ts-ignore
      const filterFiles = filesToUpload.filter(
        (file) => !attachments.map((f) => f.id).includes(file.id)
      )
      const newFiles = filterFiles.map((file) => ({
        ...file,
        isInline: false
      })) as EmailDocument[]

      setAttachments([...attachments, ...newFiles])
    }
  }, [filesToUpload])

  return (
    <Box
      height='2rem'
      width='100%'
      display='flex'
      alignItems='center'
      gap={1}
    >
      <Typography
        variant='body2'
        fontSize='.8rem'
        width='4rem'
        color={(theme) => theme.palette.text.primary}
      >
        {t('conversations.message.email.attachments')}
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '2rem',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          backgroundColor: (theme) => (
            //@ts-ignore
            theme.palette.background.dropdownMenu
          ),
          borderRadius: '5px',
          paddingLeft: '.5rem',
          paddingRight: '.5rem',
          overflowX: 'auto',
        }}
      >
        {
          attachments.map((file) => (
            <Chip
              key={file.id}
              label={file.filename}
              onDelete={() => handleDelete(file.id)}
              sx={{
                backgroundColor: (theme) => theme.palette.grey[
                  theme.palette.mode === 'light' ? 300 : 900
                ],
                color: (theme) => theme.palette.text.disabled,
              }}
              size='small'
              deleteIcon={
                (isLoading && messageLoading.includes(file.id)) ? (
                  <CircularProgress size={20} />
                ) : (
                  <Close />
                )
              }
            />
          ))
        }
      </Box>
    </Box>
  )
}

export default Attachments