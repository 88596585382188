import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { EmailEditState } from './types/EmailEditState.js';
import { PermissionReadMessagesPayload } from 'redux/types/PermissionReadMessagesPayload';
import { PermissionStartConversationsPayload } from 'redux/types/PermissionStartConversationsPayload';
import {
  EmailConfigOutboundConnectionType,
  EmailConfigOutboundSecurityType,
  Permission,
  EmailConfigFromNameType,
  IChannel
} from '@trii/types/dist/Common/Channels';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
// Service
import emailEditService from './emailEditService';

const initialState: EmailEditState = {
  channel: null,
  emailName: null,
  status: {
    fetch: 'idle',
    update: 'idle',
  },
};

export const fetchEmail = createAsyncThunk(
  'emailEdit/fetchEmail',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await emailEditService.fetchEmail(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const updateEmail = createAsyncThunk<IChannel, void, { state: RootState }>(
  'emailEdit/updateEmail',
  async (_, { getState, dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const email = getState().EmailEdit.channel;

    await emailEditService.updateEmail(jwtToken, URL_CONVERSATIONS, email);
    return email;
  }
);

const emailEditSlice = createSlice({
  name: 'emailEdit',
  initialState,
  reducers: {
    changeName(state, action: PayloadAction<string>) {
      state.channel.name = action.payload;
    },
    changeToken(state, action: PayloadAction<string>) {
      state.channel.email.token = action.payload;
    },
    changeReassign(state, action: PayloadAction<boolean>) {
      state.channel.email.reassign = action.payload;
    },
    changeReassignGroup(state, action: PayloadAction<string>) {
      state.channel.email.reassignGroupId = action.payload;
    },
    addPermission: {
      prepare: (groupId: string): { payload: Permission } => ({
        payload: {
          groupId,
          readMessages: false,
          startConversations: false,
        },
      }),
      reducer: (state, action: PayloadAction<Permission>) => {
        state.channel.permissions.push(action.payload);
      },
    },
    removePermission(state, action: PayloadAction<string>) {
      const groupId = action.payload;
      const index = state.channel.permissions.findIndex(
        (permission) => permission.groupId === groupId
      );

      state.channel.permissions.splice(index, 1);
    },
    changePermissionReadMessages(
      state,
      action: PayloadAction<PermissionReadMessagesPayload>
    ) {
      const { permissionGroupId, readMessages } = action.payload;
      const permission = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      ) as Permission;

      permission.readMessages = readMessages;
    },
    changePermissionStartConversation(
      state,
      action: PayloadAction<PermissionStartConversationsPayload>
    ) {
      const { permissionGroupId, startConversations } = action.payload;
      const permission = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );

      permission.startConversations = startConversations;
    },
    changeConnectedEmail(state, action: PayloadAction<string>) {
      state.channel.email.inboundConnectedEmail = action.payload;
    },
    changeConnectionType(
      state,
      action: PayloadAction<EmailConfigOutboundConnectionType>
    ) {
      state.channel.email.outboundConnectionType = action.payload;
    },
    changeOutboundSmtpServer(state, action: PayloadAction<string>) {
      state.channel.email.outboundSmtpServer = action.payload;
    },
    changeOutboundSmtpUser(state, action: PayloadAction<string>) {
      state.channel.email.outboundSmtpUser = action.payload;
    },
    changeOutboundSmtpPassword(state, action: PayloadAction<string>) {
      state.channel.email.outboundSmtPasword = action.payload;
    },
    changeOutboundSmtpPort(state, action: PayloadAction<number>) {
      state.channel.email.outboundSmtpPort = action.payload;
    },
    changeOutboundSmtpSecurityType(
      state,
      action: PayloadAction<EmailConfigOutboundSecurityType>
    ) {
      state.channel.email.outboundSmtSecurityType = action.payload;
    },
    changeEmailAdress(state, action: PayloadAction<string>) {
      state.channel.email.emailAddress = action.payload;
    },
    changeFromNameType(state, action: PayloadAction<EmailConfigFromNameType>) {
      state.channel.email.emailFromNameType = action.payload;
    },
    changeFromNameCustom(state, action: PayloadAction<string>) {
      state.channel.email.emailFromNameCustom = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEmail.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchEmail.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('Fetched Email: ', action.payload);
        state.channel = action.payload;
        state.emailName = action.payload.name;
      })
      .addCase(updateEmail.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        state.emailName = action.payload.name;
      })
      .addCase(updateEmail.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      });
  },
});

const selectEmailEditState = (state: RootState) => state.EmailEdit;
export const selectEmail = createSelector(
  selectEmailEditState,
  (state) => state.channel
);
export const selectEmailName = createSelector(
  selectEmailEditState,
  (state) => state.emailName
);
export const selectEmailFetchStatus = createSelector(
  selectEmailEditState,
  (state) => state.status.fetch
);
export const selectEmailUpdateStatus = createSelector(
  selectEmailEditState,
  (state) => state.status.update
);

export const {
  changeName,
  changeToken,
  changeReassign,
  changeReassignGroup,
  addPermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
  changeConnectedEmail,
  changeConnectionType,
  changeOutboundSmtpServer,
  changeOutboundSmtpUser,
  changeOutboundSmtpPassword,
  changeOutboundSmtpPort,
  changeOutboundSmtpSecurityType,
  changeEmailAdress,
  changeFromNameType,
  changeFromNameCustom,
} = emailEditSlice.actions;

export default emailEditSlice.reducer;
