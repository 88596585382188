import { useState, useContext, useEffect } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Redux
import { useSelector } from 'react-redux';
import {
  fetchUpdateChat,
  selectConversationSelected,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectUser,
  selectUsers,
  selectUsersStatus,
} from 'redux/features/userSlice/userSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, CircularProgress, List } from '@mui/material';
// Types
import { UserInfo } from '@trii/types/dist/Users';
import { UpdateChat } from 'redux/features/conversationsSlice/types/UpdateChat';
// Components
import { ChatItem } from './components';

const AddParticipant = () => {
  const dispatch = useAppDispatch();
  const {
    internalGroupInfo,
    setOpenModalList,
    setModalTitle,
    setInternalGroupInfo,
  } = useContext(conversationsContext);
  const conversationSelected = useSelector(selectConversationSelected);
  const users = useSelector(selectUsers);
  const currentUser = useSelector(selectUser);
  const usersStatus = useSelector(selectUsersStatus);
  const isLoadingUsers = usersStatus === 'loading';
  const { t } = useTranslation();
  const [groupSelected, setGroupSelected] = useState<UserInfo[]>([]);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [userList, setUserList] = useState<UserInfo[]>([]);
  const [userAlreadyInGroup, setUserAlreadyInGroup] = useState<string[]>([]);

  const handleClose = () => {
    setOpenModalList(false);
    setModalTitle('');
  };

  const handleSelect = (newUser: UserInfo) => {
    if (groupSelected && groupSelected.length > 0) {
      const getIds = groupSelected.map((user) => user.id);
      if (getIds.includes(newUser.id)) {
        const filtered = groupSelected.filter((user) => newUser.id !== user.id);
        setGroupSelected(filtered);
      } else {
        setGroupSelected([...groupSelected, newUser]);
      }
    } else {
      setGroupSelected([newUser]);
    }
  };

  const getIsChecked = (id: string) => {
    if (groupSelected && groupSelected.length > 0) {
      const getIds = groupSelected.map((user: UserInfo) => user.id);
      if (getIds.includes(id)) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleAdd = () => {
    const usersId = groupSelected.map((user) => user.id);
    const data = {
      chatId: conversationSelected?.id,
      action: 'addMember',
      value: usersId,
    };

    dispatch(fetchUpdateChat(data as UpdateChat));

    const group = groupSelected.map((user) => {
      const data = {
        joinTimeStamp: new Date(),
        isAdmin: false,
        userId: user.id,
        user: user,
      };
      return data;
    });
    setInternalGroupInfo(group);
    setOpenModalList(false);
    setModalTitle('');
  };

  useEffect(() => {
    if (users && users.length > 0) {
      const filterUsers = users.filter((user) => user.id !== currentUser.uid);
      setUserList(filterUsers);
    }
  }, [users]);

  useEffect(() => {
    if (groupSelected && groupSelected.length > 0) {
      setCanCreate(true);
    } else {
      setCanCreate(false);
    }
  }, [groupSelected]);

  useEffect(() => {
    if (internalGroupInfo && internalGroupInfo.length > 0) {
      const getIds = internalGroupInfo.map((user) => user.userId);
      const getUsers = internalGroupInfo.map((user) => user.user);
      setGroupSelected(getUsers);
      setUserAlreadyInGroup(getIds);
    }
  }, [internalGroupInfo]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '1em',
        height: 'calc(100% - 3rem)',
        overflow: 'auto',
      }}
    >
      {isLoadingUsers ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: '1em',
          }}
        >
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 6rem)',
            overflow: 'auto',
          }}
        >
          {userList &&
            userList.length > 0 &&
            userList.map((data: UserInfo, i: number) => (
              <ChatItem
                key={i}
                data={data}
                getIsChecked={getIsChecked}
                handleSelect={handleSelect}
                disabled={userAlreadyInGroup.includes(data.id)}
              />
            ))}
        </List>
      )}
      <Box
        sx={{
          width: '100%',
          height: '3rem',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '.5rem',
          position: 'sticky',
          bottom: 0,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          background: (theme) => theme.palette.background.default,
        }}
      >
        <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          onClick={handleClose}
        >
          {t('global.close')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          disabled={!canCreate}
          onClick={handleAdd}
        >
          {t('global.add')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddParticipant;
