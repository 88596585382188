// Components/ui
import { Box, Chip, Typography } from '@mui/material';
import { WarningAmber, Check } from '@mui/icons-material';
// Types
import { MessageFormStatus } from '@trii/types/dist/Common/Messages';
import { style } from './style';

type FormFooterProps = {
  status: MessageFormStatus;
  timestamp: string;
};
const FormFooter = ({ status, timestamp }: FormFooterProps) => {
  return (
    <Box sx={style.container}>
      {status === MessageFormStatus.pending && (
        <Chip
          key={1}
          size="small"
          label="Pendiente"
          icon={<WarningAmber sx={style.statusIcon} />}
          color="warning"
        />
      )}
      {status === MessageFormStatus.completed && (
        <Chip
          key={2}
          size="small"
          label="Completado"
          icon={<Check sx={style.statusIcon} />}
          color="success"
        />
      )}
      {status === MessageFormStatus.expired && (
        <Chip
          size="small"
          key={3}
          label="Vencido"
          icon={<WarningAmber sx={style.statusIcon} />}
          color="warning"
        />
      )}
      <Typography
        variant="caption"
        sx={{
          color: '#9a9a9a',
        }}
      >
        {timestamp}
      </Typography>
    </Box>
  );
};

export default FormFooter;
