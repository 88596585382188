import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
//Types
import { IChannel } from '@trii/types/dist/Common/Channels';

const fetchMercadoLibre = async (
  jwt: string,
  URL: string,
  id: string
): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/mercadoLibre/${id}`, config);
  return response.data;
};
const updateMercadoLibre = async (
  jwt: string,
  URL: string,
  mercadoLibre: IChannel
) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const mercadoLibreJson = JSON.stringify(mercadoLibre);

  const response = await axios.put(
    `${URL}/channels/mercadoLibre/${mercadoLibre.id}`,
    mercadoLibreJson,
    config
  );
  return response.data;
};

const mercadoLibreEditService = {
  fetchMercadoLibre,
  updateMercadoLibre,
};

export default mercadoLibreEditService;
