import React from 'react';
// Components/ui
import { Box } from '@mui/material';
import { style } from './style';

type ResultStateContainerProps = {
  children: React.ReactNode;
};
const ResultStateContainer = ({ children }: ResultStateContainerProps) => {
  return <Box sx={style.container}>{children}</Box>;
};

export default ResultStateContainer;
