import React from 'react';
// Components/ui
import IconButton from '@mui/material/IconButton';
import { Pause } from '@mui/icons-material';

interface Props {
  handlePauseRecording: () => void;
}

const PauseButton = ({ handlePauseRecording }: Props) => {
  return (
    <IconButton
      disableRipple
      sx={{
        padding: 0,
        color: "text.disabled"
      }}
      onClick={handlePauseRecording}
    >
      <Pause />
    </IconButton>
  );
};

export default PauseButton;
