import { Box, Popover } from "@mui/material"
import { Emojis } from "components";

interface Props {
  open: boolean;
  anchorEl: HTMLElement;
  onClose: () => void;
  onSelect: (emoji) => void;
}

const EmojiPopup = ({
  open,
  anchorEl,
  onClose,
  onSelect,
}: Props) => (
  <Popover
    open={open}
    onClose={onClose}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    slotProps={{
      paper: {
        sx: {
          width: "20rem",
          height: "20rem",
          backgroundColor: "background.default",
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
        }
      }
    }}
  >
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        backgroundColor: "background.default",
        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
      }}
    >

      <Emojis handleEmojiSelect={onSelect} />
    </Box>
  </Popover>
)


export default EmojiPopup