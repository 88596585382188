import axios from 'axios';
import { NewFacebook } from './types/NewFacebook';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchFacebooks = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/facebook`, config);
  return response.data;
};
const createFacebook = async (jwt: string, URL: string, facebook: NewFacebook) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const facebookJson = JSON.stringify(facebook);
  const response = await axios.post(
    `${URL}/channels/facebook`,
    facebookJson,
    config
  );
  return response.data;
};
const deleteFacebook = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/facebook/${id}`, config);

  return id;
};
const updateFacebook = async (jwt: string, URL: string, facebook: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const facebookJson = JSON.stringify(facebook);

  const response = await axios.put(
    `${URL}/channels/facebook/${facebook.id}`,
    facebookJson,
    config
  );
  return response.data;
};

const facebookService = {
  fetchFacebooks,
  createFacebook,
  deleteFacebook,
  updateFacebook,
};

export default facebookService;
