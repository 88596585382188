import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
//Types
import { IChannel } from '@trii/types/dist/Common/Channels';

const fetchEmail = async (
  jwt: string,
  URL: string,
  id: string
): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/email/${id}`, config);
  return response.data;
};
const updateEmail = async (jwt: string, URL: string, email: IChannel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const emailJson = JSON.stringify(email);

  const response = await axios.put(
    `${URL}/channels/email/${email.id}`,
    emailJson,
    config
  );
  return response.data;
};

const emailEditService = {
  fetchEmail,
  updateEmail,
};

export default emailEditService;
