import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
  Dispatch,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { WhatsAppEditState } from './types/WhatsAppEditState';
import { PermissionReadMessagesPayload } from 'redux/types/PermissionReadMessagesPayload';
import { PermissionStartConversationsPayload } from 'redux/types/PermissionStartConversationsPayload';
import {
  WhatsAppConectionType,
  Permission,
  IChannel,
} from '@trii/types/dist/Common/Channels';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ISchedule } from '@trii/types/dist/Common/Schedules';
// Service
import whatsAppEditService from './whatsAppEditService';

const initialState: WhatsAppEditState = {
  whatsApp: null,
  whatsAppName: null,
  status: {
    fetch: 'idle',
    update: 'idle',
    syncWhatsAppCloudApiTemplates: 'idle',
  },
};

export const fetchWhatsApp = createAsyncThunk(
  'whatsAppEdit/fetchWhatsApp',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await whatsAppEditService.fetchWhatsApp(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const updateWhatsApp = createAsyncThunk<IChannel, void, { state: RootState }>(
  'whatsAppEdit/updateWhatsApp',
  async (_, { getState, dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const whatsApp = getState().WhatsAppEdit.whatsApp;
    await whatsAppEditService.updateWhatsApp(jwtToken, URL_CONVERSATIONS, whatsApp);

    return whatsApp;
  }
);

export const syncWhatsAppCloudApiTemplates = createAsyncThunk(
  'whatsAppEdit/syncWhatsAppCloudApiTemplates',
  async (channelId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await whatsAppEditService.syncTemplates(
      jwtToken,
      URL_CONVERSATIONS,
      channelId
    );

    return response;
  }
);

const whatsAppEditSlice = createSlice({
  name: 'whatsAppEdit',
  initialState,
  reducers: {
    changeName(state, action: PayloadAction<string>) {
      state.whatsApp.name = action.payload;
    },
    changeUseInCampaigns(state, action: PayloadAction<boolean>) {
      state.whatsApp.whatsApp.useInCampaigns = action.payload;
    },
    changeToken(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.token = action.payload;
    },
    changeReOpenTime(state, action: PayloadAction<number>) {
      state.whatsApp.whatsApp.reOpenTime = action.payload;
    },
    changePauseBetweenMessages(state, action: PayloadAction<number>) {
      state.whatsApp.whatsApp.pauseBetweenMessages = action.payload;
    },
    changeReassign(state, action: PayloadAction<boolean>) {
      state.whatsApp.whatsApp.reassign = action.payload;
    },
    changeReassignGroup(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.reassignGroupId = action.payload;
    },
    changeAutoResponseBusinessHours(state, action: PayloadAction<boolean>) {
      state.whatsApp.whatsApp.autoResponseBusinessHours = action.payload;
    },
    changeAutoResponseOutOfBusinessHours(state, action: PayloadAction<boolean>) {
      state.whatsApp.whatsApp.autoResponseOutOfBusinessHours = action.payload;
    },
    changeAutoResponseHoliday(state, action: PayloadAction<boolean>) {
      state.whatsApp.whatsApp.autoResponseHoliday = action.payload;
    },
    changeSchedule(state, action: PayloadAction<ISchedule>) {
      const schedule = action.payload;

      state.whatsApp.whatsApp.schedule = schedule;
    },
    changeConectionType(state, action: PayloadAction<WhatsAppConectionType>) {
      state.whatsApp.whatsApp.conectionType = action.payload;
    },
    changeTwilioAccountSid(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.twilioAccountSid = action.payload;
    },
    changeTwilioAuthToken(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.twilioAuthToken = action.payload;
    },
    changeTwilioNumber(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.twilioNumber = action.payload;
    },
    changeDialog360AuthToken(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.dialog360AuthToken = action.payload;
    },
    changeDialog360Number(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.dialog360Number = action.payload;
    },
    changeDialog360NameSpace(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.dialog360NameSpace = action.payload;
    },
    changeApiApiqrId(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.apiqrId = action.payload;
    },
    changeApiqrToken(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.apiqrToken = action.payload;
    },
    changeCloudApiNameSpace(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.cloudApiNameSpace = action.payload;
    },
    changeCloudApiSystemUserAccessToken(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.cloudApiSystemUserAccessToken = action.payload;
    },
    changeCloudApiAppToken(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.cloudApiAppToken = action.payload;
    },
    changeCloudApiWhatsAppBusinessAccountId(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.cloudApiWhatsAppBusinessAccountId = action.payload;
    },
    changeCloudApiMetaDeveloperAppId(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.cloudApiMetaDeveloperAppId = action.payload;
    },
    changeCloudApiWhatsAppPhoneNumberId(state, action: PayloadAction<string>) {
      state.whatsApp.whatsApp.cloudApiWhatsAppPhoneNumberId = action.payload;
    },
    addPermission: {
      prepare: (groupId: string): { payload: Permission } => ({
        payload: {
          groupId,
          readMessages: false,
          startConversations: false,
        },
      }),
      reducer: (state, action: PayloadAction<Permission>) => {
        state.whatsApp.permissions.push(action.payload);
      },
    },
    removePermission(state, action: PayloadAction<string>) {
      const groupId = action.payload;
      const index = state.whatsApp.permissions.findIndex(
        (permission) => permission.groupId === groupId
      );

      state.whatsApp.permissions.splice(index, 1);
    },
    changePermissionReadMessages(
      state,
      action: PayloadAction<PermissionReadMessagesPayload>
    ) {
      const { permissionGroupId, readMessages } = action.payload;
      const permission = state.whatsApp.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      ) as Permission;

      permission.readMessages = readMessages;
    },
    changePermissionStartConversation(
      state,
      action: PayloadAction<PermissionStartConversationsPayload>
    ) {
      const { permissionGroupId, startConversations } = action.payload;
      const permission = state.whatsApp.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );

      permission.startConversations = startConversations;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWhatsApp.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchWhatsApp.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('Fetched WhatsApp: ', action.payload);
        state.whatsApp = action.payload;
        state.whatsAppName = action.payload.name;
      })
      .addCase(updateWhatsApp.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateWhatsApp.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        state.whatsAppName = action.payload.name;
      })
      .addCase(syncWhatsAppCloudApiTemplates.pending, (state) => {
        state.status.syncWhatsAppCloudApiTemplates = 'loading';
      })
      .addCase(syncWhatsAppCloudApiTemplates.fulfilled, (state) => {
        state.status.syncWhatsAppCloudApiTemplates = 'succeeded';
        console.log('Synced WhatsApp Cloud API Templates');
      });
  },
});

export const setSchedule =
  (scheduleId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const scheduleSliceState = getState().Schedule;
    const schedule = scheduleSliceState.data.find((s) => s.id === scheduleId);
    console.log('ScheduleID : ', scheduleId);
    console.log('Schedule: ', schedule);

    dispatch(changeSchedule(schedule));
  };

const selectWhatsAppEditState = (state: RootState) => state.WhatsAppEdit;
export const selectWhatsApp = createSelector(
  selectWhatsAppEditState,
  (state) => state.whatsApp
);
export const selectWhatsAppName = createSelector(
  selectWhatsAppEditState,
  (state) => state.whatsAppName
);
export const selectWhatsAppFetchStatus = createSelector(
  selectWhatsAppEditState,
  (state) => state.status.fetch
);
export const selectWhatsAppUpdateStatus = createSelector(
  selectWhatsAppEditState,
  (state) => state.status.update
);
export const selectSyncWhatsAppCloudApiTemplatesStatus = createSelector(
  selectWhatsAppEditState,
  (state) => state.status.syncWhatsAppCloudApiTemplates
);

export const {
  changeName,
  changeUseInCampaigns,
  changeToken,
  changeReOpenTime,
  changePauseBetweenMessages,
  changeReassign,
  changeReassignGroup,
  changeAutoResponseBusinessHours,
  changeAutoResponseOutOfBusinessHours,
  changeAutoResponseHoliday,
  changeSchedule,
  changeConectionType,
  changeTwilioAccountSid,
  changeTwilioAuthToken,
  changeTwilioNumber,
  changeDialog360AuthToken,
  changeDialog360Number,
  changeDialog360NameSpace,
  changeApiApiqrId,
  changeApiqrToken,
  addPermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
  changeCloudApiNameSpace,
  changeCloudApiSystemUserAccessToken,
  changeCloudApiAppToken,
  changeCloudApiWhatsAppBusinessAccountId,
  changeCloudApiMetaDeveloperAppId,
  changeCloudApiWhatsAppPhoneNumberId,
} = whatsAppEditSlice.actions;

export default whatsAppEditSlice.reducer;
