import { useEffect, useState, useContext, useMemo } from 'react';
// Router
import { useParams } from 'react-router-dom';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  selectContactInfo,
  selectConversationSelected,
} from 'redux/features/conversationsSlice/conversationsSlice';
// Components/ui
import { Box, useTheme } from '@mui/material';
import {
  ContactInfoSkeleton,
  InternalUserInfo,
  PanelView,
  TabsMenu,
} from './components';
import { ChatType } from '@trii/types/dist/Conversations';
// Custom hooks
import useSectionRefs from './hooks/useSectionRefs';
// Provider
import ActivitiesCardContextProvider from './context/ActivitiesCardContextProvider';

const ChatDetailsSidebar = () => {
  const theme = useTheme();

  const { contactInfo, setContactInfo, getNotes, getFiles, getActivities } =
    useContext(conversationsContext);

  const conversationSelected = useSelector(selectConversationSelected);
  const newContact = useSelector(selectContactInfo);

  const { contactId } = useParams<{ contactId: string }>();

  useEffect(() => {
    if (
      (conversationSelected?.type === ChatType.EXTERNAL || contactId) &&
      newContact &&
      newContact.id !== contactInfo?.id
    ) {
      setContactInfo(newContact);
    }
  }, [newContact]);

  useEffect(() => {
    if (contactId) {
      getNotes(contactId);
      getFiles(contactId);
      getActivities(contactId);
    }
  }, [contactId]);

  return (
    <ActivitiesCardContextProvider>
      <Box
        height="100%"
        sx={{
          borderLeft: `1px solid ${theme.palette.divider}`,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          bgcolor: theme.palette.background.default,
        }}
        display={'flex'}
      >
        <InternalUserInfo />
      </Box>
    </ActivitiesCardContextProvider>
  );
};

export default ChatDetailsSidebar;
