import { useState, useEffect, useContext } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Selectors
import {
  getFilterTenorGifts,
  getNextTenorGifts,
  getNextFilterTenorGifts,
  getTenorGifts,
  selectNewTenorGifts,
  selectNewTenorGiftsFetchStatus,
  selectTenorGifts,
  selectTenorGiftsFetchStatus,
} from '../../../../../../../../../../../../redux/features/conversationSlice/conversationSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  LinearProgress,
  Stack,
} from '@mui/material';
// Icons
import SearchIcon from '@mui/icons-material/Search';
// Types
import { Result } from '../../../../../../../../../../../../redux/types/Gif';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';

const Gif = () => {
  const { handleUploadGif } = useContext(messagesContext);
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const [value, setValue] = useState<string>('');
  const tenorGifts = useSelector(selectTenorGifts);
  const newTenorGifts = useSelector(selectNewTenorGifts);
  const newTenorGiftsFetchStatus = useSelector(selectNewTenorGiftsFetchStatus);
  const tenorGiftsFetchStatus = useSelector(selectTenorGiftsFetchStatus);
  const loading =
    tenorGiftsFetchStatus === 'loading' || newTenorGiftsFetchStatus === 'loading';
  const [gifList, setGifList] = useState<Result[]>(
    tenorGifts && tenorGifts.results ? tenorGifts.results : []
  );

  const handleChange = (value) => {
    setValue(value);
    if (value) {
      dispatch(getFilterTenorGifts(value));
    } else {
      dispatch(getTenorGifts());
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (value) {
        const data = {
          search: value,
          next: tenorGifts.next,
        }
        dispatch(getNextFilterTenorGifts(data));
      } else {
        dispatch(getNextTenorGifts(tenorGifts.next));
      }
    }
  };

  useEffect(() => {
    if (tenorGifts && tenorGifts.results) {
      setGifList(tenorGifts.results);
    }
  }, [tenorGifts]);

  useEffect(() => {
    if (newTenorGifts && newTenorGifts.results) {
      setGifList([...gifList, ...newTenorGifts.results]);
    }
  }, [newTenorGifts]);

  return (
    <Box
      sx={{
        display: 'flex',
        maxHeight: 'inherit',
        height: '100%',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box m={1}>
        <TextField
          id="input-with-icon-textfield"
          value={value}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ fontSize: '18px' }} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
          fullWidth
          placeholder={t('inputSearch.gifSearch')}
          onChange={(e) => handleChange(e.target.value)}
        />
      </Box>
      {loading && (
        <Stack
          sx={{
            width: '100%',
            marginBottom: '.2rem',
          }}
        >
          <LinearProgress
            color="inherit"
            sx={{
              height: '0.2rem',
              color: (theme) => theme.palette.primary.main,
            }}
          />
        </Stack>
      )}
      <Box
        sx={{
          maxHeight: 'inherit',
          height: '100%',
          width: '100%',
          overflow: 'scroll',
        }}
        onScroll={(e) => handleScroll(e)}
      >
        <Grid
          container
          sx={{
            maxHeight: '100%',
            display: 'flex',
            flexWrap: 'wrap',
          }}
          spacing={1}
        >
          {gifList.map((item: Result, index) => (
            <Grid
              key={index}
              sx={{
                width: 'inherit',
                maxWidth: '20%',
                maxHeight: '8rem',
                display: 'flex',
                cursor: 'pointer',
              }}
              item
              onClick={() => handleUploadGif(item)}
            >
              <img
                src={item.media_formats.nanogif.url}
                alt={item.title}
                loading="lazy"
                style={{
                  width: '100%',
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Gif;
