import axios from 'axios';
import { NewInstagram } from './types/NewInstagram';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchInstagrams = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/instagram`, config);
  return response.data;
};
const createInstagram = async (
  jwt: string,
  URL: string,
  instagram: NewInstagram
) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const instagramJson = JSON.stringify(instagram);
  const response = await axios.post(
    `${URL}/channels/instagram`,
    instagramJson,
    config
  );
  return response.data;
};
const deleteInstagram = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/instagram/${id}`, config);

  return id;
};
const updateInstagram = async (jwt: string, URL: string, instagram: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const instagramJson = JSON.stringify(instagram);

  const response = await axios.put(
    `${URL}/channels/instagram/${instagram.id}`,
    instagramJson,
    config
  );
  return response.data;
};

const instagramService = {
  fetchInstagrams,
  createInstagram,
  deleteInstagram,
  updateInstagram,
};

export default instagramService;
