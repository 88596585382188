import WaveSurfer from 'wavesurfer.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js';

export const getNewWaveSurfer = (container: string, progressColor:string) => {
  return WaveSurfer.create({
    container,
    barWidth: 5,
    barRadius: 3,
    barGap: 2,
    barMinHeight: 1,
    barHeight: 3,
    height: 20,
    progressColor,
    responsive: true,
    waveColor: '#6b7280',
    cursorColor: 'transparent',
    autoCenter: true,
    normalize: true,
    hideScrollbar: true,
    interact: false,
    plugins: [
      CursorPlugin.create({
        showTime: true,
        opacity: 0,
        customShowTimeStyle: {
          'background-color': 'transparent',
          color: '#000',
          padding: '2px',
          'font-size': '10px',
        },
        customStyle: {
          'background-color': 'transparent',
          border: 'none',
          height: '100%',
          width: '2rem',
          'margin-left': '0px',
          'margin-right': '0px',
        },
      }),
    ],
  });
};
