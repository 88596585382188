import { useRef, useContext, useEffect } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Slice
import { fetchUsers } from 'redux/features/userSlice/userSlice';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, Typography } from '@mui/material';
// Icons
import { Email } from '@mui/icons-material';
// Components
import { EmailWriter } from './components';
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

const EmailMode = () => {
  const conversationSelected = useSelector(selectConversationSelected);
  const { openEmailMode, resetEmailFields, handleOpenEmailMode } =
    useContext(messagesContext);
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleCloseEmailMode = () => {
    resetEmailFields();
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    if (openEmailMode) {
      resetEmailFields();
    }
  }, [conversationSelected]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: '100%',
        height: '100%',
      }}
      ref={containerRef}
    >
      <Button
        variant="contained"
        startIcon={<Email />}
        size="small"
        onClick={handleOpenEmailMode}
      >
        <Typography variant="body2">
          {t('conversations.message.email.title')}
        </Typography>
      </Button>
      <Box
        sx={{
          display: openEmailMode ? 'block' : 'none',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: openEmailMode ? '30rem' : '0',
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          backgroundColor: (theme) => theme.palette.background.default,
          '@-webkit-animation': 'slide 0.3s forwards',
          animation: 'slide 0.3s forwards',
          '@-webkit-keyframes slide': {
            '0%': {
              height: '0',
            },
            '100%': {
              height: '30rem',
            },
          },
          '@keyframes slide': {
            '0%': {
              height: '0',
            },
            '100%': {
              height: '30rem',
            },
          },
        }}
      >
        <EmailWriter handleClose={handleCloseEmailMode} />
      </Box>
    </Box>
  );
};

export default EmailMode;
