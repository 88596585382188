import axios from 'axios';
import { NewWebHook } from './types/NewWebHook';
import { IWebhook } from '@trii/types/dist/Conversations';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchWebHooks = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/webhooks`, config);
  return response.data;
};
const createWebHook = async (jwt: string, URL: string, webHook: NewWebHook) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const webHookJson = JSON.stringify(webHook);
  const response = await axios.post(`${URL}/webhooks`, webHookJson, config);
  return response.data;
};
const deleteWebHook = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/webhooks/${id}`, config);

  return id;
};
const updateWebHook = async (jwt: string, URL: string, webHook: IWebhook) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const webHookJson = JSON.stringify(webHook);

  const response = await axios.put(
    `${URL}/webhooks/${webHook.id}`,
    webHookJson,
    config
  );
  return response.data;
};

const webHooksService = {
  fetchWebHooks,
  createWebHook,
  deleteWebHook,
  updateWebHook,
};

export default webHooksService;
