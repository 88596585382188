import { useState } from "react"
// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
// Utils
import { channelList } from "./utils/channelList"

const FilterChannels = () => {
  const [channel, setChannel] = useState<number>()
  const { t } = useTranslation()

  const handleSelect = (channelId: number) => {
    setChannel(channelId)
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <Typography
        sx={{
          color: (theme) => theme.palette.text.disabled,
          fontWeight: 'bold',
          fontSize: "1rem",
        }}
      >
        {t("conversations.message.search.filterChannels")}
      </Typography>
      <FormControl
        fullWidth
        size="small"
      >
        <InputLabel
          id="search-message-filter-channels"
        >
          {t("conversations.message.search.selectChannel")}
        </InputLabel>
        <Select
          labelId="search-message-filter-channels"
          value={channel}
          label={t("conversations.message.search.selectChannel")}
          onChange={(e) => handleSelect(e.target.value as number)}
        >
          {channelList.map((channel) => (
            <MenuItem
              key={channel?.id}
              value={channel?.id}
            >
              {t(channel?.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default FilterChannels