import { useContext, useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, IconButton } from '@mui/material';
// Icons
import { GroupAdd } from '@mui/icons-material';
import { Chats, Filter, OrderBy } from './components';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Worker
import { dbWorker } from 'db/db';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
import { ChatFilter } from './types/ChatFilter';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  setChat,
  setChats,
} from 'redux/features/conversationsSlice/conversationsSlice';
// Utils
import chatFilters from './utils/chatFilters';

const InternalChat = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { subscribeEvent, unsubscribeEvent } = useContext(socketContext);
  const { setExpandedInternal, setModalTitle, setOpenModalList } =
    useContext(conversationsContext);

  const [filterCondition, setFilterCondition] = useState<ChatFilter>(chatFilters[0]);
  // List order state
  const [nameAscending, setNameAscending] = useState<boolean>(false);

  const handleNewInternalChat = () => {
    setModalTitle(t('conversations.internalChat.newInternalChat'));
    setOpenModalList(true);
  };

  // List order functions
  const handleOrderByName = () => {
    setNameAscending(!nameAscending);

    localStorage.setItem('chat_nameAscending', JSON.stringify(!nameAscending));
  };

  useEffect(() => {
    dbWorker.postMessage({
      action: 'getAllChats',
    });
  }, []);

  useEffect(() => {
    const isNameAscending = localStorage.getItem('chat_nameAscending') === 'true';

    setNameAscending(isNameAscending);
  }, []);

  useEffect(() => {
    dbWorker.onmessage = (event) => {
      const { data } = event;
      if (data.action === 'getAllChats') {
        if (data.data && data.data.length > 0) {
          dispatch(setChats(data.data));
        }
      }
    };
  }, []);

  useEffect(() => {
    subscribeEvent('chat_update', async (data: IConversation) => {
      console.log('chat_update', data);
      dispatch(setChat(data));

      dbWorker.postMessage({
        action: 'setChat',
        data: data,
      });
    });

    return () => {
      unsubscribeEvent('chat_update');
    };
  }, []);

  // Set the initial state of the accordion according to the local storage
  useEffect(() => {
    const expanded = localStorage.getItem('expandedInternal');
    if (expanded) {
      setExpandedInternal(expanded === 'true');
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        height: 'calc(100% - 3rem)',
        '&:before': {
          display: 'none',
        },
        transition: 'max-height 0.5s ease-in-out',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p={1}
        sx={{
          maxHeight: '3rem',
          height: '100%',
        }}
        alignItems="center"
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Box
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Filter condition={filterCondition} setCondition={setFilterCondition} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem',
            }}
          >
            <IconButton onClick={handleNewInternalChat}>
              <GroupAdd sx={{ color: 'text.disabled' }} />
            </IconButton>
            <OrderBy
              handleOrderByName={handleOrderByName}
              nameAscending={nameAscending}
            />
          </Box>
        </Box>
      </Box>
      <Chats nameAscending={nameAscending} />
    </Box>
  );
};

export default InternalChat;
