import { useContext } from 'react';
import { FilePreviewerContext } from 'features/Views/Conversations/context/FilePreviewerProvider/FilePreviewerContext';
// Components/ui
import { Box } from '@mui/material';
import DocumentIcon from '../../../DocumentIcon/index.js';
import TextDocumentExtension from '../../../DocumentIcon/types/DocumentExtension';

const DocumentPreview = () => {
  // const { selectedDocument, getFileType } = useContext(messagesContext);
  const { selectedDocument, getFileType } = useContext(FilePreviewerContext);
  const fileType = getFileType(selectedDocument?.mimeType);

  return (
    <Box
      sx={{
        width: '100%',
        height: '70%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: 400,
          borderRadius: '1px',
          boxShadow: 1,
        }}
      >
        <DocumentIcon
          documentType={
            fileType === 'image' || fileType === 'video'
              ? fileType
              : (selectedDocument?.filename?.split('.')[1] as TextDocumentExtension)
          }
        />
      </Box>
    </Box>
  );
};

export default DocumentPreview;
