// Components/ui
import { Box, Typography, styled } from '@mui/material';

export const StyledDatePickerContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 0.5,
}));
export const StyledPickersContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 1,
}));
export const StyledPickersDivider = styled(Typography)(() => ({
  marginX: '8px',
  marginTop: '-15px',
  color: 'text.primary',
}));
