import WaveSurfer from 'wavesurfer.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js';

export const getNewWaveSurfer = () => {
  return WaveSurfer.create({
    container: '#waveFormId',
    barWidth: 3,
    barRadius: 3,
    barGap: 2,
    barMinHeight: 1,
    cursorWidth: 1,
    height: 35,
    progressColor: '#6b7280',
    responsive: true,
    waveColor: '#e5e7eb',
    cursorColor: 'transparent',
    plugins: [
      CursorPlugin.create({
        showTime: true,
        opacity: 0,
        customShowTimeStyle: {
          'background-color': 'transparent',
          color: '#000',
          padding: '2px',
          'font-size': '10px',
        },
        customStyle: {
          'background-color': 'transparent',
          border: 'none',
          height: '100%',
          width: '1px',
          'margin-left': '0px',
          'margin-right': '0px',
        },
      }),
    ],
  });
};
