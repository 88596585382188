import { useState } from "react";
// Components/ui
import { Box, CircularProgress, Fab, IconButton } from "@mui/material";
// Icons
import { Clear, FileUpload } from "@mui/icons-material";

interface LoaderProps {
  handleTryAgain: () => void;
  isLoading: boolean;
}

const Loader = ({
  handleTryAgain,
  isLoading,
}: LoaderProps) => {
  const [cancel, setCancel] = useState<boolean>(false);

  const handleMouseOver = () => {
    setCancel(true);
  };

  const handleMouseOut = () => {
    setCancel(false);
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}>
      <Fab
        aria-label="action"
        sx={{
          width: 35,
          height: 35,
          bgcolor: "rgba(0, 0, 0, 0.5)",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          '&:hover': {
            bgcolor: "rgba(0, 0, 0, 0.6)",
          },
        }}
        onMouseOver={() => handleMouseOver()}
        onMouseOut={() => handleMouseOut()}
      >
        {
          isLoading && cancel ? (
            <IconButton>
              <Clear sx={{ color: 'white' }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => handleTryAgain()}
            >
              <FileUpload sx={{ color: 'white' }} />
            </IconButton>
          )
        }
      </Fab>
      {
        isLoading && (
          <CircularProgress
            size={45}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zid: 1,
              color: theme => theme.palette.primary.dark,
            }}
          />
        )
      }
    </Box >
  )
}

export default Loader;