import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { InstagramEditState } from './types/InstagramEditState';
import { PermissionReadMessagesPayload } from 'redux/types/PermissionReadMessagesPayload';
import { PermissionStartConversationsPayload } from 'redux/types/PermissionStartConversationsPayload';
import { Permission } from '@trii/types/dist/Common/Channels';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ISchedule } from '@trii/types/dist/Common/Schedules';
import { IChannel } from '@trii/types/dist/Common/Channels';
// Service
import instagramEditService from './instagramEditService';

const initialState: InstagramEditState = {
  channel: null,
  instagramName: null,
  status: {
    fetch: 'idle',
    update: 'idle',
  },
};

export const fetchInstagram = createAsyncThunk(
  'instagramEdit/fetchInstagram',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await instagramEditService.fetchInstagram(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const updateInstagram = createAsyncThunk<
  IChannel,
  void,
  { state: RootState }
>('instagramEdit/updateInstagram', async (_, { getState, dispatch }) => {
  const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const instagram = getState().InstagramEdit.channel;
  console.log(instagram);
  await instagramEditService.updateInstagram(jwtToken, URL_CONVERSATIONS, instagram);
  return instagram;
});

const instagramEditSlice = createSlice({
  name: 'instagramEdit',
  initialState,
  reducers: {
    changeName(state, action: PayloadAction<string>) {
      state.channel.name = action.payload;
    },
    changeToken(state, action: PayloadAction<string>) {
      state.channel.instagram.token = action.payload;
    },
    changeReassign(state, action: PayloadAction<boolean>) {
      state.channel.instagram.reassign = action.payload;
    },
    changeReassignGroup(state, action: PayloadAction<string>) {
      state.channel.instagram.reassignGroupId = action.payload;
    },
    changeAutoResponseBusinessHours(state, action: PayloadAction<boolean>) {
      state.channel.instagram.autoResponseBusinessHours = action.payload;
    },
    changeAutoResponseOutOfBusinessHours(state, action: PayloadAction<boolean>) {
      state.channel.instagram.autoResponseOutOfBusinessHours = action.payload;
    },
    changeAutoResponseHoliday(state, action: PayloadAction<boolean>) {
      state.channel.instagram.autoResponseHoliday = action.payload;
    },
    changeSchedule(state, action: PayloadAction<ISchedule>) {
      const schedule = action.payload;

      state.channel.instagram.schedule = schedule;
    },
    changeSubscribedInstagramEvents(state, action: PayloadAction<string[]>) {
      const subscribedEvents = action.payload;

      state.channel.instagram.igSubscribedEvents = subscribedEvents;
    },
    addPermission: {
      prepare: (groupId: string): { payload: Permission } => ({
        payload: {
          groupId,
          readMessages: false,
          startConversations: false,
        },
      }),
      reducer: (state, action: PayloadAction<Permission>) => {
        state.channel.permissions.push(action.payload);
      },
    },
    removePermission(state, action: PayloadAction<string>) {
      const groupId = action.payload;
      const index = state.channel.permissions.findIndex(
        (permission) => permission.groupId === groupId
      );

      state.channel.permissions.splice(index, 1);
    },
    changePermissionReadMessages(
      state,
      action: PayloadAction<PermissionReadMessagesPayload>
    ) {
      const { permissionGroupId, readMessages } = action.payload;
      const permission = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      ) as Permission;

      permission.readMessages = readMessages;
    },
    changePermissionStartConversation(
      state,
      action: PayloadAction<PermissionStartConversationsPayload>
    ) {
      const { permissionGroupId, startConversations } = action.payload;
      const permission = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );

      permission.startConversations = startConversations;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInstagram.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchInstagram.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('Fetched Instagram: ', action.payload);
        state.channel = action.payload;
        state.instagramName = action.payload.name;
      })
      .addCase(updateInstagram.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateInstagram.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        state.instagramName = action.payload.name;
      })
      .addCase(updateInstagram.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      });
  },
});

const selectInstagramEditState = (state: RootState) => state.InstagramEdit;
export const selectInstagram = createSelector(
  selectInstagramEditState,
  (state) => state.channel
);
export const selectInstagramName = createSelector(
  selectInstagramEditState,
  (state) => state.instagramName
);
export const selectInstagramFetchStatus = createSelector(
  selectInstagramEditState,
  (state) => state.status.fetch
);
export const selectInstagramUpdateStatus = createSelector(
  selectInstagramEditState,
  (state) => state.status.update
);

export const {
  changeName,
  changeToken,
  changeReassign,
  changeReassignGroup,
  changeAutoResponseBusinessHours,
  changeAutoResponseOutOfBusinessHours,
  changeAutoResponseHoliday,
  changeSchedule,
  changeSubscribedInstagramEvents,
  addPermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
} = instagramEditSlice.actions;

export default instagramEditSlice.reducer;
