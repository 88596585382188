import axios from 'axios';
import { NewWebChat } from './types/NewWebChat';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchWebChats = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/webChat`, config);
  return response.data;
};
const createWebChat = async (jwt: string, URL: string, webChat: NewWebChat) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const webChatJson = JSON.stringify(webChat);
  const response = await axios.post(`${URL}/channels/webChat`, webChatJson, config);
  return response.data;
};
const deleteWebChat = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/webChat/${id}`, config);

  return id;
};
const updateWebChat = async (jwt: string, URL: string, webChat: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const webChatJson = JSON.stringify(webChat);

  const response = await axios.put(
    `${URL}/channels/webChat/${webChat.id}`,
    webChatJson,
    config
  );
  return response.data;
};

const webChatService = {
  fetchWebChats,
  createWebChat,
  deleteWebChat,
  updateWebChat,
};

export default webChatService;
