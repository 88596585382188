import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { WhatsAppState } from './types/WhatsAppState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewWhatsApp } from './types/NewWhatsApp';
// Service
import whatsAppService from './whatsAppService';

const initialState: WhatsAppState = {
  whatsApps: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchWhatsApps = createAsyncThunk(
  'whatsApp/fetchWhatsApps',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await whatsAppService.fetchWhatsApps(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

export const createWhatsApp = createAsyncThunk(
  'whatsApp/createWhatsApp',
  async (whatsApp: NewWhatsApp, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await whatsAppService.createWhatsApp(
      jwtToken,
      URL_CONVERSATIONS,
      whatsApp
    );

    return response;
  }
);

export const deleteWhatsApp = createAsyncThunk(
  'whatsApp/deleteWhatsApp',
  async (whatsAppId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await whatsAppService.deleteWhatsApp(
      jwtToken,
      URL_CONVERSATIONS,
      whatsAppId
    );

    return response;
  }
);

const whatsAppSlice = createSlice({
  name: 'whatsApp',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWhatsApps.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchWhatsApps.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.whatsApps = action.payload;
      })
      .addCase(createWhatsApp.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createWhatsApp.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.whatsApps.push(action.payload);
      })
      .addCase(deleteWhatsApp.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteWhatsApp.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.whatsApps = state.whatsApps.filter(
          (whatsApp) => whatsApp.id !== action.payload
        );
      });
  },
});

const selectWhatsAppState = (state: RootState) => state.WhatsApp;
export const selectAllWhatsApps = createSelector(
  selectWhatsAppState,
  (state) => state.whatsApps
);
export const selectWhatsAppsFetchStatus = createSelector(
  selectWhatsAppState,
  (state) => state.status.fetch
);
export const selectWhatsAppCreateStatus = createSelector(
  selectWhatsAppState,
  (state) => state.status.create
);
export const selectWhatsAppDeleteStatus = createSelector(
  selectWhatsAppState,
  (state) => state.status.delete
);

export default whatsAppSlice.reducer;
