import React from 'react';
// Components/ui
import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const Footer = () => {

    return (
        <Box
            display="flex"
            alignItems="center"
            width='100%'
            justifyContent="center"
            height='100%'
        >
            <Typography
                variant='inherit'
                color='textSecondary'
                sx={{
                    height: '100%',
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                19:34
            </Typography>
            <StarIcon
                fontSize='inherit'
            />
        </Box>
    );
}

export default Footer;