import { createContext } from 'react';
// Types
import { ActivitiesCardContextType } from './types/ActivitiesCardContextType';
// Utils
import { useCreateActivityInitialDef } from '../hooks/useCreateActivity';

export const ActivitiesCardContext = createContext<ActivitiesCardContextType>({
  isCreatePopUpOpen: false,
  columns: [],
  newActivity: useCreateActivityInitialDef,
  isEditing: false,
  setIsEditing: () => { },
  sortByColumn: () => { },
  openEditActivityPopUp: () => { },
  closeActivityPopUp: () => { },
  openCreateActivityPopUp: () => { },
  handleCreateActivity: () => { },
  handleUpdateActivity: () => { },
  handleDeleteActivity: () => { },
  handleEndActivity: () => { },
  showDetails: false,
  setShowDetails: () => { },
  selectedActivity: null,
  tabsSowed: [],
  setTabsSowed: () => { },
});
