import { useContext, useEffect, useState } from 'react';
// Context
import { TemplatesContext } from './TemplatesContext';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Types
import { EditionType } from './types/EditionType';
import { ChannelList, TemplatesList } from './types/Lists';
import { FocusType } from './types/FocusType';
import { TemplateVar, TemplateVarButton } from '@trii/types/dist/Common/Messages';
// Redux
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/features/userSlice/userSlice';
import { postTemplate } from 'redux/features/templatesSlice/templatesSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { dbWorker } from 'db/db';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

interface TemplatesProviderProps {
  children: React.ReactNode;
  handleClose?: () => void;
}

export const TemplatesProvider: React.FC<TemplatesProviderProps> = ({
  children,
  handleClose,
}) => {
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const conversationSelected = useSelector(selectConversationSelected);

  const [focusType, setFocusType] = useState<FocusType>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editionType, setEditionType] = useState<EditionType>(null);
  const [channel, setChannel] = useState(null);
  const [template, setTemplate] = useState(null);
  const [channelList, setChannelList] = useState<ChannelList[]>([]);
  const [templateList, setTemplateList] = useState<TemplatesList[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<ChannelList | null>(null);
  // Template
  const [selectedTemplate, setSelectedTemplate] = useState<TemplatesList | null>(
    null
  );
  const [localBodyVariables, setLocalBodyVariables] = useState<TemplateVar[]>(null);
  const [localHeaderVariables, setLocalHeaderVariables] =
    useState<TemplateVar[]>(null);
  const [localButtons, setLocalButtons] = useState<TemplateVarButton[]>(null);

  const updateChannel = () => {};
  const updateTemplate = (id: string, data: TemplatesList) => {
    const updatedTemplateList = templateList.map((template) =>
      template.id === id ? { ...template, ...data } : template
    );

    setTemplateList(updatedTemplateList);

    setSelectedTemplate(data);
  };

  function convertButtonsVars(buttonsVars: TemplateVarButton[]) {
    return buttonsVars.flatMap((button) => {
      return button.vars.map((variable) => {
        return {
          btn: button.btnNumber - 1,
          var: variable.name.replace(/{{|}}/g, ''), // remove {{ and }} from the name
          value: variable.value,
        };
      });
    });
  }

  function convertBodyHeadVarsNameMinusOne(vars: TemplateVar[]) {
    return vars.map((variable) => {
      // Remove the name attribute and replace it for "var" attribute with the name without {{ and }}
      return {
        var: variable.name.replace(/{{|}}/g, ''),
        value: variable.value,
      };
    });
  }

  function getTemplate() {
    return {
      from: conversationSelected.contactInfo?.id,
      to: conversationSelected.remoteAddress,
      conversationId: conversationSelected.id,
      userId: user.uid,
      channelInfo: conversationSelected.channelInfo,
      templateId: selectedTemplate.id,
      headerVars: convertBodyHeadVarsNameMinusOne(localHeaderVariables),
      bodyVars: convertBodyHeadVarsNameMinusOne(localBodyVariables),
      buttonVars: convertButtonsVars(localButtons),
    };
  }

  async function sendTemplateMessage() {
    const template = getTemplate();
    const response = await dispatch(postTemplate(template));

    if (response.payload) {
      await dbWorker.postMessage({
        action: 'setMessage',
        data: response.payload,
      });
    }

    handleClose();
  }

  function cleanUp() {
    setSelectedTemplate(null);
  }

  useEffect(() => {
    setLocalBodyVariables(selectedTemplate?.varsBody);
    setLocalHeaderVariables(selectedTemplate?.varsHeader);
    setLocalButtons(selectedTemplate?.varsButtons);
  }, [selectedTemplate]);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  return (
    <TemplatesContext.Provider
      value={{
        editionType,
        setEditionType,
        updateChannel,
        updateTemplate,
        channelList,
        setChannelList,
        channel,
        setChannel,
        templateList,
        setTemplateList,
        template,
        setTemplate,
        selectedChannel,
        setSelectedChannel,
        selectedTemplate,
        setSelectedTemplate,
        focusType,
        setFocusType,
        isEditing,
        setIsEditing,
        // Template state
        localBodyVariables,
        setLocalBodyVariables,
        localHeaderVariables,
        setLocalHeaderVariables,
        localButtons,
        setLocalButtons,
        sendTemplateMessage,
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
};
