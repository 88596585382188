import { useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Avatar, Badge, Box, Chip, Typography } from '@mui/material';
// Icons
import { Verified } from '@mui/icons-material';
// Types
import { IChatMemeber } from '@trii/types/dist/Conversations';
// Components
import InformationBox from '../InformationBox';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// Utils
import { getBadgeStatus } from '../../utils/getBadgeStatus';
import { UserStatus } from '@trii/types/dist/Users';
import { useSelector } from 'react-redux';
import { selectUsers } from 'redux/features/userSlice/userSlice';

interface Props {
  member: IChatMemeber;
  handleSelectUser: (id: string) => void;
  userSelectedInfo: string;
}

const GroupInformationBox = ({
  member,
  handleSelectUser,
  userSelectedInfo,
}: Props) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [maxHeight, setMaxHeight] = useState<string>('max-content');
  const {
    isAdmin,
    user: { id, name, verifiedAccount, imageUrl, email, phone },
  } = member;

  const users = useSelector(selectUsers);

  const status = users.find((user) => user.id === id)?.status;

  const stringAvatar = (name: string) => {
    if (name.includes(' ')) {
      return {
        children: `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`,
      };
    } else {
      return {
        children: `${name?.split(' ')[0][0]}`,
      };
    }
  };

  const getBadgeColor = () => {
    switch (status) {
      case UserStatus.ONLINE:
        return 'success';
      case UserStatus.AWAY:
        return 'warning';
      case UserStatus.BUSY:
        return 'error';

      default:
        return 'default';
    }
  };

  useEffect(() => {
    if (userSelectedInfo && userSelectedInfo === id) {
      setShowDetails(true);
      setMaxHeight('10rem');
    } else {
      setShowDetails(false);
      setMaxHeight('4rem');
    }
  }, [userSelectedInfo]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        padding: 2,
        width: '100%',
        height: '100%',
        maxHeight,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
          cursor: 'pointer',
        },
        transition: 'max-height .2s ease-out',
      }}
      onClick={() => handleSelectUser(id)}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            width: 'max-content',
          }}
        >
          <Badge
            color={getBadgeColor()}
            overlap="circular"
            badgeContent=" "
            variant="dot"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            sx={{
              '.MuiBadge-badge': {
                backgroundColor: status === UserStatus.OFFLINE ? 'grey' : undefined,
              },
            }}
          >
            <Avatar
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.12)',
                bgcolor: (theme) => theme.palette.primary.light,
                color: (theme) => theme.palette.text.secondary,
                fontSize: '1rem',
                width: 30,
                height: 30,
              }}
              src={imageUrl}
              children={stringAvatar(name).children}
            />
          </Badge>
          <Typography
            variant="body1"
            fontWeight="semi-bold"
            color={'text.primary'}
            sx={{
              userSelect: 'none',
            }}
          >
            {name}
          </Typography>
          {/* <Verified
            sx={{
              color: (theme) => verifiedAccount ? (
                theme.palette.primary.light
              ) : (
                theme.palette.text.disabled
              ),
            }}
          /> */}
        </Box>
        <Box
          sx={{
            width: 'max-content',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {isAdmin && (
            <Chip
              label="Admin."
              sx={{
                bgcolor: (theme) => theme.palette.primary.light,
                color: (theme) => theme.palette.text.secondary,
                fontSize: 'small',
                userSelect: 'none',
              }}
            />
          )}
        </Box>
      </Box>
      {showDetails && (
        <Box
          sx={{
            display: showDetails ? 'flex' : 'none',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            animation: 'fadeIn .3s ease-out',
            paddingTop: 1,
            '@keyframes fadeIn': {
              '0%': {
                opacity: '0',
              },
              '100%': {
                opacity: '1',
              },
            },
          }}
        >
          {email && email !== '' && (
            <InformationBox icon={<EmailIcon />} value={email} />
          )}
          {phone && phone !== '' && (
            <InformationBox icon={<LocalPhoneIcon />} value={phone} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default GroupInformationBox;
