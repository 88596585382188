export const style = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  title: {
    color: (theme) => theme.palette.text.disabled,
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  resultsContainer: {
    overflow: 'auto',
    height: '450px',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  loading: {
    width: '5rem',
    height: '5rem',
  },
};
