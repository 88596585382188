import { useState, useEffect, useContext } from "react";
// Translate
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
// Context
import { conversationsContext } from "features/Views/Conversations/context/ConversationsProvider/ConversationsProvider";
// Slice
import {
  selectContactInfo,
  selectContactInfoFetchStatus
} from "redux/features/contactInfoSlice/contactInfoSlice";
// Components/ui
import { Box, Skeleton } from "@mui/material";
// Components
import { Button } from "./components";
// Icons
import {
  Email,
  Facebook,
  Forum,
  Instagram,
} from "@mui/icons-material";
// Types
import { ChannelType } from "@trii/types/dist/Common/Channels";
import { IContactAddress, IContactInfo } from "@trii/types/dist/Contacts";

interface ActionButtonsProps {
  contactSelected: IContactInfo;
  channelSelected: ChannelType;
  setChannelSelected: (channel: ChannelType) => void;
  setList: (list: IContactAddress[]) => void;
}

const ActionButtons = ({
  contactSelected,
  channelSelected,
  setChannelSelected,
  setList,
}: ActionButtonsProps) => {
  const {
    getImage,
    setChannel,
    getChannelList,
  } = useContext(conversationsContext);
  const { t } = useTranslation();
  const contactInfo = useSelector(selectContactInfo);
  const contactInfoFetchStatus = useSelector(selectContactInfoFetchStatus);
  const isLoading = contactInfoFetchStatus === 'loading';
  const skeletonSize = [1, 2, 3, 4, 5];
  const [phoneList, setPhoneList] = useState<IContactAddress[]>([]);
  const [filterPhoneList, setFilterPhoneList] = useState<IContactAddress[]>([]); // [1
  const [emailList, setEmailList] = useState<IContactAddress[]>([]);
  const [imsInstagramList, setImsInstagramList] = useState<IContactAddress[]>([]);
  const [imsFacebookList, setImsFacebookList] = useState<IContactAddress[]>([]);
  const [imsWebchatList, setImsWebchatList] = useState<IContactAddress[]>([]);

  const handleSelect = (
    channel: ChannelType,
    channelUrl: string,
    list: IContactAddress[],
  ) => {
    setChannelSelected(channel);
    const channelInfo = getImage(channel);
    setChannel(channelInfo);
    setList(list);
    if (channelUrl) {
      getChannelList(channelUrl)
    }
  }

  useEffect(() => {
    if (contactSelected) {
      const {
        phones,
        emails,
        ims_instagram,
        ims_facebook,
        ims_webchat,
      } = contactInfo || {};
      setPhoneList(phones);
      const uniqueIds = [];
      const unique = phones?.filter(element => {
        const isDuplicate = uniqueIds.includes(element.channelType);

        if (!isDuplicate) {
          uniqueIds.push(element.channelType);

          return true;
        }

        return false;
      });
      setFilterPhoneList(unique);
      setEmailList(emails);
      setImsInstagramList(ims_instagram);
      setImsFacebookList(ims_facebook);
      setImsWebchatList(ims_webchat);
    } else {
      setPhoneList([]);
      setEmailList([]);
      setImsInstagramList([]);
      setImsFacebookList([]);
      setImsWebchatList([]);
    }
    setChannelSelected(ChannelType.NULL);
  }, [contactInfo, contactSelected])

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: '1rem',
        overflowX: 'auto',
      }}
    >
      {
        isLoading ? (
          skeletonSize.map((item) => (
            <Skeleton
              key={item}
              variant='rounded'
              width='100%'
              height={30}
              sx={{ my: 1 }}
            />
          ))
        ) : (
          <>
            {
              filterPhoneList && filterPhoneList.length > 0 && filterPhoneList?.map((item) => {
                const whatsapp = getImage(ChannelType.WHATSAPP);
                const sms = getImage(ChannelType.SMS);
                return (
                  <Box
                    key={item.id}
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                    }}
                  >
                    <Button
                      title={whatsapp.title}
                      icon={whatsapp.icon}
                      onClick={() => handleSelect(ChannelType.WHATSAPP, whatsapp.channel, phoneList)}
                      channelSelected={channelSelected}
                      type={ChannelType.WHATSAPP}
                    />
                    <Button
                      title={sms.title}
                      icon={sms.icon}
                      onClick={() => handleSelect(ChannelType.SMS, sms.channel, phoneList)}
                      channelSelected={channelSelected}
                      type={ChannelType.SMS}
                    />
                  </Box>
                )
              })
            }
            {
              emailList && emailList.length > 0 && (
                <Button
                  title={t('conversations.createConversation.email')}
                  icon={<Email
                    fontSize="small"
                    sx={{
                      color: (theme) =>
                        channelSelected === ChannelType.EMAIL ?
                          theme.palette.text.disabled : theme.palette.primary.main
                    }} />}
                  onClick={() => handleSelect(ChannelType.EMAIL, 'email', emailList)}
                  channelSelected={channelSelected}
                  type={ChannelType.EMAIL}
                />
              )
            }
            {
              imsInstagramList && imsInstagramList.length > 0 && (
                <Button
                  title={t('conversations.createConversation.instagram')}
                  icon={<Instagram
                    fontSize="small"
                    sx={{
                      color: (theme) =>
                        channelSelected === ChannelType.INSTAGRAM ?
                          theme.palette.text.disabled : theme.palette.primary.main
                    }} />}
                  onClick={() => handleSelect(ChannelType.INSTAGRAM, 'instagram', imsInstagramList)}
                  channelSelected={channelSelected}
                  type={ChannelType.INSTAGRAM}
                />
              )
            }
            {
              imsFacebookList && imsFacebookList.length > 0 && (
                <Button
                  title={t('conversations.createConversation.facebook')}
                  icon={<Facebook
                    fontSize="small"
                    sx={{
                      color: (theme) =>
                        channelSelected === ChannelType.FACEBOOK ?
                          theme.palette.text.disabled : theme.palette.primary.main

                    }} />}
                  onClick={() => handleSelect(ChannelType.FACEBOOK, 'facebook', imsFacebookList)}
                  channelSelected={channelSelected}
                  type={ChannelType.FACEBOOK}
                />
              )
            }
            {
              imsWebchatList && imsWebchatList.length > 0 && (
                <Button
                  title={t('conversations.createConversation.webchat')}
                  icon={<Forum
                    fontSize="small"
                    sx={{
                      color: (theme) =>
                        channelSelected === ChannelType.WEBCHAT ?
                          theme.palette.text.disabled : theme.palette.primary.main

                    }} />}
                  onClick={() => handleSelect(ChannelType.WEBCHAT, 'webchat', imsWebchatList)}
                  channelSelected={channelSelected}
                  type={ChannelType.WEBCHAT}
                />
              )
            }
          </>
        )
      }
    </Box>
  )
}

export default ActionButtons