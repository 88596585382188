import { useEffect } from 'react';
// Context
import ConversationsProvider from './context/ConversationsProvider/ConversationsProvider';
import MessagesProvider from './context/MessagesProvider/MessagesProvider';
// Components
import ConversationsContainer from './components';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';

const Conversations = () => {
  const dispatch = useAppDispatch();

  return (
    <ConversationsProvider>
      <MessagesProvider>
        <ConversationsContainer />
      </MessagesProvider>
    </ConversationsProvider>
  );
};

export default Conversations;
