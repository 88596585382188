import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
//Types
import { IChannel } from '@trii/types/dist/Common/Channels';

const fetchInstagram = async (
  jwt: string,
  URL: string,
  id: string
): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/instagram/${id}`, config);
  return response.data;
};
const updateInstagram = async (jwt: string, URL: string, instagram: IChannel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const instagramJson = JSON.stringify(instagram);

  const response = await axios.put(
    `${URL}/channels/instagram/${instagram.id}`,
    instagramJson,
    config
  );
  return response.data;
};

const instagramEditService = {
  fetchInstagram,
  updateInstagram,
};

export default instagramEditService;
