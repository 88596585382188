import { useEffect, useRef } from 'react';

const useNotificationSound = () => {
  const notificationRef = useRef<HTMLAudioElement | null>(null);

  function playNotification() {
    if (notificationRef.current) {
      notificationRef.current.play().catch((error) => {
        console.log('notification playback failed', error);
      });
    }
  }

  useEffect(() => {
    notificationRef.current = document.getElementById(
      'WhatsAppNewMessage'
    ) as HTMLAudioElement;
  }, []);

  return {
    playNotification,
  };
};

export default useNotificationSound;
