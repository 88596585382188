// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
// Types
import { MessageEmail } from '@trii/types/dist/Common/Messages';
// Components
import { EmailDetails } from './components';
import { useState } from 'react';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';
import useSAS from 'hooks/useSAS';

interface EmailProps {
  email: MessageEmail;
  date: string;
}

const Email = ({ email, date }: EmailProps) => {
  const { t } = useTranslation();

  const conversationSelected = useSelector(selectConversationSelected);

  const [open, setOpen] = useState(false);

  const URLHandler = useSAS();

  const handleDownload = async (url: string) => {
    const link = document.createElement('a');
    const fileName = url.split('/').pop();
    const accessURL = await URLHandler.getURLWithAccessToken(url);

    link.download = fileName || '';
    link.href = accessURL;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleModal = () => {
    setOpen(!open);
  };
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {t('conversations.channel.email.from')}
        </Typography>
        <Chip size="small" label={conversationSelected?.channelInfo?.name} />
      </Box>
      <Box display="flex" gap={1} alignItems="center" flexWrap={'wrap'}>
        <Typography variant="body2" color="textSecondary">
          {t('conversations.channel.email.to')}
        </Typography>
        {email?.to?.map((to) => (
          <Chip key={to.id} size="small" label={to.address} />
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body2" color="textSecondary">
            {t('conversations.channel.email.subject')}
          </Typography>
          <Typography
            variant="body2"
            fontWeight="bold"
            noWrap
            sx={{
              maxWidth: 200,
            }}
          >
            {email?.subject}
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Tooltip title={t('conversations.channel.email.fullPage')}>
            <IconButton size="small" onClick={toggleModal}>
              <UnfoldMore
                fontSize="small"
                sx={{
                  transform: 'rotate(45deg)',
                  color: (theme) => theme.palette.text.disabled,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {email?.attachments && email?.attachments.length > 0 && (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body2" color="textSecondary">
            {t('conversations.channel.email.attachments')}
          </Typography>
          <Box display="flex" gap={1} flexWrap={'wrap'}>
            {email?.attachments.map((attachment) => (
              <a
                href={attachment.url}
                target="_blank"
                rel="noreferrer"
                key={attachment.id}
              >
                <Chip
                  size="small"
                  label={attachment.filename}
                  onClick={() => handleDownload(attachment.url)}
                  sx={{
                    width: 'max-content',
                  }}
                />
              </a>
            ))}
          </Box>
        </Box>
      )}
      <EmailDetails email={email} open={open} onClose={toggleModal} date={date} />
    </Box>
  );
};

export default Email;
