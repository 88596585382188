import React from 'react';
// Translation
import { getI18n } from 'react-i18next';
// Components/ui
import { Box } from '@mui/material';
// Component Emoji
import data from '@emoji-mart/data/sets/14/facebook.json';
import Picker from '@emoji-mart/react';
// Theme
import { useTheme } from '@mui/material/styles';

interface Props {
  handleEmojiSelect: (emoji) => void;
}

const Emojis = ({ handleEmojiSelect }) => {
  const languaje = getI18n().language.toLocaleLowerCase();
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        maxHeight: 'inherit',
        '& > div': {
          width: '100%',
          height: '100%',
          maxHeight: 'inherit',
        },
      }}
    >
      <style>
        {`
          em-emoji-picker {
            width: 100%;
            height: 100%;
            max-height: inherit;
            overflow: auto;
            --rgb-background: ${
              //@ts-ignore
              theme.palette.background.dropdownMenu
            };
            --rgb-input: ${
              //@ts-ignore
              theme.palette.background.dropdownMenu
            };
            background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))
          }
        `}
      </style>
      <Picker
        data={data}
        onEmojiSelect={(e) => handleEmojiSelect(e)}
        set="facebook"
        locale={languaje}
        previewPosition="none"
        dynamicWidth
        style={{
          width: '100%',
          height: '100%',
          maxHeight: 'inherit',
          color: theme.palette.text.primary,
        }}
        theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
      />
    </Box>
  );
};

export default Emojis;
