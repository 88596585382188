import { Box, Typography } from '@mui/material';

interface Props {
  title?: string;
  value: string;
  icon?: React.ReactNode;
}
const InformationBox = ({ title, value, icon }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // gap: 1,
        p: 1,
        width: '100%',
        height: '3rem',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      {icon}
      <Typography variant="body2" fontWeight="semi-bold" color={'text.primary'}>
        {title}
      </Typography>
      <Typography variant="body2" fontWeight="semi-bold" color={'text.primary'}>
        {value}
      </Typography>
    </Box>
  );
};

export default InformationBox;
