import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
//Types
import { IChannel } from '@trii/types/dist/Common/Channels';

const fetchSms = async (jwt: string, URL: string, id: string): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/sms/${id}`, config);
  return response.data;
};
const updateSms = async (jwt: string, URL: string, sms: IChannel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const smsJson = JSON.stringify(sms);

  const response = await axios.put(`${URL}/channels/sms/${sms.id}`, smsJson, config);
  return response.data;
};

const smsEditService = {
  fetchSms,
  updateSms,
};

export default smsEditService;
