import { useState, useEffect } from 'react';
// Components/ui
import { Box, Fade, IconButton, Slider } from '@mui/material';
// Icons
import { VolumeDown, VolumeOff, VolumeUp } from '@mui/icons-material';

interface VolumeProps {
  videoRef: React.RefObject<HTMLVideoElement>;
}

const Volume = ({ videoRef }: VolumeProps) => {
  const [volume, setVolume] = useState(1);
  const [showVolumeSlider, setShowVolumeSlider] = useState<boolean>(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
  }, [volume]);

  const handleVolumeChange = (_event: Event, newValue: number | number[]) => {
    const newVolume = typeof newValue === 'number' ? newValue / 100 : 0;
    setVolume(newVolume);
  };

  const handleToggleMute = () => {
    if (videoRef.current) {
      if (videoRef.current.volume === 0) {
        videoRef.current.volume = volume || 1;
        setVolume(videoRef.current.volume);
      } else {
        videoRef.current.volume = 0;
        setVolume(0);
      }
    }
  };

  const handleLeaveVolumeSlider = () => {
    setShowVolumeSlider(false);
  };

  const handleEnterVolumeSlider = () => {
    setShowVolumeSlider(true);
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <IconButton
        onClick={handleToggleMute}
        onMouseEnter={handleEnterVolumeSlider}
        onMouseLeave={handleLeaveVolumeSlider}
      >
        {
          volume === 0 ? (
            <VolumeOff
              sx={{
                color: 'white'
              }}
            />
          ) : (
            volume < 0.5 ? (
              <VolumeDown
                sx={{
                  color: 'white'
                }}
              />
            ) : (
              <VolumeUp
                sx={{
                  color: 'white'
                }}
              />
            )
          )
        }
      </IconButton>
      <Fade in={showVolumeSlider}>
        <Box
          sx={{
            position: 'absolute',
            bottom: '2.5rem',
            height: '5rem',
          }}
          onMouseEnter={handleEnterVolumeSlider}
          onMouseLeave={handleLeaveVolumeSlider}
        >
          <Slider
            value={volume * 100}
            onChange={handleVolumeChange}
            aria-label="Video volume slider"
            orientation="vertical"
            sx={{
              color: 'white',
              '& .MuiSlider-thumb': {
                width: '0.7rem',
                height: '0.7rem',
              },
              '& .MuiSlider-track': {
                height: '0.25rem',
              },
            }}
          />
        </Box>
      </Fade>
    </Box>
  )
}

export default Volume