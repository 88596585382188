import "./MainLayout.css";
import { Box, useTheme } from "@mui/material";
import { NavMenu } from "./components";
import { useOpenCallPanelWidth } from "./utils/useOpenCallPanelWidth";

function MainLayout(props) {
  const theme = useTheme()
  const { boxWidth } = useOpenCallPanelWidth();

  return (
    <Box
      sx={{
        display: 'flex',
        width: boxWidth,
        height: 'calc(100vh - 4.3rem)',
      }}
    >
      <Box
        sx={{
          //@ts-ignore
          backgroundImage: theme.palette.background.mainGradient,
          maxWidth: '3rem',
          minWidth: '3rem',
          width: '100%',
          borderRight: `1px solid ${theme.palette.divider}`
        }}
      >
        <NavMenu />
      </Box>
      <Box
        sx={{
          //@ts-ignore
          backgroundColor: theme.palette.background.mainCenter,
          width: '100%',
        }}
      >
        <Box
          id="js-page-content"
          // p={2}
          sx={{
            overflow: 'auto',
            height: '100%',
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

export default MainLayout;