import { useContext, useEffect, useState } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  LinearProgress,
  Card as MuiCard,
  Typography,
} from '@mui/material';
// Types
import { ChannelType, IChannelInfo } from '@trii/types/dist/Common/Channels';
// Components
import {
  ListSelector,
  MultipleListSelector,
} from './components';
import { IContactAddress } from '@trii/types/dist/Contacts';

interface CardProps {
  title: string;
  isOrigin: boolean;
  channelSelected: ChannelType;
  list: IChannelInfo[] | IContactAddress[];
  onSelect: (selector: string[]) => void;
  selected: string[];
  isLoading: boolean;
}

const ORIGIN_DESCRIPTION = 'originDescription';
const DESTINATION_DESCRIPTION = 'destinationDescription';

const Card = ({
  title,
  isOrigin,
  channelSelected,
  list,
  selected,
  onSelect,
  isLoading,
}: CardProps) => {
  const {
    channel,
  } = useContext(conversationsContext);
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>('');

  const getDescripion = (channelType: ChannelType, type: string) => {
    switch (channelType) {
      case ChannelType.WHATSAPP:
        return `conversations.createConversation.${type}.destinationChannel`;
      case ChannelType.FACEBOOK || ChannelType.INSTAGRAM || ChannelType.WEBCHAT:
        return `conversations.createConversation.userChannel`;
      case ChannelType.EMAIL:
        return `conversations.createConversation.${type}.email`;
      case ChannelType.PHONE:
        return `conversations.createConversation.${type}.${type === ORIGIN_DESCRIPTION ? 'originNumber' : 'phone'}`;
      default:
        return `conversations.createConversation.${type}.phone`;
    }
  }

  useEffect(() => {
    if (isOrigin) {
      const newDescription = getDescripion(channelSelected, ORIGIN_DESCRIPTION);
      setDescription(newDescription);
    } else {
      const newDescription = getDescripion(channelSelected, DESTINATION_DESCRIPTION);
      setDescription(newDescription);
    }
  })

  return (
    <MuiCard
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        borderRadius: '.5rem',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '3rem',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          padding: '.5rem',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {
          isLoading && (
            <Box
              position='absolute'
              sx={{
                bottom: 0,
                width: '100%',
              }}
            >
              <LinearProgress
                sx={{
                  borderRadius: 1,
                }}
              />
            </Box>
          )
        }
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            fontSize: '.75rem',
            width: '100%',
          }}
        >
          {t(description)}
        </Typography>
      </Box>
      {
        channelSelected === ChannelType.EMAIL && !isOrigin ? (
          <MultipleListSelector
            list={list}
            selectedIds={selected}
            onSelect={onSelect}
          />
        ) : (
          <ListSelector
            list={list}
            selectedId={selected?.length > 0 ? selected[0] : ''}
            onSelect={onSelect}
          />
        )
      }
    </MuiCard>
  )
}

export default Card