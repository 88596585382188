import { useState, useEffect } from 'react';

const useCheckbox = (initialValue) => {
  initialValue = initialValue || false;
  const [checked, setChecked] = useState(initialValue);

  const onChange = (event) => {
    setChecked(event.target.checked);
  };

  const resetValue = () => {
    setChecked(initialValue || false);
  };

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  return {
    actions: { resetValue },
    attributes: { onChange, checked },
  };
};

export default useCheckbox;
