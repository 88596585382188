/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
import { ISpaceInfo } from '@trii/types/dist/Spaces';

const fetchSpaceInfo = async (jwt: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(
    'https://account.trii.app/api/v1/MySpace',
    config
  );

  return response;
};
const fetchSpacesInfo = async (jwt: string): Promise<ISpaceInfo[]> => {
  const headers = { Authorization: `Bearer ${jwt}` };

  const response = await fetch('https://account.trii.app/api/v1/MySpaces', {
    headers,
  });

  if (!response.ok) {
    throw new Error('Error al obtener la información de los espacios');
  }

  const data: ISpaceInfo[] = await response.json();
  return data;
};
export default {
  fetchSpaceInfo,
  fetchSpacesInfo,
};
