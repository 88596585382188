import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';

export const selectTemplatesSlice = (state: RootState) => state.Templates;
export const selectTemplates = createSelector(
  (state: RootState) => state.Templates.templates,
  (templates) => templates
);
export const selectTemplatesFetchStatus = createSelector(
  (state: RootState) => state.Templates.status.fetch,
  (fetchStatus) => fetchStatus
);
