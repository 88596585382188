// Components/ui
import { Box, Fade, IconButton, Typography } from '@mui/material';
// Icons
import { Close, CropFree, DeleteOutlineOutlined } from '@mui/icons-material';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  handleClose: () => void;
  setOpenModal: (value: boolean) => void;
}

const Header = ({ handleClose, setOpenModal }: HeaderProps) => {
  const { t } = useTranslation();

  const {
    showSavedEmailDraftMessage,
    emailConversationHasDraft,
    handleDeleteEmailDraft,
    resetEmailFields,
  } = useContext(messagesContext);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleDeleteButton = () => {
    handleDeleteEmailDraft();
    // resetEmailFields();
  };

  return (
    <Box
      height="2rem"
      width="100%"
      borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      gap={1}
    >
      <Fade in={showSavedEmailDraftMessage}>
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.text.disabled }}
        >
          {t('conversations.message.email.draftSaved')}
        </Typography>
      </Fade>
      <Fade in={emailConversationHasDraft}>
        <IconButton size="small" onClick={handleDeleteButton}>
          <DeleteOutlineOutlined
            sx={{
              color: (theme) => theme.palette.text.disabled,
            }}
          />
        </IconButton>
      </Fade>
      <IconButton size="small" onClick={handleOpenModal}>
        <CropFree
          sx={{
            color: (theme) => theme.palette.text.disabled,
          }}
        />
      </IconButton>
      <IconButton size="small" onClick={handleClose}>
        <Close
          sx={{
            color: (theme) => theme.palette.text.disabled,
          }}
        />
      </IconButton>
    </Box>
  );
};

export default Header;
