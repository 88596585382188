// Translations
import { useTranslation } from "react-i18next";
import {
  Button as MuiButton,
} from "@mui/material";
// Types
import { ChannelType } from "@trii/types/dist/Common/Channels";

interface ButtonProps {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
  channelSelected: ChannelType;
  type: ChannelType;
}

const Button = ({
  title,
  icon,
  onClick,
  channelSelected,
  type,
}: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <MuiButton
      variant={channelSelected === type ? 'contained' : 'outlined'}
      color="primary"
      startIcon={icon}
      sx={{
        minWidth: 'max-content',
        height: 30,
      }}
      onClick={onClick}
    >
      {t(title)}
    </MuiButton>
  );
}

export default Button;