import { useState, useEffect, ChangeEvent } from 'react';

export interface UseFieldType {
  actions: {
    onError: (errorMsg: string) => void;
    onSuccess: () => void;
    resetValue: () => void;
    changeValue: (newValue: string) => void;
  };
  attributes: {
    error: boolean;
    helperText: string;
    type: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
}

const useField = (type: string, initialValue?: string): UseFieldType => {
  const [value, setValue] = useState(initialValue || '');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    setValue(initialValue || '');

    return () => {
      setValue(initialValue || '');
    };
  }, [initialValue]);

  const onError = (errorMsg: string) => {
    setError(true);
    setHelperText(errorMsg);
  };

  const onSuccess = () => {
    setError(false);
    setHelperText('');
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const changeValue = (newValue: string) => {
    setValue(newValue);
  };

  const resetValue = () => {
    setValue(initialValue || '');
  };

  return {
    actions: { onError, onSuccess, resetValue, changeValue },
    attributes: { error, helperText, type, value, onChange },
  };
};

export default useField;
