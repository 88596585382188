// Types
import { RootState } from "redux/store";
import { createSelector } from '@reduxjs/toolkit';

const createStatusSelector = (statusKey: string) =>
  createSelector(
    (state: RootState) => state.BusinessInfo.status[statusKey],
    (status) => status
  );

export const businessState = (state: RootState) => state.BusinessInfo;

export const selectBusinessStatusCreate = createStatusSelector('create');
export const selectBusinessStatusUpdate = createStatusSelector('update');
export const selectBusinessStatusFetchBusinessById =
  createStatusSelector('fetchBusinessById');
export const selectBusinessStatusfetchBussinesFields =
  createStatusSelector('fetchBussinesFields');
export const selectBusinessData = createSelector(
  businessState,
  (state) => state.businessData
);
export const selectBusinessFields = createSelector(
  businessState,
  (state) => state.businessFields
);
