import React, { useState } from 'react';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// Icons
import {
  Delete,
  Edit,
  KeyboardArrowDown,
  Star,
} from '@mui/icons-material';

type Props = {
  over: boolean;
  setOver: (value: boolean) => void;
}

const Options = ({ over, setOver }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setOver(false);
    setAnchorEl(null);
  }

  return (
    <Box
      display={`${over || anchorEl ? 'flex' : 'none'}`}
      position="absolute"
      right="1rem"
      top="0"
    >
      <IconButton
        onClick={handleOpen}
        size="small"
      >
        <KeyboardArrowDown />
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={openMenu}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 12,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem key={1} >
          <ListItemIcon>
            <Star fontSize='small' />
          </ListItemIcon>
          <ListItemText>
            {t('conversations.note.highlightNote')}
          </ListItemText>
        </MenuItem>
        <MenuItem key={2} >
          <ListItemIcon>
            <Edit fontSize='small' />
          </ListItemIcon>
          <ListItemText>
            {t('conversations.note.edit')}
          </ListItemText>
        </MenuItem>
        <MenuItem key={3} >
          <ListItemIcon>
            <Delete fontSize='small' />
          </ListItemIcon>
          <ListItemText>
            {t('conversations.note.delete')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default Options;