import axios from 'axios';
import { NewForm } from './types/NewForm';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchForms = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/form`, config);
  return response.data;
};
const createForm = async (jwt: string, URL: string, form: NewForm) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const formJson = JSON.stringify(form);
  const response = await axios.post(`${URL}/channels/form`, formJson, config);
  return response.data;
};
const deleteForm = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/form/${id}`, config);

  return id;
};
const updateForm = async (jwt: string, URL: string, form: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const formJson = JSON.stringify(form);

  const response = await axios.put(
    `${URL}/channels/form/${form.id}`,
    formJson,
    config
  );
  return response.data;
};

const formService = {
  fetchForms,
  createForm,
  deleteForm,
  updateForm,
};

export default formService;
