// Components/ui
import { Box } from '@mui/material';
import { Assignment } from '@mui/icons-material';
import { style } from './style';

const FormIcon = () => {
  return (
    <Box sx={style.iconContainer}>
      <Assignment
        sx={{
          color: (theme) => theme.palette.text.primary,
        }}
      />
    </Box>
  );
};

export default FormIcon;
