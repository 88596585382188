import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { WebChatState } from './types/WebChatState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewWebChat } from './types/NewWebChat';
// Service
import webChatService from './webChatService';

const initialState: WebChatState = {
  webChats: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchWebChats = createAsyncThunk(
  'webChat/fetchWebChats',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await webChatService.fetchWebChats(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const createWebChat = createAsyncThunk(
  'webChat/createwebChat',
  async (webChat: NewWebChat, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await webChatService.createWebChat(
      jwtToken,
      URL_CONVERSATIONS,
      webChat
    );

    return response;
  }
);

export const deleteWebChat = createAsyncThunk(
  'webChat/deleteWebChat',
  async (webChatId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await webChatService.deleteWebChat(
      jwtToken,
      URL_CONVERSATIONS,
      webChatId
    );

    return response;
  }
);

const webChatSlice = createSlice({
  name: 'webChat',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWebChats.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchWebChats.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.webChats = action.payload;
      })
      .addCase(createWebChat.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createWebChat.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.webChats.push(action.payload);
      })
      .addCase(deleteWebChat.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteWebChat.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.webChats = state.webChats.filter(
          (webChat) => webChat.id !== action.payload
        );
      });
  },
});

const selectWebChatState = (state: RootState) => state.WebChat;
export const selectAllWebChats = createSelector(
  selectWebChatState,
  (state) => state.webChats
);
export const selectWebChatsFetchStatus = createSelector(
  selectWebChatState,
  (state) => state.status.fetch
);
export const selectWebChatCreateStatus = createSelector(
  selectWebChatState,
  (state) => state.status.create
);
export const selectWebChatDeleteStatus = createSelector(
  selectWebChatState,
  (state) => state.status.delete
);

export default webChatSlice.reducer;
