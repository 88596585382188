import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { GoogleBusinessState } from './types/GoogleBusinessState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewGoogleBusiness } from './types/NewGoogleBusiness';
// Service
import googleBusinessService from './googleBusinessService';

const initialState: GoogleBusinessState = {
  googleBusiness: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchGoogleBusiness = createAsyncThunk(
  'googleBusiness/fetchGoogleBusinesss',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await googleBusinessService.fetchGoogleBusiness(
      jwtToken,
      URL_CONVERSATIONS
    );
    return response;
  }
);

export const createGoogleBusiness = createAsyncThunk(
  'googleBusiness/createGoogleBusiness',
  async (googleBusiness: NewGoogleBusiness, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await googleBusinessService.createGoogleBusiness(
      jwtToken,
      URL_CONVERSATIONS,
      googleBusiness
    );

    return response;
  }
);

export const deleteGoogleBusiness = createAsyncThunk(
  'googleBusiness/deleteGoogleBusiness',
  async (googleBusinessId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await googleBusinessService.deleteGoogleBusiness(
      jwtToken,
      URL_CONVERSATIONS,
      googleBusinessId
    );

    return response;
  }
);

const googleBusinessSlice = createSlice({
  name: 'googleBusiness',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGoogleBusiness.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchGoogleBusiness.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.googleBusiness = action.payload;
      })
      .addCase(createGoogleBusiness.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createGoogleBusiness.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.googleBusiness.push(action.payload);
      })
      .addCase(deleteGoogleBusiness.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteGoogleBusiness.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.googleBusiness = state.googleBusiness.filter(
          (googleBusiness) => googleBusiness.id !== action.payload
        );
      });
  },
});

const selectGoogleBusinessState = (state: RootState) => state.GoogleBusiness;
export const selectAllGoogleBusiness = createSelector(
  selectGoogleBusinessState,
  (state) => state.googleBusiness
);
export const selectGoogleBusinessFetchStatus = createSelector(
  selectGoogleBusinessState,
  (state) => state.status.fetch
);
export const selectGoogleBusinessCreateStatus = createSelector(
  selectGoogleBusinessState,
  (state) => state.status.create
);
export const selectGoogleBusinessDeleteStatus = createSelector(
  selectGoogleBusinessState,
  (state) => state.status.delete
);

export default googleBusinessSlice.reducer;
