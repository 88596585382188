import { styled, Box } from '@mui/system';

export const SelectPrimaryContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
});
export const SelectSecondaryContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  position: 'relative',
});
