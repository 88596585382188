import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
import { RootState } from '../../store';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import responsesService from './responsesService';
// Functions
// import { initRequestData } from '../../functions/initRequestData';
// import InitRequestDataReturn from '../../types/InitRequestDataReturn';
const initialState = {
  id: '*',
  name: '',
  config: {
    autoResponseBusinessHours: false,
    autoResponseOutOfBusinessHours: false,
    autoResponseHoliday: false,
    autoResponseBusinessHoursMessage: '',
    autoResponseOutOfBusinessHoursMessage: '',
    autoResponseHolidayMessage: '',
  },
  statusGet: 'idle',
  statusUpdate: 'idle',
};

export const getResponses = createAsyncThunk(
  'responses/getResponses',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await responsesService.getResponses(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );
    return response;
  }
);

export const updateResponses = createAsyncThunk(
  'responses/updateResponses',
  async (channel, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await responsesService.updateResponses(
      jwtToken,
      URL_CONVERSATIONS,
      channel
    );

    return response;
  }
);

const responseSlice = createSlice({
  name: 'Response',
  initialState,
  reducers: {
    setTitleResponse: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setIdResponse: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setSwitchBusinessHours: (state, action: PayloadAction<boolean>) => {
      state.config.autoResponseBusinessHours = action.payload;
    },
    setSwitchOutOfBusinessHours: (state, action: PayloadAction<boolean>) => {
      state.config.autoResponseOutOfBusinessHours = action.payload;
    },
    setSwitchHolidaysHours: (state, action: PayloadAction<boolean>) => {
      state.config.autoResponseHoliday = action.payload;
    },
    setInputAutoResponseBusinessHoursMessage: (
      state,
      action: PayloadAction<string>
    ) => {
      state.config.autoResponseBusinessHoursMessage = action.payload;
    },
    setInputAutoResponseOutOfBusinessHoursMessage: (
      state,
      action: PayloadAction<string>
    ) => {
      state.config.autoResponseOutOfBusinessHoursMessage = action.payload;
    },
    setInputAutoResponseHolidayMessage: (state, action: PayloadAction<string>) => {
      state.config.autoResponseHolidayMessage = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateResponses.rejected, (state) => {
        state.statusUpdate = 'idle';
      })
      .addCase(updateResponses.pending, (state) => {
        state.statusUpdate = 'loading';
      })
      .addCase(updateResponses.fulfilled, (state, action) => {
        state.statusUpdate = 'succeeded';
      })
      .addCase(getResponses.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(getResponses.rejected, (state) => {
        state.statusGet = 'idle';
      })
      .addCase(getResponses.fulfilled, (state, action) => {
        state.statusGet = 'succeeded';
        state.config.autoResponseBusinessHours =
          action.payload.autoResponseBusinessHours;
        state.config.autoResponseOutOfBusinessHours =
          action.payload.autoResponseOutOfBusinessHours;
        state.config.autoResponseHoliday = action.payload.autoResponseHoliday;
        state.config.autoResponseBusinessHoursMessage =
          action.payload.autoResponseBusinessHoursMessage;
        state.config.autoResponseOutOfBusinessHoursMessage =
          action.payload.autoResponseOutOfBusinessHoursMessage;
        state.config.autoResponseHolidayMessage =
          action.payload.autoResponseHolidayMessage;
      });
  },
});
//Selectors
export const responseSelected = (state: RootState) => state.Response;
export const responseTitle = (state: RootState) => state.Response.name;
export const responseId = (state: RootState) => state.Response.id;
export const switchAutoResponseBusinessHours = (state: RootState) =>
  state.Response.config.autoResponseBusinessHours;
export const switchAutoResponseOutOfBusinessHours = (state: RootState) =>
  state.Response.config.autoResponseOutOfBusinessHours;
export const switchAutoResponseHoliday = (state: RootState) =>
  state.Response.config.autoResponseHoliday;
export const inputAutoResponseBusinessHoursMessage = (state: RootState) =>
  state.Response.config.autoResponseBusinessHoursMessage;
export const inputAutoResponseOutOfBusinessHoursMessage = (state: RootState) =>
  state.Response.config.autoResponseOutOfBusinessHoursMessage;
export const inputAutoResponseHolidayMessage = (state: RootState) =>
  state.Response.config.autoResponseHolidayMessage;
export const responseGetStatus = (state: RootState) => state.Response.statusGet;
export const responseUpdateStatus = (state: RootState) =>
  state.Response.statusUpdate;

//Actions
export const {
  setTitleResponse,
  setIdResponse,
  setSwitchBusinessHours,
  setSwitchOutOfBusinessHours,
  setSwitchHolidaysHours,
  setInputAutoResponseBusinessHoursMessage,
  setInputAutoResponseOutOfBusinessHoursMessage,
  setInputAutoResponseHolidayMessage,
} = responseSlice.actions;

export default responseSlice.reducer;
