import { useContext, useState } from 'react';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import { Box, ClickAwayListener, SpeedDial, SpeedDialAction } from '@mui/material';
import { AttachFile, Photo } from '@mui/icons-material';
import FeedIcon from '@mui/icons-material/Feed';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function AttachMediaDial() {
  const { handleFileUpload, handleDocumentUpload } = useContext(messagesContext);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { setModalTitle, setOpenModalList } = useContext(conversationsContext);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageIconClick = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*, video/*';
    inputElement.multiple = true;
    inputElement.addEventListener('change', handleFileUpload.bind(this));
    inputElement.click();
  };
  const handleTextFileIconClick = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.multiple = true;
    inputElement.addEventListener('change', handleDocumentUpload.bind(this));
    inputElement.click();
  };

  const handleFormIconClick = (title: string) => {
    setOpenModalList(true);
    setModalTitle(t(title));
  };
  const handleLocationIconClick = (title: string) => {
    setOpenModalList(true);
    setModalTitle(t(title));
  };

  return (
    <Box mr={6} position="relative">
      <ClickAwayListener onClickAway={() => handleClose()}>
        <SpeedDial
          ariaLabel="SpeedDial files"
          icon={<AttachFile />}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '-0.5rem',
          }}
          FabProps={{
            size: 'small',
            sx: {
              color: (theme) => theme.palette.grey[600],
              boxShadow: 'none',
              bgcolor: !open
                ? 'transparent'
                : (theme) => `${theme.palette.grey[200]}30`,
              '&:hover': {
                bgcolor: (theme) => theme.palette.divider,
              },
              '&:active': {
                boxShadow: 'none',
                bgcolor: (theme) => `${theme.palette.grey[200]}30`,
                color: (theme) => theme.palette.grey[600],
              },
            },
          }}
          open={open}
          onClick={handleOpen}
        >
          <SpeedDialAction
            icon={<Photo />}
            tooltipTitle={t('global.photos&videos')}
            onClick={handleImageIconClick}
            sx={{
              backgroundColor: 'background.panel',
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            }}
          />
          <SpeedDialAction
            icon={<AttachFile />}
            tooltipTitle={t('global.documents')}
            onClick={handleTextFileIconClick}
            sx={{
              backgroundColor: 'background.panel',
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            }}
          />
          <SpeedDialAction
            icon={<FeedIcon />}
            tooltipTitle={t('global.form')}
            onClick={() =>
              handleFormIconClick('conversations.message.actions.selectForm')
            }
            sx={{
              backgroundColor: 'background.panel',
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            }}
          />
          <SpeedDialAction
            icon={<LocationOnIcon />}
            tooltipTitle={t('global.sendLocation')}
            onClick={() =>
              handleLocationIconClick('conversations.message.actions.sendLocation')
            }
            sx={{
              backgroundColor: 'background.panel',
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            }}
          />
        </SpeedDial>
      </ClickAwayListener>
    </Box>
  );
}

export default AttachMediaDial;
