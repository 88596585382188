import { useEffect, useContext } from 'react';
import { FilePreviewerContext } from 'features/Views/Conversations/context/FilePreviewerProvider/FilePreviewerContext';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import {
  DocumentPreview,
  DocumentSelector,
  FunctionalityButtons,
  FilePreview,
  MessageInput,
  FileSelector,
  SendButton,
} from './components';
import { Box } from '@mui/material';

const FilePreviewer = () => {
  const { endFileSelectorMode } = useContext(messagesContext);
  const { filesCopy } = useContext(FilePreviewerContext);

  useEffect(() => {
    const cleanUp = () => {
      endFileSelectorMode();
    };

    return () => {
      cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        p: 1,
        width: '100%',
        height: '100%',
        bgcolor: 'background.panel',
      }}
    >
      <FunctionalityButtons />
      {filesCopy?.length > 0 ? <FilePreview /> : <DocumentPreview />}
      <Box
        sx={{
          width: '100%',
          height: '20%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {filesCopy?.length > 0 && <MessageInput />}
        <Box
          sx={{
            width: '100%',
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {filesCopy?.length > 0 ? <FileSelector /> : <DocumentSelector />}
          <SendButton />
        </Box>
      </Box>
    </Box>
  );
};

export default FilePreviewer;
