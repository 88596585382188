import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { EndingState } from './types/FlowState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewFlow } from './types/NewFlow';
// Service
import flowService from './flowService';

const initialState: EndingState = {
  flows: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchFlows = createAsyncThunk(
  'flow/fetchFlows',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await flowService.fetchFlows(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const createFlow = createAsyncThunk(
  'flow/createFlow',
  async (flow: NewFlow, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await flowService.createFlow(jwtToken, URL_CONVERSATIONS, flow);

    return response;
  }
);

export const deleteFlow = createAsyncThunk(
  'flow/deleteFlow',
  async (flowId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await flowService.deleteFlow(
      jwtToken,
      URL_CONVERSATIONS,
      flowId
    );

    return response;
  }
);

const flowSlice = createSlice({
  name: 'flow',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFlows.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchFlows.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.flows = action.payload;
      })
      .addCase(createFlow.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createFlow.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.flows.push(action.payload);
      })
      .addCase(deleteFlow.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteFlow.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.flows = state.flows.filter((flow) => flow.id !== action.payload);
      });
  },
});

const selectFlowState = (state: RootState) => state.Flow;
export const selectAllFlows = createSelector(
  selectFlowState,
  (state) => state.flows
);
export const selectFlowsFetchStatus = createSelector(
  selectFlowState,
  (state) => state.status.fetch
);
export const selectFlowCreateStatus = createSelector(
  selectFlowState,
  (state) => state.status.create
);
export const selectFlowDeleteStatus = createSelector(
  selectFlowState,
  (state) => state.status.delete
);

export default flowSlice.reducer;
