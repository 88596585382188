import axios from 'axios';
import { IChannel } from '@trii/types/dist/Common/Channels';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchForm = async (
  jwt: string,
  URL: string,
  id: string
): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/form/${id}`, config);
  return response.data;
};
const updateForm = async (jwt: string, URL: string, form: IChannel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const formJson = JSON.stringify(form);

  const response = await axios.put(
    `${URL}/channels/form/${form.id}`,
    formJson,
    config
  );
  return response.data;
};

const formEditService = {
  fetchForm,
  updateForm,
};

export default formEditService;
