import { socketContext } from 'context/WebSocketProvider/SocketProvider';
import { useContext, useEffect, useState } from 'react';

export interface UseDateTime {
  getDateTime: () => Date;
}

export const useDateTimeInitialState: UseDateTime = {
  getDateTime: () => new Date(),
};

const useDateTime = (): UseDateTime => {
  const [serverTime, setServerTime] = useState<Date | null>(null);

  const { subscribeEvent } = useContext(socketContext);

  function getDateTime(): Date {
    if (serverTime) {
      const localTime = new Date();
      const diff = localTime.getTime() - serverTime.getTime();
      const newDate = new Date(localTime.getTime() - diff);

      return newDate;
    }

    return new Date();
  }

  useEffect(() => {
    subscribeEvent('datetime', (data: { datetime: number }) => {
      setServerTime(new Date(data.datetime));
    });
  }, []);

  return {
    getDateTime,
  };
};

export default useDateTime;
