import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
// Components/ui
import { IconButton } from '@mui/material';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import './print.css';

type PrintButtonProps = {
  imageUrl: string;
};

const PrintButton: React.FC<PrintButtonProps> = ({ imageUrl }) => {
  const componentRef = useRef<HTMLImageElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handlePrint}
        size="large"
        sx={{
          position: "absolute",
          top: "10px",
          right: "60px",
          zIndex: 1,
          color: "primary.contrastText",
        }}
      >
        <LocalPrintshopIcon
          sx={{
            fontSize: 28,
          }}
        />
      </IconButton>
      <div style={{ display: "none" }}>
        <div id="print-content">
          <img
            ref={componentRef}
            // style={{ width: '100%' }}
            src={imageUrl}
            alt="Print"
          />
        </div>
      </div>
    </>
  );
};

export default PrintButton;
