import { Alert as AlertUi, Box, LinearProgress } from "@mui/material"
import { useEffect, useRef, useState } from "react";

interface Props {
  open: boolean;
  errorMessage: string;
  onClose: () => void;
  severity: "error" | "info" | "success" | "warning";
}

const Alert = ({
  open,
  errorMessage,
  onClose,
  severity,
}: Props) => {
  const [progress, setProgress] = useState<number>(100);
  const timer = useRef<NodeJS.Timeout>(null);

  const handleClose = () => {
    clearTimeout(timer.current);
    timer.current = null;
    setProgress(100);
    onClose();
  }

  useEffect(() => {
    const totalDecrements = 100 / 1.2;
    const decrementPerInterval = 100 / totalDecrements;
    const newInterval = setInterval(() => {
      setProgress((prevProgress) => prevProgress - decrementPerInterval);
    }, 100);

    timer.current = setTimeout(() => {
      handleClose();
      clearInterval(newInterval);
    }, 5000);

    return () => clearTimeout(timer.current);
  }, [errorMessage])

  return (
    <Box
      sx={{
        display: open ? 'flex' : 'none',
        width: 'max-content',
        height: 'max-content',
        position: 'absolute',
        bottom: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
        transition: 'display 0.5s ease-in-out',
      }}
    >
      <Box
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        <AlertUi
          onClose={handleClose}
          severity={severity}
          sx={{
            width: '100%',
            position: 'relative',
            userSelect: 'none',
          }}
          variant="filled"
        >
          {errorMessage}
        </AlertUi>
        <LinearProgress
          variant="determinate"
          value={progress}
          color={severity}
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
          }}
        />
      </Box>
    </Box>
  )
}

export default Alert