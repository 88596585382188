import { Box, CircularProgress, List, Typography } from '@mui/material';
import { FormItem } from './components';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchForms,
  selectAllForms,
  selectFormsFetchStatus,
} from 'redux/features/formSlice/formSlice';
import { RequestStatus } from 'redux/types/RequestStatus';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'redux/rootReducer';
import { AnyAction } from 'redux';
import { useEffect } from 'react';

const AddForm = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const allForms = useSelector(selectAllForms);
  const formsFetchStatus: RequestStatus = useSelector(selectFormsFetchStatus);

  const Forms = allForms.map((form, index) => (
    <FormItem key={form.id} data={form} index={index} />
  ));

  useEffect(() => {
    dispatch(fetchForms());
  }, []);

  return (
    <Box
      borderRadius={1}
      px={{ md: '1rem', lg: '1rem' }}
      sx={{
        color: (theme) => theme.palette.text.primary,
        //@ts-ignore
        backgroundColor: (theme) => theme.palette.background.panel,
      }}
    >
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 6rem)',
          overflow: 'auto',
        }}
      >
        {formsFetchStatus === 'succeeded' ? (
          Forms.length > 0 ? (
            Forms
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '35px',
              }}
            >
              <Typography
                variant="body1"
                sx={{ opacity: 0.7 }}
                color={(theme) => theme.palette.text.disabled}
              >
                No existen formularios registrados
              </Typography>
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '25px',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </List>
    </Box>
  );
};

export default AddForm;
