import React, { useState, useCallback, forwardRef, useEffect, useContext } from "react";
import { GoogleMapsProvider } from "@ubilabs/google-maps-react-hooks";
import MapCanvas from "./components/map-canvas/map-canvas";
import { Box, CircularProgress } from "@mui/material";
import { messagesContext } from "features/Views/Conversations/context/MessagesProvider/MessagesProvider";
import MapMarkers from "./components/map-markers/map-markers";
import GeocodingService from "./components/geocoding-service.tsx/geocoding-service";
const GoogleMap = () => {
  const [mapContainer, setMapContainer] = useState(null);
  const mapRef = useCallback((node) => {
    node && setMapContainer(node);
  }, []);
  const [userLocation, setUserLocation] = useState(null);
  const {userLocationContext, setUserLocationContext} = useContext(messagesContext)
  const zoomMap = 18


  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("position", position)
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const latitudeString = latitude.toString();
        const longitudeString = longitude.toString();

        const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoomMap}&size=400x400&markers=color:red%7Clabel:A%7C${latitude},${longitude}&key=AIzaSyBmoGBAVgJOl-wOHvpr9ZP0jK4a7WbR0Mk`;

        setUserLocation({ latitude, longitude });
        setUserLocationContext([
          {
            lat: latitudeString,
            lng: longitudeString,
            title: "",
            description: "",
            url: staticMapUrl,
          },
        ]);
        
      },
      (error) => {
        console.error("Error getting location:", error);
      }
    );
  }, []); 
  
  if (!userLocation) {
    // Puedes mostrar un mensaje de carga o un indicador mientras se obtiene la ubicación
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  const mapOptions = {
    center: { lat: userLocation.latitude, lng: userLocation.longitude },
    zoom: zoomMap,
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {
      position: 3, // Right top
    },
  };
  return (
    <GoogleMapsProvider
      googleMapsAPIKey="AIzaSyBmoGBAVgJOl-wOHvpr9ZP0jK4a7WbR0Mk"
      mapContainer={mapContainer}
      mapOptions={mapOptions}
    >
      {/* <div id="container">
          <MapCanvas ref={mapRef} />
        </div> */}
      <div style={{ height: "100vh" }} ref={mapRef}>
        <MapCanvas center={userLocation} />
        <GeocodingService markerUser={userLocation} />
      </div>
      {/* <div ref={(node) => setMapContainer(node)} style={{height: "100vh"}} /> */}
    </GoogleMapsProvider>
  );
};

export default GoogleMap;
