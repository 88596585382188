import axios from 'axios';
import { NewEmail } from './types/NewEmail';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchEmails = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/email`, config);
  return response.data;
};
const createEmail = async (jwt: string, URL: string, email: NewEmail) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const emailJson = JSON.stringify(email);
  const response = await axios.post(`${URL}/channels/email`, emailJson, config);
  return response.data;
};
const deleteEmail = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/email/${id}`, config);

  return id;
};
const updateEmail = async (jwt: string, URL: string, email: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const emailJson = JSON.stringify(email);

  const response = await axios.put(
    `${URL}/channels/email/${email.id}`,
    emailJson,
    config
  );
  return response.data;
};

const emailService = {
  fetchEmails,
  createEmail,
  deleteEmail,
  updateEmail,
};

export default emailService;
