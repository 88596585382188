import { useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';

const FromBox = () => {
  const { from, setFrom } = useContext(messagesContext);
  const conversationSelected = useSelector(selectConversationSelected);

  const { t } = useTranslation();

  useEffect(() => {
    if (conversationSelected) {
      setFrom(conversationSelected.channelInfo.name);
    }
  }, []);

  return (
    <Box height="2rem" width="100%" display="flex" alignItems="center" gap={1}>
      <Typography
        variant="body2"
        fontSize=".8rem"
        width="4rem"
        color={(theme) => theme.palette.text.primary}
      >
        {t('conversations.message.email.from')}
      </Typography>
      <Typography
        variant="body2"
        color={(theme) => theme.palette.text.disabled}
        fontSize=".8rem"
        width="100%"
        sx={{
          backgroundColor: (theme) =>
            //@ts-ignore
            theme.palette.background.dropdownMenu,
          borderRadius: '5px',
          paddingLeft: '.5rem',
        }}
      >
        {from}
      </Typography>
    </Box>
  );
};

export default FromBox;
