import { useState } from 'react'
// Components/ui
import {
  Box,
} from '@mui/material'
// Components
import {
  Body,
  Footer,
  Header,
} from './components';

const NoteType = ({ text }) => {
  const [over, setOver] = useState<boolean>(false);

  const handleOver = () => {
    setOver(true);
  }

  const handleOut = () => {
    setOver(false);
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      margin='0 auto'
      width="90%"
      maxWidth="90%"
      height="100%"
      maxHeight="25rem"
      px={2}
      py={1}
      sx={{
        backgroundColor: "#f2e772",
        boxShadow: "-.2rem .2rem .2rem rgba(0, 0, 0, 0.15)",
        borderRadius: ".5rem",
        position: "relative",
        '&:before': {
          background: "none repeat scroll 0 0 #658E15",
          borderColor: "#f3f3f3 #f3f3f3 #c1b52a #c1b52a",
          borderStyle: "solid",
          borderWidth: "0 16px 16px 0",
          content: "''",
          display: "block",
          position: "absolute",
          right: "0",
          top: "0",
          width: "0",
        },
      }}
      onMouseOver={handleOver}
      onMouseOut={handleOut}
    >
      <Header over={over} setOver={setOver} />
      <Body text={text} />
      <Footer />
    </Box>
  )
}

export default NoteType