import { useEffect, useState } from "react";
// Components/ui
import { Box, ListItemButton, ListItemText, Checkbox } from "@mui/material";
// Types
import { IChannelInfo } from "@trii/types/dist/Common/Channels";
import { IContactAddress } from "@trii/types/dist/Contacts";

interface MultipleListSelectorProps {
  list: IChannelInfo[] | IContactAddress[];
  selectedIds: string[];
  onSelect: (item: string[]) => void;
}

const MultipleListSelector = ({
  list,
  selectedIds,
  onSelect,
}: MultipleListSelectorProps) => {
  const [newSelectedIds, setNewSelectedIds] = useState<string[]>([]);

  const handleSelect = (item: IContactAddress, checked: boolean) => {
    if (checked) {
      if (selectedIds?.length > 0) {
        onSelect([...selectedIds, item.id]);
      } else {
        onSelect([item.id]);
      }
    } else {
      onSelect(selectedIds?.filter((selectedItemId) => selectedItemId !== item.id));
    }
  }

  useEffect(() => {
    if (selectedIds) {
      setNewSelectedIds(selectedIds.map((item) => item));
    }
  }, [selectedIds]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
      }}
    >
      {
        list?.map((item) => (
          <ListItemButton
            key={item.id}
            sx={{
              width: '100%',
            }}
            onClick={() => handleSelect(item, !newSelectedIds?.includes(item.id))}
            selected={newSelectedIds?.includes(item.id)}
          >
            <ListItemText
              primary={item.name || item.address}
            />
            <Checkbox
              checked={newSelectedIds?.includes(item.id)}
              color="primary"
              inputProps={{ 'aria-label': item.name || item.address }}
            />
          </ListItemButton>
        ))
      }
    </Box>
  );
}

export default MultipleListSelector;