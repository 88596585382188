import { Box, styled } from '@mui/material';

export const StyledContactDataContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  gap: '8px',
  overflow: "hidden"
});
