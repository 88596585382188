import axios from 'axios';
import { NewMercadoLibre } from './types/NewMercadoLibre';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchMercadoLibres = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/mercadoLibre`, config);
  return response.data;
};
const createMercadoLibre = async (
  jwt: string,
  URL: string,
  mercadoLibre: NewMercadoLibre
) => {
  if (!mercadoLibre) {
    const config = getRequestConfig.basic(jwt);
    const response = await axios.post(`${URL}/channels/mercadoLibre`, {}, config);

    return response.data;
  }

  const config = getRequestConfig.contentTypeJson(jwt);
  const response = await axios.post(
    `${URL}/channels/mercadoLibre`,
    mercadoLibre,
    config
  );

  return response.data;
};
const deleteMercadoLibre = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/mercadoLibre/${id}`, config);

  return id;
};
const updateMercadoLibre = async (jwt: string, URL: string, mercadoLibre: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const mercadoLibreJson = JSON.stringify(mercadoLibre);

  const response = await axios.put(
    `${URL}/channels/mercadoLibre/${mercadoLibre.id}`,
    mercadoLibreJson,
    config
  );
  return response.data;
};

const mercadoLibreService = {
  fetchMercadoLibres,
  createMercadoLibre,
  deleteMercadoLibre,
  updateMercadoLibre,
};

export default mercadoLibreService;
