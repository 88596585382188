// Components/ui
import { style } from './style';
import { Box } from '@mui/material';
// Types
import { MessageForm, MessageFormStatus } from '@trii/types/dist/Common/Messages';
import { FormIcon, FormTypeTitle, FormBody, FormFooter } from './components';
// Moment
import moment from 'moment';

type FormTypeProps = {
  form: MessageForm;
  timestamp: Date;
};

const FormType = ({ form, timestamp }: FormTypeProps) => {
  const dateHour = moment(timestamp).format('HH:mm');

  return (
    <Box
      sx={{
        ...style.container,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <FormIcon />
      <FormTypeTitle />
      <FormBody data={form} />
      <FormFooter status={form.status} timestamp={dateHour} />
    </Box>
  );
};

export default FormType;
