export const style = {
  container: {
    width: '100%',
    padding: '10px',
    paddingBottom: '9px',
    borderLeft: `0px`,
  },
  previewContainer: {
    border: '1px solid lightgray',
    padding: '10px',
    height: '100%',
  },
};
