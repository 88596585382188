import React from 'react';
// Components/ui
import { Box } from '@mui/material';

const Gradient = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '70%',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, transparent)',
      }}
    />
  );
};

export default Gradient;
