import { useEffect, useState } from "react"
// Translation
import { useTranslation } from "react-i18next"
// Components/ui
import {
  Box,
  Typography
} from "@mui/material"
// Components/ui
import DocumentIcon from "features/Views/Conversations/components/components/DocumentIcon/DocumentIcon"
// Types
import { MessageDocument } from "@trii/types/dist/Common/Messages"
import TextFileExtension from "features/Views/Conversations/components/components/DocumentIcon/types/DocumentExtension"
import { FileCopy } from "@mui/icons-material"

interface DocumentProps {
  documents: MessageDocument[]
}

const Document = ({ documents }: DocumentProps) => {
  const { t } = useTranslation()
  const [extension, setExtension] = useState<TextFileExtension>("pdf")
  const [documentName, setDocumentName] = useState<string>("")
  const defaultDocumentName = `+${documents.length} ${t("conversations.message.documents")}`
  useEffect(() => {
    if (documents.length === 1) {
      const document = documents[0]
      const { filename, mimeType } = document
      setDocumentName(filename)
      if (mimeType.startsWith("image")) {
        setExtension("image")
        return
      }
      if (mimeType.startsWith("video")) {
        setExtension("video")
        return
      }
      const newExtension = filename.split(".").pop() as TextFileExtension
      setExtension(newExtension)
    }
  }, [documents])

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "1rem",
        gap: 1,
      }}
    >
      {
        documents.length > 1 ? (
          <FileCopy 
            sx={{
              color: (theme) => theme.palette.text.disabled,
              fontSize: "1rem",
            }}
          />
        ) : (
          <Box
            width="1rem"
            height="1rem"
          >
            <DocumentIcon
              documentType={extension}
            />
          </Box>
        )
      }
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.disabled,
            fontSize: ".8rem",
          }}
        >
          {documentName || defaultDocumentName}
        </Typography>
      </Box>
    </Box>
  )
}

export default Document