import { useEffect, useState } from "react";
// Types
import { UserInfo } from "@trii/types/dist/Users";
// Context
import { UserInfoContext } from "./UserInfoContext";

interface Props {
  children: React.ReactNode;
}

export const UserInfoProvider = ({ children }: Props) => {
  const [usersInfo, setUsersInfo] = useState<UserInfo[]>([]);
  const [usersIds, setUsersIds] = useState<string[]>([]);

  const value = {
    usersInfo,
    setUsersInfo,
    usersIds,
    setUsersIds,
  };

  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  );
}