import axios from 'axios';
import { NewSip } from './types/NewSip';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchSips = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/sip`, config);
  return response.data;
};
const createSip = async (jwt: string, URL: string, sip: NewSip) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const sipJson = JSON.stringify(sip);
  const response = await axios.post(`${URL}/channels/sip`, sipJson, config);
  return response.data;
};
const deleteSip = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/sip/${id}`, config);

  return id;
};
const updateSip = async (jwt: string, URL: string, sip: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const sipJson = JSON.stringify(sip);

  const response = await axios.put(`${URL}/channels/sip/${sip.id}`, sipJson, config);
  return response.data;
};

const sipService = {
  fetchSips,
  createSip,
  deleteSip,
  updateSip,
};

export default sipService;
