// Context
import { useContext, useState } from 'react';
import { conversationsContext } from '../../ConversationsProvider/ConversationsProvider';
// Types
import { FormattedFormMessage } from '../../../../../../redux/features/messagesSlice/types/FormattedFormMessage';
import {
  MessageDirection,
  MessageAck,
  MessageType,
} from '@trii/types/dist/Common/Messages';
// Id gen
import ObjectID from 'bson-objectid';
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

const useFormMessage = () => {
  const conversationSelected = useSelector(selectConversationSelected);

  function getFormattedMessage(formId: string): FormattedFormMessage {
    return {
      id: ObjectID().toString(),
      conversationId: conversationSelected.id,
      timestamp: new Date(),
      from: conversationSelected.channelInfo.name, // These could change varing on the type of conversation (external or internal)
      to: conversationSelected.remoteAddress,
      mentions: [],
      direction: MessageDirection.OUT,
      ack: MessageAck.ACK_NONE,
      type: MessageType.CHAT_EXTERNAL,
      forwarded: false,
      remoteDeleted: false,
      deleted: false,
      form: {
        id: formId,
      },
    };
  }

  return {
    getFormattedMessage,
  };
};

export default useFormMessage;
