import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
//Types
import { IChannel } from '@trii/types/dist/Common/Channels';

const fetchGoogleBusiness = async (
  jwt: string,
  URL: string,
  id: string
): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/googleBusiness/${id}`, config);
  return response.data;
};
const updateGoogleBusiness = async (
  jwt: string,
  URL: string,
  googleBusiness: IChannel
) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const googleBusinessJson = JSON.stringify(googleBusiness);

  const response = await axios.put(
    `${URL}/channels/googleBusiness/${googleBusiness.id}`,
    googleBusinessJson,
    config
  );
  return response.data;
};

const googleBusinessEditService = {
  fetchGoogleBusiness,
  updateGoogleBusiness,
};

export default googleBusinessEditService;
