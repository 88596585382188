// Components/ui
import { Box } from '@mui/material';
// Components
import { Options } from './components';

type Props = {
  over: boolean,
  setOver: (value: boolean) => void,
}

const Header = ({ over, setOver }: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Options over={over} setOver={setOver} />
    </Box>
  );
}

export default Header;