import { useContext, useEffect, useState } from 'react';
import {
  useGeocodingService,
  useGoogleMap
} from '@ubilabs/google-maps-react-hooks';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';

interface GeocodingServiceProps {
  markerUser: {
    latitude: number;
    longitude: number;
  };
}

const GeocodingService: React.FC<GeocodingServiceProps> = ({ markerUser }) => {
    const map = useGoogleMap();
    const geocoder = useGeocodingService();
    const [marker, setMarker] = useState<google.maps.Marker | null>(null);
    const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(
      null
    );
    const { setUserLocationContext } = useContext(messagesContext);
  
    useEffect(() => {
      if (!map) {
        return () => {};
      }
  
      const newMarker = new google.maps.Marker({
        map,
        position: new google.maps.LatLng(markerUser.latitude, markerUser.longitude),
      });
  
      setMarker(newMarker);
  
      const newInfoWindow = new google.maps.InfoWindow({
        content: 'Haz clic en el mapa para obtener la dirección inversa.',
        position: new google.maps.LatLng(markerUser.latitude, markerUser.longitude),
      });
  
      setInfoWindow(newInfoWindow);
      newInfoWindow.open(map, newMarker);
  
      const clickListener = map.addListener(
        'click',
        (mapsMouseEvent: google.maps.MapMouseEvent) => {
          geocoder?.geocode(
            { location: mapsMouseEvent.latLng },
            (
              results: google.maps.GeocoderResult[] | null,
              status: google.maps.GeocoderStatus
            ) => {
              if (status !== 'OK' || !results) {
                console.error(
                  `La geocodificación no fue exitosa por la siguiente razón: ${status}`
                );
                return;
              }
  
              const position = results[0].geometry.location;
              const formattedAddress = results[0].formatted_address;             
  
              if (!position || !formattedAddress) {
                return;
              }
  
              newMarker?.setPosition(position);
              infoWindow?.setPosition(position);
              infoWindow?.setContent(formattedAddress);
  
              map?.setCenter(results[0].geometry.location);
  
              setUserLocationContext([
                {
                  lat: position.lat().toString(),
                  lng: position.lng().toString(),
                  title: formattedAddress,
                  description: "",
                  url: `https://maps.googleapis.com/maps/api/staticmap?center=${position.lat()},${position.lng()}&zoom=18&size=400x400&markers=color:red%7Clabel:A%7C${position.lat()},${position.lng()}&key=AIzaSyBmoGBAVgJOl-wOHvpr9ZP0jK4a7WbR0Mk`,
                },
              ]);
            }
          );
        }
      );
  
      return () => {
        newInfoWindow?.close();
        newMarker?.setMap(null);
        google.maps.event.removeListener(clickListener);
      };
    }, [map, markerUser, setUserLocationContext, geocoder]);
  
    return null;
  };
  
  export default GeocodingService;
