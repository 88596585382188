// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-zA3GzII\\+ngpgJ4rg779aQ\\=\\= {\n    width: 100%;\n    height: 100%;\n  }", "",{"version":3,"sources":["webpack://./src/features/Views/Conversations/components/components/ModalList/components/SendLocation/components/GoogleMap/components/map-canvas/map-canvas.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;EACd","sourcesContent":[".map {\n    width: 100%;\n    height: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map": "-zA3GzII+ngpgJ4rg779aQ=="
};
export default ___CSS_LOADER_EXPORT___;
