import React from 'react';
// Components/ui
import { Typography } from '@mui/material';

const Body = ({ text }: { text: string }) => {

    return (
        <>
            <Typography
                sx={{
                    wordWrap: "break-word",
                    padding: ".1rem",
                    whiteSpace: "pre-wrap",
                    maxHeight: "100%",
                    overflowY: "auto",
                }}
                fontSize={(theme) => theme.typography.body1.fontSize}
                variant="body1"
                component="p"
            >
                {text}
            </Typography>
        </>
    );
}

export default Body;