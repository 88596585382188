import { useContext } from 'react';
import { FilePreviewerContext } from 'features/Views/Conversations/context/FilePreviewerProvider/FilePreviewerContext';
// Components/ui
import { Box } from '@mui/material';
import { DocumentItem, AddButton } from './components';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';

const DocumentSelector = () => {
  const { handleDocumentUpload } = useContext(messagesContext);
  const {
    selectedDocument,
    documentsCopy,
    handleDocumentRemove,
    handleDocumentSelect,
  } = useContext(FilePreviewerContext);

  const UploadedDocuments = documentsCopy.map((document, i: number) => (
    <DocumentItem
      key={i}
      item={document}
      handleDocumentRemove={handleDocumentRemove}
      handleDocumentSelect={handleDocumentSelect}
      selectedDocument={selectedDocument}
    />
  ));

  return (
    <Box
      sx={{
        width: '60%',
        display: 'flex',
        gap: 2,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {UploadedDocuments}
      <AddButton handleDocumentUpload={handleDocumentUpload} />
    </Box>
  );
};

export default DocumentSelector;
