import React, { forwardRef, useEffect, useState } from 'react';
import { useGoogleMap } from "@ubilabs/google-maps-react-hooks";
import './map-canvas.module.css';

interface MapCanvasProps {
  center: google.maps.LatLngLiteral;
}

const MapCanvas = forwardRef<HTMLDivElement, MapCanvasProps>(
  ({ center }, ref) => {
    const map = useGoogleMap();
    const [marker, setMarker] = useState<google.maps.Marker | null>(null);

    useEffect(() => {
      if (!map || !center || typeof center.lat !== 'number' || typeof center.lng !== 'number') {
        // Verificar que map y center estén definidos y que lat y lng sean números
        return;
      }

      const LatLng = {
        lat: center.lat,
        lng: center.lng,
      };

      const newMarker = new window.google.maps.Marker({
        position: LatLng,
        map: map,
        title: 'Mi Ubicación',
      });

      // Actualizar el estado del marcador
      setMarker(newMarker);
    }, [map, center]);

    return (
      <div ref={ref} className={"map"} style={{ height: "100%" }}>
        {/* Puedes agregar contenido aquí si es necesario */}
      </div>
    );
  }
);

export default MapCanvas;
