import axios from 'axios';
import { IEnding } from '@trii/types/dist/Conversations';
import { NewEnding } from './types/NewEnding';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchEndings = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/endings`, config);
  return response.data;
};
const createEnding = async (jwt: string, URL: string, ending: NewEnding) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const endingJson = JSON.stringify(ending);
  const response = await axios.post(`${URL}/endings`, endingJson, config);
  return response.data;
};
const deleteEnding = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/endings/${id}`, config);

  return id;
};
const updateEnding = async (jwt: string, URL: string, ending: IEnding) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const endingJson = JSON.stringify(ending);

  const response = await axios.put(
    `${URL}/endings/${ending.id}`,
    endingJson,
    config
  );
  return response.data;
};

const endingService = {
  fetchEndings,
  createEnding,
  deleteEnding,
  updateEnding,
};

export default endingService;
