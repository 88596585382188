import { useContext } from 'react';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, IconButton, Tooltip } from '@mui/material';
// Icons
import { Search } from '@mui/icons-material';
// Components
import { MoreActions } from './components';

const ConversationActions = () => {
  const { t } = useTranslation();

  const { setIsSearching } = useContext(messagesContext);

  const handleSearchMessage = () => {
    setIsSearching(true);
  };

  return (
    <Box display="flex" alignItems={'center'} height="100%">
      <Tooltip title={t('conversations.actions.search')} arrow>
        <IconButton sx={{ color: 'text.disabled' }} onClick={handleSearchMessage}>
          <Search />
        </IconButton>
      </Tooltip>
      <MoreActions />
    </Box>
  );
};

export default ConversationActions;
