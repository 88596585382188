import { useEffect, useState, useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Components/ui
import {
  CircularProgress,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@mui/material';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Slice
import {
  selectContactFields,
  selectContactFieldsFetchStatus
} from 'redux/features/contactInfoSlice/contactInfoSlice';
// Types
import { FieldsData } from 'redux/features/contactInfoSlice/types/FieldsData';

type VariableProps = {
  variable: string,
  anchorEl: HTMLDivElement | null,
  caretPosition: number,
  autoFocus: boolean,
  conversationId: string,
  contactId: string,
  handleClose: () => void,
  onSelect: (variable: string) => void,
  handleFocus: () => void,
}

interface PopoverPosition {
  top: number,
  left: number,
}

const Variable = ({
  variable,
  anchorEl,
  caretPosition,
  autoFocus,
  conversationId,
  contactId,
  handleClose,
  onSelect,
  handleFocus,
}: VariableProps) => {
  const {
    getContactFields,
    getVariableInfo,
  } = useContext(messagesContext);
  const { t } = useTranslation();
  const [popoverPosition, setPopoverPosition] = useState<PopoverPosition>(null)
  const [variableList, setVariableList] = useState<string[]>([])
  const contactFieldList = useSelector(selectContactFields);
  const contactFieldFetchStatus = useSelector(selectContactFieldsFetchStatus);
  const isLoading = contactFieldFetchStatus === 'loading';

  const handleSelect = async (variable: string) => {
    const data = {
      variable,
      contactId,
      conversationId,
    }
    const response = await getVariableInfo(data)
    if (response) {
      onSelect(response)
    }
    handleClose()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.key === 'Escape' || event.key === 'Tab') {
      handleFocus()
    }
  }

  useEffect(() => {
    const data = {
      filterBy: 'all',
      fetchFor: 'contact',
    } as FieldsData
    getContactFields(data)
    if (caretPosition > 0) {
      const sel = window.getSelection()
      if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0)
        const rect = range.getBoundingClientRect()
        const left = rect.left - anchorEl?.getBoundingClientRect().left
        const top = rect.top - anchorEl?.getBoundingClientRect().top
        setPopoverPosition({ top, left })
      }
    }
  }, [anchorEl, variable])

  useEffect(() => {
    const contactList = contactFieldList?.map(
      (contact) => `contact.${contact.name.toLocaleLowerCase()}`
    );
    if (variable) {
      const space = variable.replace(/_/g, ' ')
      setVariableList(contactList?.filter((item) => item.toLocaleLowerCase().includes(space.toLocaleLowerCase())))
    } else {
      setVariableList(contactList)
    }
  }, [contactFieldList, variable]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      disableAutoFocus
      disableEnforceFocus
      disableEscapeKeyDown
      anchorOrigin={{
        vertical: popoverPosition ? popoverPosition.top : 0,
        horizontal: popoverPosition ? popoverPosition.left : 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPopover-paper': {
          width: '100%',
          maxWidth: 'max-content',
          maxHeight: 200,
        },
      }}
      onKeyDown={handleKeyDown}
    >
      <MenuList
        sx={{
          width: '100%',
          bgcolor: (theme) => theme.palette.background.default,
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollBehavior: 'smooth',
          maxHeight: 200,
        }}
        autoFocus={autoFocus}
      >
        {
          isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                margin: '1rem',
              }}
            />
          ) : (
            variableList && variableList.length > 0 ? (
              variableList.map((variable, index) => (
                <MenuItem
                  key={index}
                  sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                  }}
                  onClick={() => handleSelect(variable)}
                >
                  {variable}
                </MenuItem>
              ))
            ) : (
              <Typography
                variant="body2"
                sx={{
                  width: '100%',
                  height: '100%',
                  padding: '1rem',
                }}
              >
                {t('conversations.message.variableNoResults')}
              </Typography>
            )
          )
        }
      </MenuList>
    </Popover>
  );
}

export default Variable;