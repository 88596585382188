import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

export const CustomTooltip = styled(({ className, children, placement, title, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    title={title}
    placement={placement}
  >
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '4',
  },
}));