import React from 'react';
// Components/ui
import { Box, Button } from '@mui/material';
// Types
import { MessageHeader } from '@trii/types/dist/Common/Messages';
// Utils
import { getHeaderContent } from 'functions/getHeaderContent';
// Markdown
import { Markdown } from 'functions/Markdown';

interface TextProps {
  text: string;
  footer: string | null;
  header: MessageHeader | null;
  buttons: any[];
}

const Text: React.FC<TextProps> = ({ text, buttons, footer, header }) => {
  const markdown = Markdown(text, false);
  const newText = markdown.replace(/\n/g, '<br />');

  return (
    <Box
      sx={{
        fontSize: '13px',
        wordBreak: 'break-word',
        display: 'flex',
        flexDirection: 'column',
        gap: '3px',
        color: (theme) => theme.palette.text.primary,
      }}
    >
      {header && (
        <Box
          sx={{
            fontWeight: 'bold',
          }}
        >
          {getHeaderContent(header)}
        </Box>
      )}
      <Box dangerouslySetInnerHTML={{ __html: newText }} />
      {footer && footer !== '' && (
        <Box
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
          dangerouslySetInnerHTML={{ __html: footer }}
        />
      )}
      {buttons && (
        <Box
          sx={{
            '& > *': {
              margin: (theme) => theme.spacing(0.5),
            },
          }}
        >
          {buttons.map((button, index) => (
            <Button
              key={index}
              size="small"
              variant="contained"
              style={{
                padding: '0px',
                textTransform: 'inherit',
              }}
              // onClick={(e) => sendMessageRespButton(button.title, e)}
            >
              {button.title}
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Text;
