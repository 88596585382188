import { useEffect, useState, useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Slice
import { selectContactsFetchStatus } from 'redux/features/contactInfoSlice/contactInfoSlice';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
  useTheme,
} from '@mui/material';
// Icons
import {
  ArrowDropDown,
  Clear,
  Person,
  PersonAddAlt1,
  Search,
} from '@mui/icons-material';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Types
import { IContactAddress, IContactInfo } from '@trii/types/dist/Contacts';
import { Pagination } from 'redux/features/contactInfoSlice/types/Pagination';
import { ContactData } from 'redux/features/contactInfoSlice/types/ContactData';
import getImage from 'functions/getImage';

interface SearchContactProps {
  contactTitle: string;
  searchTitle: string;
  contactSelected: IContactInfo;
  handleSelectContact: (contact: IContactInfo) => void;
}

const DATA = {
  currentPage: 1,
  perPage: 10,
  order: 'ASC',
  orderColumn: 'name',
  format: 'IContactInfo',
  operator: 'OR',
  filter: [
    {
      column: 'name',
      value: '',
      condition: '',
    },
    {
      column: 'email',
      value: '',
      condition: '',
    },
    {
      column: 'phone',
      value: '',
      condition: '',
    },
  ],
};

const SearchContact = ({
  contactTitle,
  searchTitle,
  contactSelected,
  handleSelectContact,
}: SearchContactProps) => {
  const { setDestinationSelected } = useContext(conversationsContext);
  const { handleSearchContact } = useContext(messagesContext);
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isNextPage, setIsNextPage] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allContactsLoaded, setAllContactsLoaded] = useState<boolean>(false);
  const [allBusinessesLoaded, setAllBusinessesLoaded] = useState<boolean>(false);
  const [contacts, setContacts] = useState<ContactData>(null);
  const [businesses, setBusinesses] = useState<ContactData>(null);
  const contactsFetchStatus = useSelector(selectContactsFetchStatus);
  const theme = useTheme();

  const handleSearch = async (value: string) => {
    setSearch(value);
    setIsNextPage(false);
    setAllContactsLoaded(false);
    setAllBusinessesLoaded(false);
    setCurrentPage(1);
    setContacts(null);
    setBusinesses(null);
    clearTimeout(timer);
    if (value) {
      setIsLoading(true);
      setTimer(
        setTimeout(async () => {
          const filterValue = DATA.filter.map((item) => ({
            ...item,
            value,
          }));
          const newData = {
            ...DATA,
            filter: filterValue,
          } as Pagination;
          const newBusinessData = {
            ...DATA,
            filter: filterValue,
            format: 'IBusiness',
          } as Pagination;

          const responses = await Promise.all([
            handleSearchContact(newData),
            handleSearchContact(newBusinessData),
          ]);
          setContacts({
            ...responses[0],
            contactos: responses[0].contactos.map((contact) => ({
              ...contact,
              isBusiness: false,
            })),
          });
          setBusinesses({
            ...responses[1],
            contactos: responses[1].contactos.map((contact) => ({
              ...contact,
              isBusiness: true,
            })),
          });
        }, 1000)
      );
    } else {
      setIsLoading(false);
    }
  };

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    const bottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (bottom && !allContactsLoaded && !allBusinessesLoaded) {
      setIsNextPage(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      const filterValue = DATA.filter.map((item) => ({
        ...item,
        value: search,
      }));
      const newData = {
        ...DATA,
        currentPage: newPage,
        filter: filterValue,
      } as Pagination;
      const newBusinessData = {
        ...DATA,
        currentPage: newPage,
        filter: filterValue,
        format: 'IBusiness',
      } as Pagination;
      const responses = await Promise.all([
        handleSearchContact(newData),
        handleSearchContact(newBusinessData),
      ]);
      if (contacts?.paginacion.total === contacts.contactos.length) {
        setAllContactsLoaded(true);
      } else {
        const newContacts = responses[0].contactos.map((contact) => ({
          ...contact,
          isBusiness: false,
        }));
        setContacts((prev) => ({
          ...prev,
          contactos: [...prev.contactos, ...newContacts],
        }));
      }
      if (businesses?.paginacion.total === businesses.contactos.length) {
        setAllBusinessesLoaded(true);
      } else {
        const newBusiness = responses[1].contactos.map((contact) => ({
          ...contact,
          isBusiness: true,
        }));
        setBusinesses((prev) => ({
          ...prev,
          contactos: [...prev.contactos, ...newBusiness],
        }));
      }
    }
  };

  const handleChange = (value: IContactInfo) => {
    handleSelectContact(value);
    if (!value) {
      setDestinationSelected([]);
      setContacts(null);
      setBusinesses(null);
      setSearch('');
    }
  };

  const handleAddContact = () => {
    window.open('/a/contacts/contacts/create', '_blank');
  };

  const getContactInfoAddress = (addresses: IContactAddress[]) => {
    if (addresses && addresses.length > 0) {
      const text = addresses.map((address, i) => {
        const channelInfo = getImage(address.channelType, 15);
        return (
          <Typography key={address.id} noWrap variant="body1" fontSize={'.75rem'}>
            {channelInfo.icon} {address.address}{' '}
            {i < addresses.length - 1 ? ' - ' : ''}
          </Typography>
        );
      });
      return (
        <Box display="flex" alignItems="center">
          {text}
        </Box>
      );
    }
  };

  useEffect(() => {
    if (!contactSelected) {
      setDestinationSelected([]);
      setContacts(null);
      setBusinesses(null);
      setSearch('');
    }
  }, [contactSelected]);

  useEffect(() => {
    setIsLoading(contactsFetchStatus === 'loading');
  }, [contactsFetchStatus]);

  const style = {
    //@ts-ignore
    backgroundColor: theme.palette.background.panel,
    backgroundImage:
      'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
  };
  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: style,
    [`& .${autocompleteClasses.noOptions}`]: style,
    [`& .${autocompleteClasses.groupLabel}`]: {
      //@ts-ignore
      backgroundColor: theme.palette.background.panel,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  });

  return (
    <Box display="flex" width="100%" height="100%" flexDirection="column">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          {contactTitle}
        </Typography>
        <IconButton color="primary" onClick={handleAddContact}>
          <PersonAddAlt1 />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box position="absolute" top="2rem" width="100%">
          {isLoading && (
            <LinearProgress
              sx={{
                borderRadius: 1,
              }}
            />
          )}
        </Box>
        <Autocomplete
          id="asynchronous-search-contact"
          sx={{
            width: '100%',
          }}
          PopperComponent={StyledPopper}
          noOptionsText={t('conversations.createConversation.selectContact')}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={() => search}
          options={[...(contacts?.contactos || []), ...(businesses?.contactos || [])]}
          //@ts-ignore
          options={[
            ...(contacts?.contactos || []),
            ...(businesses?.contactos || []),
          ]}
          groupBy={(option) =>
            //@ts-ignore
            option?.isBusiness
              ? t('conversations.createConversation.business')
              : t('conversations.createConversation.contact')
          }
          defaultValue={contactSelected}
          renderOption={(props, option: IContactInfo) => (
            <Box key={option.id}>
              <ListItemButton
                {...props}
                component={'li'}
                selected={option.id === contactSelected?.id || null}
                dense
                divider
              >
                <ListItemIcon>
                  <Person
                    sx={{
                      color: (theme) => theme.palette.text.disabled,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={option?.name}
                  secondary={getContactInfoAddress(option?.contactAddresses || [])}
                />
              </ListItemButton>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={searchTitle}
              fullWidth
              size="small"
              autoFocus
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      fontSize="small"
                      sx={{
                        color: (theme) => theme.palette.text.disabled,
                      }}
                    />
                    {params.InputProps.startAdornment}
                  </InputAdornment>
                ),
              }}
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
          )}
          onChange={(e, value: IContactInfo) => handleChange(value)}
          ListboxProps={{
            sx: {
              maxHeight: '15rem',
              overflowY: 'auto',
            },
            onScroll: (e) => handleScroll(e),
          }}
          popupIcon={
            <ArrowDropDown
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
              fontSize="small"
            />
          }
          clearIcon={
            <Clear
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
              fontSize="small"
            />
          }
        />
      </Box>
    </Box>
  );
};

export default SearchContact;
