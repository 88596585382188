import { createContext } from 'react';
// Types
import { UseDateTime, useDateTimeInitialState } from './hooks/useDateTime';

export type DateTimeContextType = {
  datetime: UseDateTime;
};

export const DateTimeContext = createContext({
  datetime: useDateTimeInitialState,
});
