// Types
import { ActivitiesTableColumn } from '../types/ActivitiesTableColumn';

export const activitiesTableColumns: ActivitiesTableColumn[] = [
  {
    name: 'title',
    title: 'title',
    sortDirection: 'DESC',
    sorted: false,
  },
  {
    name: 'date',
    title: 'date',
    sortDirection: 'DESC',
    sorted: false,
  },
  {
    name: 'startAt',
    title: 'schedule',
    sortDirection: 'DESC',
    sorted: false,
  },
  {
    name: 'duration',
    title: 'duration',
    sortDirection: 'DESC',
    sorted: false,
  },
  {
    name: 'createdBy',
    title: 'host',
    sortDirection: 'DESC',
    sorted: false,
  },
  {
    name: 'sharedWith',
    title: 'participants',
    sortDirection: 'DESC',
    sorted: false,
  },
];
