import { styled, Box } from '@mui/material';

export const ReminderNotificationsContainer = styled(Box)`
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
`;
export const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  userSelect: 'none',
}));
