import Dexie from 'dexie';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
import { IMessage } from '@trii/types/dist/Common/Messages';

export const dbWorker = new Worker('/chat-db.worker.js');

class ChatDatabase extends Dexie {
  chats: Dexie.Table<IConversation, string>;
  messages: Dexie.Table<IMessage, string>;
  drafts: Dexie.Table<IMessage, string>;

  constructor() {
    super('ChatDatabase');

    this.version(1.4).stores({
      chats: 'id, spaceId',
      messages: 'id, shardKey',
      drafts: 'id, conversationId',
    });

    this.chats = this.table('chats');
    this.messages = this.table('messages');
    this.drafts = this.table('drafts');
  }

  // Internal Chats Section
  // async updateChats(chats: IConversation[]) {
  //   const existingChats = await this.chats.toArray();
  //   const existingChatsIds = existingChats.map((chat) => chat.id);
  //   const receivedChatsIds = chats.map((chat) => chat.id);

  //   const chatsToAdd = chats.filter((chat) => !existingChatsIds.includes(chat.id));
  //   const chatsToDelete = existingChats.filter(
  //     (chat) => !receivedChatsIds.includes(chat.id)
  //   );
  //   const chatsToUpdate = chats.filter((chat) => existingChatsIds.includes(chat.id));

  //   await this.transaction('rw', this.chats, async () => {
  //     if (chatsToAdd.length > 0) {
  //       await this.chats.bulkAdd(chatsToAdd);
  //     }

  //     if (chatsToDelete.length > 0) {
  //       const chatsIdsToDelete = chatsToDelete.map((chat) => chat.id);
  //       await this.chats.where('id').anyOf(chatsIdsToDelete).delete();
  //     }

  //     if (chatsToUpdate.length > 0) {
  //       for (const chat of chatsToUpdate) {
  //         await this.chats.put(chat);
  //       }
  //     }
  //   });
  // }
  // async updateChat(chat: IConversation) {
  //   const id = chat.id;
  //   await this.chats
  //     .where('id')
  //     .equals(id)
  //     .modify({ ...chat });
  // }
  // async deleteChat(chatId: string) {
  //   await this.chats.where('id').equals(chatId).delete();
  // }
  // End Internal Chats Section

  // Messages Section
  // async updateMessages(messages: IMessage[], conversationId: string) {
  //   const existingMessages = await this.messages
  //     .where({
  //       conversationId,
  //     })
  //     .toArray();
  //   const existingMessageIds = existingMessages.map((message) => message.id);
  //   const receivedMessageIds = messages.map((message) => {
  //     if (message.conversationId === conversationId) {
  //       return message.id;
  //     }
  //   });

  //   const messagesToAdd = messages.filter(
  //     (message) =>
  //       message.conversationId === conversationId &&
  //       !existingMessageIds.includes(message.id)
  //   );
  //   const messagesToDelete = existingMessages.filter(
  //     (message) =>
  //       message.conversationId === conversationId &&
  //       message.ack !== MessageAck.ACK_PENDING &&
  //       !receivedMessageIds.includes(message.id)
  //   );
  //   const messagesToUpdate = messages.filter(
  //     (message) =>
  //       message.conversationId === conversationId &&
  //       existingMessageIds.includes(message.id)
  //   );

  //   await this.transaction('rw', this.messages, async () => {
  //     if (messagesToAdd.length > 0) {
  //       await this.messages.bulkAdd(messagesToAdd);
  //     }

  //     if (messagesToDelete.length > 0) {
  //       const messageIdsToDelete = messagesToDelete.map((message) => message.id);
  //       await this.messages.where('id').anyOf(messageIdsToDelete).delete();
  //     }

  //     if (messagesToUpdate) {
  //       for (const message of messagesToUpdate) {
  //         await this.messages.put(message);
  //       }
  //     }
  //   });
  // }
  // async updateMessage(message: IMessage) {
  //   const id = message.id;
  //   await this.messages
  //     .where('id')
  //     .equals(id)
  //     .modify({ ...message });
  // }
  // async setMessage(message: IMessage) {
  //   const messages = await this.messages.where('id').equals(message.id).toArray();
  //   if (messages.length > 0) {
  //     await this.updateMessage(message);
  //   } else {
  //     await this.messages.add(message);
  //   }
  // }

  // async setMessages(messages: IMessage[]) {
  //   const existingMessages = await this.messages.toArray();
  //   const existingMessagesId = existingMessages.map((message) => message.id);
  //   const newMessages = messages.filter(
  //     (message) => !existingMessagesId.includes(message.id)
  //   );
  //   await this.messages.bulkAdd(newMessages);
  // }
  async getAllMessages(conversationId: string) {
    return await this.messages
      .where('conversationId')
      .equals(conversationId)
      .toArray();
  }
  // End Messages Section
}

const db = new ChatDatabase();

export default db;
