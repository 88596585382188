// Components/ui
import { Box, Select, TextField, styled, IconButton } from '@mui/material';

export const StyledReminderItemContainer = styled(Box)(() => ({
  marginBottom: '6px',
  padding: '8px 6px',
  border: '1px solid lightgray',
  borderRadius: '3px',
}));
export const StyledReminderInputsContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  userSelect: 'none',
}));
export const StyledReminderTextField = styled(TextField)(() => ({
  width: '13%',
}));
export const StyledReminderSelect = styled(Select)(() => ({
  width: '23%',
}));
export const StyledReminderActionsContainer = styled(Box)(() => ({
  display: 'flex',
  gap: 1,
  width: '100%',
  alignItems: 'center',
}));
export const StyledReminderDeleteIconButton = styled(IconButton)(() => ({
  ml: 'auto',
}));
