import { createSlice } from '@reduxjs/toolkit';
// Types
import { NotificationsState } from './types/NotificationsState';

const initialState: NotificationsState = {
  notifications: [],
  numberOfUnreadNotifications: 0,
  status: 'idle',
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
});

export default notificationsSlice.reducer;
