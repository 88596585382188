// Components/ui
import {
  Box,
  Typography
} from "@mui/material"

interface Props {
  text: string
}

const Text = ({ text }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "2.5rem",
        overflow: "hidden",
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "0%",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.disabled,
            fontSize: ".8rem",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default Text