import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { FormState } from './types/FormState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewForm } from './types/NewForm';
// Service
import formService from './formService';

const initialState: FormState = {
  forms: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchForms = createAsyncThunk(
  'form/fetchForms',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await formService.fetchForms(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const createForm = createAsyncThunk(
  'form/createform',
  async (form: NewForm, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await formService.createForm(jwtToken, URL_CONVERSATIONS, form);

    return response;
  }
);

export const deleteForm = createAsyncThunk(
  'form/deleteForm',
  async (formId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await formService.deleteForm(
      jwtToken,
      URL_CONVERSATIONS,
      formId
    );

    return response;
  }
);

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchForms.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchForms.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.forms = action.payload;
      })
      .addCase(createForm.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createForm.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.forms.push(action.payload);
      })
      .addCase(deleteForm.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteForm.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.forms = state.forms.filter((form) => form.id !== action.payload);
      });
  },
});

const selectFormState = (state: RootState) => state.Form;
export const selectAllForms = createSelector(
  selectFormState,
  (state) => state.forms
);
export const selectFormsFetchStatus = createSelector(
  selectFormState,
  (state) => state.status.fetch
);
export const selectFormCreateStatus = createSelector(
  selectFormState,
  (state) => state.status.create
);
export const selectFormDeleteStatus = createSelector(
  selectFormState,
  (state) => state.status.delete
);

export default formSlice.reducer;
