import { useContext } from 'react';
// Components/ui
import { Box, IconButton, Modal } from '@mui/material';
// Components
import { Body, Header } from './components';
// Icons
import { Close, Minimize } from '@mui/icons-material';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import EmailMessageProvider from './context/EmailMessageProvider';

interface EmailWriterProps {
  handleClose: () => void;
}

const EmailWriter = ({ handleClose }: EmailWriterProps) => {
  const { openEmailModal, setOpenEmailModal, resetEmailFields } =
    useContext(messagesContext);

  const handleCloseModal = () => {
    resetEmailFields();
    setOpenEmailModal(false);
  };

  const handleMinimize = () => {
    setOpenEmailModal(false);
  };

  return (
    <EmailMessageProvider>
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Header handleClose={handleClose} setOpenModal={setOpenEmailModal} />
        {!openEmailModal && (
          <Box height="28rem" width="100%" padding={1}>
            <Body openModal={openEmailModal} />
          </Box>
        )}
        <Modal open={openEmailModal} onClose={handleMinimize}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '80vw',
                height: '80vh',
                backgroundColor: (theme) => theme.palette.background.default,
                borderRadius: '1rem',
              }}
            >
              <Box
                sx={{
                  height: '2rem',
                  width: '100%',
                  borderBottom: '1px solid',
                  borderColor: (theme) => theme.palette.divider,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton size="small" onClick={handleMinimize}>
                  <Minimize
                    sx={{
                      color: (theme) => theme.palette.text.disabled,
                    }}
                  />
                </IconButton>
                <IconButton size="small" onClick={handleCloseModal}>
                  <Close
                    sx={{
                      color: (theme) => theme.palette.text.disabled,
                    }}
                  />
                </IconButton>
              </Box>
              <Box height="calc(100% - 2rem)" width="100%" padding={1}>
                <Body openModal={openEmailModal} />
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </EmailMessageProvider>
  );
};

export default EmailWriter;
