import { useContext } from 'react';
// Router
import { useParams } from 'react-router-dom';
// Components/ui
import { Box, useTheme } from '@mui/material';
//Components
import { ConversationActions, ConversationInfo } from './components';
// Context
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';

const Header = () => {
  const conversationSelected = useSelector(selectConversationSelected);
  const { contactId } = useParams<{ contactId: string }>();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor={(theme) => theme.palette.background.default}
      color={(theme) => theme.palette.text.primary}
      sx={{
        maxHeight: '3rem',
        height: '100%',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
      }}
      px={1}
      borderBottom={`1px solid ${theme.palette.divider}`}
      position="relative"
    >
      {(contactId || conversationSelected.finalizedAt) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: '1',
            backgroundColor: `${theme.palette.background.default}90`,
            '&:hover': {
              cursor: 'not-allowed',
            },
          }}
        />
      )}
      <ConversationInfo />
      <ConversationActions />
    </Box>
  );
};

export default Header;
