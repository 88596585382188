import { useEffect, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Chip, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { Close, KeyboardArrowDown, UnfoldMore } from '@mui/icons-material';
// Types
import { MessageEmail } from '@trii/types/dist/Common/Messages';
// Components
import { ActionButtons, EmailDestination } from './components';

interface Props {
  email: MessageEmail;
  open: boolean;
  onClose: () => void;
  date: string;
}

const EmailDetails = ({ email, open, onClose, date }: Props) => {
  const { t } = useTranslation();
  const [emailHeight, setEmailHeight] = useState(6);

  useEffect(() => {
    let height = 0;
    if (email && email.cc && email.cc.length > 0) {
      height += 2;
    }
    if (email && email.bcc && email.bcc.length > 0) {
      height += 2;
    }
    if (email && email.attachments && email.attachments.length > 0) {
      height += 2;
    }
    setEmailHeight(emailHeight + height);
  }, [email]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        p={2}
        sx={{
          width: '60vw',
          height: '80vh',
          backgroundColor: (theme) => theme.palette.background.default,
          borderRadius: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            height: '2xrem',
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <IconButton onClick={onClose} size="small">
            <Close
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          height="calc(100% - 2rem)"
          width="100%"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '2rem',
            }}
          >
            <EmailDestination
              title="conversations.channel.email.from"
              list={email?.from}
            />
            <ActionButtons email={email} date={date} onClose={onClose} />
          </Box>
          <EmailDestination
            title="conversations.channel.email.to"
            list={email?.to}
          />
          {email?.cc && email?.cc.length > 0 && (
            <EmailDestination
              title="conversations.channel.email.cc"
              list={email?.cc}
            />
          )}
          {email?.bcc && email?.bcc.length > 0 && (
            <EmailDestination
              title="conversations.channel.email.bcc"
              list={email?.bcc}
            />
          )}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              gap: '0.5rem',
              height: '2rem',
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {t('conversations.channel.email.subject')}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              noWrap
              sx={{
                maxWidth: 200,
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {email?.subject}
            </Typography>
          </Box>
          {email?.attachments && email?.attachments.length > 0 && (
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              sx={{
                padding: '0.5rem 0',
                height: '2rem',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {t('conversations.channel.email.attachments')}
              </Typography>
              <Box display="flex" gap={1} flexWrap={'wrap'}>
                {email?.attachments.map((attachment) => (
                  <a
                    href={attachment.url}
                    target="_blank"
                    rel="noreferrer"
                    key={attachment.id}
                  >
                    <Chip
                      size="small"
                      label={attachment.filename}
                      sx={{
                        width: 'max-content',
                      }}
                    />
                  </a>
                ))}
              </Box>
            </Box>
          )}
          <Typography
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              height: `calc(100% - ${emailHeight}rem)`,
              width: '100%',
              wordBreak: 'break-word',
              fontSize: '0.8rem',
              color: (theme) => theme.palette.text.primary,
              borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              pt: 1,
            }}
            dangerouslySetInnerHTML={{ __html: email.bodyHtml }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailDetails;
