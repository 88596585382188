import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { SipState } from './types/SipState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewSip } from './types/NewSip';
// Service
import sipService from './sipService';

const initialState: SipState = {
  sips: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchSips = createAsyncThunk(
  'sip/fetchSips',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await sipService.fetchSips(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const createSip = createAsyncThunk(
  'sip/createSip',
  async (sip: NewSip, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await sipService.createSip(jwtToken, URL_CONVERSATIONS, sip);

    return response;
  }
);

export const deleteSip = createAsyncThunk(
  'sip/deleteSip',
  async (sipId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await sipService.deleteSip(jwtToken, URL_CONVERSATIONS, sipId);

    return response;
  }
);

const sipSlice = createSlice({
  name: 'sip',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSips.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchSips.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.sips = action.payload;
      })
      .addCase(createSip.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createSip.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.sips.push(action.payload);
      })
      .addCase(deleteSip.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteSip.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.sips = state.sips.filter((sip) => sip.id !== action.payload);
      });
  },
});

const selectSipState = (state: RootState) => state.Sip;
export const selectAllSips = createSelector(selectSipState, (state) => state.sips);
export const selectSipsFetchStatus = createSelector(
  selectSipState,
  (state) => state.status.fetch
);
export const selectSipCreateStatus = createSelector(
  selectSipState,
  (state) => state.status.create
);
export const selectSipDeleteStatus = createSelector(
  selectSipState,
  (state) => state.status.delete
);

export default sipSlice.reducer;
