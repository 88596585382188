import { useState, useEffect } from 'react';
// Utils
import { v4 as uuidv4 } from 'uuid';
import { isNumber } from 'utils/isNumber';
// Types
import { ChangeEvent } from 'react';
import { EventNotificationFront } from 'redux/features/activitiesSlice/types/EventNotificationFront';
import {
  EventNotificationPeriod,
  IEventNotification,
} from '@trii/types/dist/Calendar';
import { SelectChangeEvent } from '@mui/material';

export const useNotificationsInitialDef = {
  notifications: [],
  action: {
    addNotification: () => { },
    deleteNotification: (notificationId: string) => { },
    handleTimeFieldChange: (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      notificationId: string
    ) => { },
    handleTimePeriodChange: (
      event: SelectChangeEvent<unknown>,
      notificationId: string
    ) => { },
    handleReminderChange: (
      event: ChangeEvent<HTMLInputElement>,
      notificationId: string
    ) => { },
    resetNotifications: () => { },
  },
};

export type UseNotificationsType = {
  notifications: EventNotificationFront[];
  action: {
    addNotification: () => void;
    deleteNotification: (notificationId: string) => void;
    handleTimeFieldChange: (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      notificationId: string
    ) => void;
    handleTimePeriodChange: (
      event: SelectChangeEvent<unknown>,
      notificationId: string
    ) => void;
    handleReminderChange: (
      event: ChangeEvent<HTMLInputElement>,
      notificationId: string
    ) => void;
    resetNotifications: () => void;
  };
};

export function useNotifications(
  eventNotifications?: IEventNotification[]
): UseNotificationsType {
  const [notifications, setNotifications] = useState<EventNotificationFront[]>(
    eventNotifications ? eventNotifications : []
  );

  function addNotification() {
    const newNotification: EventNotificationFront = {
      id: uuidv4(),
      timeValue: 0,
      timePeriod: EventNotificationPeriod.MINUTE,
      sendToContact: false,
    };
    const updatedNotifications = notifications?.concat(newNotification);
    setNotifications(updatedNotifications);
  }
  function deleteNotification(notificationId: string) {
    const updatedNotifications = notifications?.filter(
      (notification) => notification.id !== notificationId
    );
    setNotifications(updatedNotifications);
  }
  function handleTimeFieldChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    notificationId: string
  ) {
    const numberValue = Number(event.target.value);

    if (!isNumber(numberValue)) return;

    const notificationToUpdate = notifications?.find(
      (notification) => notification.id === notificationId
    );

    if (notificationToUpdate) {
      const updatedNotification = {
        ...notificationToUpdate,
        timeValue: numberValue,
      };
      const updatedNotifications = notifications?.map((notification) =>
        notification.id === notificationId ? updatedNotification : notification
      );
      setNotifications(updatedNotifications);
    }
  }
  function handleTimePeriodChange(
    event: SelectChangeEvent<unknown>,
    notificationId: string
  ) {
    if (!isNumber(event.target.value)) return;

    const value = event.target.value;
    const notificationToUpdate = notifications?.find(
      (notification) => notification.id === notificationId
    );

    if (notificationToUpdate !== undefined) {
      const updtedNotification = { ...notificationToUpdate, timePeriod: value };
      const updatedNotifications = notifications?.map((notification) =>
        notification.id === notificationId ? updtedNotification : notification
      );
      setNotifications(updatedNotifications);
    }
  }
  function handleReminderChange(
    event: ChangeEvent<HTMLInputElement>,
    notificationId: string
  ) {
    const value = event.target.checked;
    const notificationToUpdate = notifications?.find(
      (notification) => notification.id === notificationId
    );
    if (notificationToUpdate) {
      const updtedNotification = { ...notificationToUpdate, sendToContact: value };
      const updatedNotifications = notifications?.map((notification) =>
        notification.id === notificationId ? updtedNotification : notification
      );
      setNotifications(updatedNotifications);
    }
  }

  function resetNotifications() {
    setNotifications([]);
  }

  useEffect(() => {
    if (eventNotifications) setNotifications(eventNotifications);
  }, [eventNotifications]);

  return {
    notifications,
    action: {
      addNotification,
      deleteNotification,
      handleTimeFieldChange,
      handleTimePeriodChange,
      handleReminderChange,
      resetNotifications,
    },
  };
}
