import { useContext } from 'react';
import { FilePreviewerContext } from 'features/Views/Conversations/context/FilePreviewerProvider/FilePreviewerContext';
import { messagesContext } from '../../../../../context/MessagesProvider/MessagesProvider';
// Components/ui
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FunctionalityButtons = () => {
  const { selectedFile } = useContext(FilePreviewerContext);
  const { endFileSelectorMode } = useContext(messagesContext);

  return (
    <Box
      sx={{
        width: '100%',
        height: '5%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '47%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton disableRipple onClick={endFileSelectorMode}>
          <CloseIcon />
        </IconButton>
      </Box>
      {selectedFile?.mimeType === 'text' && (
        <Box
          sx={{
            width: '53%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography>{selectedFile?.filename}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default FunctionalityButtons;
