import Icon from '@mdi/react';
import { mdiRobot } from '@mdi/js';

interface Props {
  color: string;
}

const ChatBotIcon = ({color}: Props) => {
  return (
    <Icon
    path={mdiRobot} size={1}
      color={color}
    />
  );
};

export default ChatBotIcon;