import { useState } from 'react';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
// Types
import { ReactionData } from 'redux/features/messagesSlice/types/ReactionData';
import { reactToMessage } from 'redux/features/messagesSlice/messagesSlice';
import { MessageReaction } from '@trii/types/dist/Common/Messages';
import { MessageReactionWithCount } from '../types/MessageReactionWithCount';

export type UseReactionsType = {
  // ref: React.RefObject<HTMLDivElement>;
  isOpenOnMessageId: string;
  countReactions: (reactions: MessageReaction[]) => MessageReactionWithCount[];
  handleToolClick: (messageId: string) => void;
  handleClose: () => void;
  handleReactionClick: (reactionData: ReactionData) => void;
};

export const useReactionsInitialState: UseReactionsType = {
  // ref: null,
  isOpenOnMessageId: null,
  countReactions: () => [],
  handleToolClick: () => {},
  handleClose: () => {},
  handleReactionClick: () => {},
};

export const useReactions = (): UseReactionsType => {
  const dispatch = useAppDispatch();

  const [isOpenOnMessageId, setIsOpenOnMessageId] = useState<string>(null);
  // const ref = useRef(null);

  function handleToolClick(messageId: string) {
    setIsOpenOnMessageId(messageId);
  }

  function handleClose() {
    setIsOpenOnMessageId(null);
  }

  async function handleReactionClick(reactionData: ReactionData) {
    await dispatch(reactToMessage(reactionData));
    setIsOpenOnMessageId(null);
  }

  function countReactions(reactions: MessageReaction[]): MessageReactionWithCount[] {
    const reactionCounts: { [key: string]: MessageReactionWithCount } = {};

    for (const reaction of reactions) {
      if (reaction.emoji) {
        if (reactionCounts[reaction.emoji]) {
          reactionCounts[reaction.emoji].amount += 1;
        } else {
          reactionCounts[reaction.emoji] = { ...reaction, amount: 1 };
        }
      }
    }

    return Object.values(reactionCounts);
  }

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       handleClose();
  //     }
  //   }

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [ref]);

  return {
    isOpenOnMessageId,
    handleToolClick,
    handleClose,
    countReactions,
    // ref,
    handleReactionClick,
  };
};
