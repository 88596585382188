import { useContext, useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectUploadMediaStatus } from 'redux/features/messagesSlice/messagesSlice';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components
import { Loader } from './components';
import { Markdown } from 'functions/Markdown';
// Icons
import { PlayCircle } from '@mui/icons-material';
// Types
import MediaFile from 'features/Views/Conversations/context/MessagesProvider/types/MediaFile';
import { MessageHeaderType } from '@trii/types/dist/Common/Messages';

type PhotoProps = {
  files: MediaFile[];
  handleTryAgain?: () => void;
  messageId: string;
  isLoaded: boolean;
  type: 'sticker' | 'file';
  templateFileType?: MessageHeaderType;
};

const Image = ({
  files,
  handleTryAgain,
  messageId,
  isLoaded,
  type,
  templateFileType,
}: PhotoProps) => {
  const { setOpenImageViewer, setImageViewerSrc, setCurrentIndex } =
    useContext(conversationsContext);
  const { getFileType, messageLoading } = useContext(messagesContext);

  const uploadImgStatus = useSelector(selectUploadMediaStatus);

  const [filesToRender, setFilesToRender] = useState<MediaFile[]>(files);
  const [imgSize, setImgSize] = useState<number>(15);
  const isLoading =
    uploadImgStatus === 'loading' && messageLoading.includes(messageId);

  // useEffect(() => {
  //   console.log('Files change on Image.tsx:', files);
  // }, [files]);

  useEffect(() => {
    switch (type) {
      case 'sticker':
        setImgSize(6);
        break;
      case 'file':
        if (files.length > 2 && files.length < 4) {
          setFilesToRender(files.slice(0, 2));
        } else if (files.length > 4) {
          setFilesToRender(files.slice(0, 4));
        }
        if (files.length >= 4) {
          setImgSize(10);
        } else {
          setImgSize(15);
        }
        break;
      default:
        break;
    }
  }, [files]);

  const handleOpenModal = (index: number) => {
    setOpenImageViewer(true);
    setImageViewerSrc(files);
    setCurrentIndex(index);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `${filesToRender.length > 1 ? '1fr 1fr' : '1fr'}`,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {(isLoading || (isLoaded !== undefined && !isLoaded)) &&
          uploadImgStatus !== 'succeeded' && (
            <Loader
              handleTryAgain={handleTryAgain}
              isLoading={isLoading}
              messageId={messageId}
              isLoaded={isLoaded}
            />
          )}
        {filesToRender?.map((image, i) => {
          const type = getFileType(image?.mimeType) || templateFileType;
          const isSticker = image?.mimeType === 'image/sticker';
          const imageSize = isSticker ? '150px' : `${imgSize}rem`;
          return (
            <Box
              key={i}
              position="relative"
              sx={{
                height: imageSize,
                width: imageSize,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '1px',
                  left: '1px',
                  width: 'calc(100% - 2px)',
                  height: 'calc(100% - 2px)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  display: `${
                    (((files.length > 2 && files.length < 4) || files.length > 4) &&
                      i === filesToRender.length - 1) ||
                    type === 'video' ||
                    type === MessageHeaderType.VIDEO
                      ? 'flex'
                      : 'none'
                  }`,
                }}
                onClick={() => handleOpenModal(i)}
              >
                {((files.length > 2 && files.length < 4) || files.length > 4) &&
                i === filesToRender.length - 1 ? (
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      color: (theme) => theme.palette.text.primary,
                    }}
                  >
                    +{files.length - filesToRender.length}
                  </Typography>
                ) : (
                  (type === 'video' || type === MessageHeaderType.VIDEO) && (
                    <IconButton
                      sx={{
                        color: 'rgba(255, 255, 255, 0.8)',
                        '&:hover': {
                          backgroundColor: 'rgba(0,0,0,0.8)',
                        },
                      }}
                    >
                      <PlayCircle
                        sx={{
                          width: '4rem',
                          height: '4rem',
                        }}
                      />
                    </IconButton>
                  )
                )}
              </Box>
              <CardMedia
                component={`${
                  type === 'image' || type === MessageHeaderType.IMAGE
                    ? 'img'
                    : 'video'
                }`}
                onClick={() => handleOpenModal(i)}
                sx={{
                  cursor: 'pointer',
                  padding: '1px',
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '2px',
                }}
                image={image?.url}
                alt={image?.caption}
                width="100%"
                height="100%"
              />
            </Box>
          );
        })}
      </Box>
      {files.length <= 1 && (
        <Typography
          variant="caption"
          sx={{
            fontSize: '13px',
            lineHeight: 1.5,
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            textAlign: 'justify',
            display: 'block',
            padding: '8px',
            color: (theme) => theme.palette.text.primary,
            whiteSpace: 'pre-line',
          }}
          dangerouslySetInnerHTML={{
            __html: Markdown(files[0]?.caption || '', false),
          }}
        />
      )}
    </Box>
  );
};

export default Image;
