import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { FacebookState } from './types/FacebookState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewFacebook } from './types/NewFacebook';
// Service
import facebookService from './facebookService';

const initialState: FacebookState = {
  facebooks: [],
  newFacebook: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchFacebooks = createAsyncThunk(
  'facebook/fetchFacebooks',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await facebookService.fetchFacebooks(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

export const createFacebook = createAsyncThunk(
  'facebook/createFacebook',
  async (facebook: NewFacebook, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await facebookService.createFacebook(
      jwtToken,
      URL_CONVERSATIONS,
      facebook
    );

    return response;
  }
);

export const deleteFacebook = createAsyncThunk(
  'facebook/deleteFacebook',
  async (facebookId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await facebookService.deleteFacebook(
      jwtToken,
      URL_CONVERSATIONS,
      facebookId
    );

    return response;
  }
);

const facebookSlice = createSlice({
  name: 'facebook',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFacebooks.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchFacebooks.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.facebooks = action.payload;
      })
      .addCase(createFacebook.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createFacebook.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.newFacebook = action.payload;
      })
      .addCase(deleteFacebook.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteFacebook.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.facebooks = state.facebooks.filter(
          (facebook) => facebook.id !== action.payload
        );
      });
  },
});

const selectFacebookState = (state: RootState) => state.Facebook;
export const selectAllFacebooks = createSelector(
  selectFacebookState,
  (state) => state.facebooks
);
export const selectFacebooksFetchStatus = createSelector(
  selectFacebookState,
  (state) => state.status.fetch
);
export const selectNewFacebook = createSelector(
  selectFacebookState,
  (state) => state.newFacebook
);
export const selectFacebookCreateStatus = createSelector(
  selectFacebookState,
  (state) => state.status.create
);
export const selectFacebookDeleteStatus = createSelector(
  selectFacebookState,
  (state) => state.status.delete
);

export default facebookSlice.reducer;
