import axios from 'axios';
import { ILabel } from '@trii/types/dist/Conversations';
import { NewLabel } from './types/NewLabel';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchLabels = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/labels`, config);
  return response.data;
};
const createLabel = async (jwt: string, URL: string, label: NewLabel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const labelJson = JSON.stringify(label);
  const response = await axios.post(`${URL}/labels`, labelJson, config);
  return response.data;
};
const deleteLabel = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/labels/${id}`, config);

  return id;
};
const updateLabel = async (jwt: string, URL: string, label: ILabel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const labelJson = JSON.stringify(label);

  const response = await axios.put(`${URL}/labels/${label.id}`, labelJson, config);
  return response.data;
};

const labelService = {
  fetchLabels,
  createLabel,
  deleteLabel,
  updateLabel,
};

export default labelService;
