import { IconButton, Tooltip } from "@mui/material";

interface Props {
  title: string;
  onClick: () => void;
  icon: React.ReactNode
}


const Actions = ({
  title,
  onClick,
  icon
}: Props) => (
  <Tooltip
    title={title}
    arrow
  >
    <IconButton
      onClick={onClick}
    >
      {icon}
    </IconButton>
  </Tooltip>
)

export default Actions;