import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { InstagramState } from './types/InstagramState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { NewInstagram } from './types/NewInstagram';
// Service
import instagramService from './instagramService';

const initialState: InstagramState = {
  instagrams: [],
  newInstagrams: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
};

export const fetchInstagrams = createAsyncThunk(
  'instagram/fetchInstagrams',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await instagramService.fetchInstagrams(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

export const createInstagram = createAsyncThunk(
  'instagram/createInstagram',
  async (instagram: NewInstagram, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await instagramService.createInstagram(
      jwtToken,
      URL_CONVERSATIONS,
      instagram
    );

    return response;
  }
);

export const deleteInstagram = createAsyncThunk(
  'instagram/deleteInstagram',
  async (instagramId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await instagramService.deleteInstagram(
      jwtToken,
      URL_CONVERSATIONS,
      instagramId
    );

    return response;
  }
);

const instagramSlice = createSlice({
  name: 'instagram',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchInstagrams.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchInstagrams.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.instagrams = action.payload;
      })
      .addCase(createInstagram.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createInstagram.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.newInstagrams = action.payload;
      })
      .addCase(deleteInstagram.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteInstagram.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.instagrams = state.instagrams.filter(
          (instagram) => instagram.id !== action.payload
        );
      });
  },
});

const selectInstagramState = (state: RootState) => state.Instagram;
export const selectAllInstagrams = createSelector(
  selectInstagramState,
  (state) => state.instagrams
);
export const selectInstagramsFetchStatus = createSelector(
  selectInstagramState,
  (state) => state.status.fetch
);
export const selectNewInstagrams = createSelector(
  selectInstagramState,
  (state) => state.newInstagrams
);
export const selectInstagramCreateStatus = createSelector(
  selectInstagramState,
  (state) => state.status.create
);
export const selectInstagramDeleteStatus = createSelector(
  selectInstagramState,
  (state) => state.status.delete
);

export default instagramSlice.reducer;
