import axios from 'axios';
import { NewWhatsApp } from './types/NewWhatsApp';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchWhatsApps = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/whatsApp`, config);
  return response.data;
};
const createWhatsApp = async (jwt: string, URL: string, whatsApp: NewWhatsApp) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const whatsAppJson = JSON.stringify(whatsApp);
  const response = await axios.post(
    `${URL}/channels/whatsApp`,
    whatsAppJson,
    config
  );
  return response.data;
};
const deleteWhatsApp = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/whatsApp/${id}`, config);

  return id;
};
const updateWhatsApp = async (jwt: string, URL: string, whatsApp: any) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const whatsAppJson = JSON.stringify(whatsApp);

  const response = await axios.put(
    `${URL}/channels/whatsApp/${whatsApp.id}`,
    whatsAppJson,
    config
  );
  return response.data;
};

const whatsAppService = {
  fetchWhatsApps,
  createWhatsApp,
  deleteWhatsApp,
  updateWhatsApp,
};

export default whatsAppService;
