import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { NewWebHook } from './types/NewWebHook';
import { WebHooksState } from './types/InitialState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
// Service
import webHooksService from './webHooksService';
import { RootState } from '../../rootReducer';
import { IWebhook } from '@trii/types/dist/Conversations'; 

const initialState: WebHooksState = {
  webHooks: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    update: 'idle',
    delete: 'idle',
  },
};

export const fetchWebHooks = createAsyncThunk(
  'webHooks/fetchWebHooks',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await webHooksService.fetchWebHooks(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

export const createWebHook = createAsyncThunk(
  'webHooks/createWebHook',
  async (webHook: NewWebHook, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await webHooksService.createWebHook(
      jwtToken,
      URL_CONVERSATIONS,
      webHook
    );

    return response;
  }
);

export const deleteWebHook = createAsyncThunk(
  'webHooks/deleteWebHook',
  async (webHookId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await webHooksService.deleteWebHook(
      jwtToken,
      URL_CONVERSATIONS,
      webHookId
    );

    return response;
  }
);

export const updateWebHook = createAsyncThunk(
  'webHooks/updateWebHook',
  async (webHook: IWebhook, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    await webHooksService.updateWebHook(jwtToken, URL_CONVERSATIONS, webHook);

    return webHook;
  }
);

const webHooksSlice = createSlice({
  name: 'webHooks',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWebHooks.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchWebHooks.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('fulfilled', action.payload);
        state.webHooks = action.payload;
      })
      .addCase(createWebHook.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createWebHook.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.webHooks.push(action.payload);
      })
      .addCase(deleteWebHook.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteWebHook.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.webHooks = state.webHooks.filter(
          (webHook) => webHook.id !== action.payload
        );
      })
      .addCase(updateWebHook.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateWebHook.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        const editWebHook = action.payload;
        state.webHooks = state.webHooks.map((webHook) => {
          if (webHook.id === editWebHook.id) {
            return editWebHook;
          }
          return webHook;
        });
      });
  },
});

const selectWebHooksState = (state: RootState) => state.WebHooks;
export const selectAllWebHooks = createSelector(
  selectWebHooksState,
  (state) => state.webHooks
);
export const selectWebHooksFetchStatus = createSelector(
  selectWebHooksState,
  (state) => state.status.fetch
);
export const selectWebHooksCreateStatus = createSelector(
  selectWebHooksState,
  (state) => state.status.create
);
export const selectWebHookDeleteStatus = createSelector(
  selectWebHooksState,
  (state) => state.status.delete
);
export const selectWebHookUpdateStatus = createSelector(
  selectWebHooksState,
  (state) => state.status.update
);

export default webHooksSlice.reducer;
