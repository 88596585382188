import { useEffect, useState, useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Context
import { audioMessagesContext } from '../../context/AudioMessagesProvider/AudioMessagesProvider';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Selector
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { selectUser } from 'redux/features/userSlice/userSlice';
// Utils
import { getNewWaveSurfer } from './utils/getNewWaveSurfer';
// Components/ui
import { ToggleAudioButton, SoundWave, PauseButton } from './components';
import Recorder from './components/Recorder/Recorder';
import { Box, IconButton, Fade } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
// ID
import ObjectID from 'bson-objectid';
// Types
import {
  MessageAck,
  MessageDirection,
  MessageType,
} from '@trii/types/dist/Common/Messages';
import db from 'db/db';
import { Mic } from '@mui/icons-material';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

const AudioBox = () => {
  const {
    currentTime,
    handleWaveformClick,
    isPlaying,
    isRecording,
    playAudio,
    startRecording,
    stopAudio,
    pauseRecording,
    waveformRef,
    recordedAudioURL,
    formatTime,
    setIsPlaying,
    setCurrentTime,
    handleDeleteRecordedAudio,
    recordedAudioBase64,
    isPaused,
  } = useContext(audioMessagesContext);
  const conversationSelected = useSelector(selectConversationSelected);

  const { endRecordAudioMode, sendMessage } = useContext(messagesContext);
  const [fadeIn, setFadeIn] = useState(false);
  const [isSendAudio, setIsSendAudio] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const spaceInfo = useSelector(selectSpaceInfo);
  const userInfo = useSelector(selectUser);
  const [duration, setDuration] = useState('');

  const handleSendAudio = async () => {
    const id = ObjectID().toString();
    const user = {
      ...userInfo,
      id: userInfo.uid,
      name: userInfo.username,
      isActive: true,
    };
    const newMessage = {
      id,
      spaceId: spaceInfo.id,
      conversationId: conversationSelected.id,
      timestamp: new Date(),
      userId: user.uid,
      audio: {
        id: ObjectID().toString(),
        url: recordedAudioBase64,
        mimeType: 'audio/webm',
        filename: 'audio.webm',
      },
      from: conversationSelected.contactInfo?.id,
      to: conversationSelected.remoteAddress,
      mentions: null,
      direction: MessageDirection.OUT,
      ackLogs: [],
      ack: MessageAck.ACK_PENDING,
      forwarded: false,
      remoteDeleted: false,
      type: MessageType.CHAT,
      messageReference: null,
      deleted: false,
      isLoaded: false,
      isHighlighted: false,
    };

    setFadeIn(false);
    endRecordAudioMode();
    sendMessage(newMessage);
  };

  const handleStopAndSendAudio = () => {
    if (isRecording) {
      pauseRecording();
      setIsSendAudio(true);
    } else {
      handleSendAudio();
    }
  };

  useEffect(() => {
    if (isSendAudio && recordedAudioURL) {
      handleSendAudio();
      return;
    }
    if (waveformRef.current && recordedAudioURL) {
      const newWaveForm = getNewWaveSurfer();

      waveformRef.current = newWaveForm;
      waveformRef.current.load(recordedAudioURL);
      waveformRef.current.on('ready', () => {
        const newDuration = waveformRef.current.getDuration();
        setDuration(formatTime(newDuration));
        setAudioDuration(newDuration);
      });
      waveformRef.current.on('finish', () => {
        setIsPlaying(false);
      });
      waveformRef.current.on('audioprocess', () => {
        const currentTime = waveformRef.current.getCurrentTime();
        setCurrentTime(formatTime(currentTime));
      });
    }
  }, [recordedAudioURL]);

  useEffect(() => {
    startRecording();
    setFadeIn(true);
  }, []);

  return (
    <Box
      display="flex"
      position="relative"
      width={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      {/* Control buttons */}
      <Box display={'flex'} gap={1}>
        <IconButton
          disableRipple
          onClick={handleDeleteRecordedAudio}
          sx={{ color: 'text.disabled' }}
        >
          <DeleteOutline />
        </IconButton>
        {isRecording ? (
          <PauseButton handlePauseRecording={pauseRecording} />
        ) : (
          <ToggleAudioButton
            isPlaying={isPlaying}
            playAudio={playAudio}
            stopAudio={stopAudio}
          />
        )}
      </Box>
      {isRecording ? (
        <Recorder isRecording={isRecording} audioDuration={audioDuration} />
      ) : (
        <SoundWave
          currentTime={currentTime}
          duration={duration}
          waveformRef={waveformRef}
          handleWaveformClick={handleWaveformClick}
        />
      )}
      {isPaused && (
        <IconButton
          disableRipple
          onClick={startRecording}
          sx={{ color: 'text.disabled' }}
        >
          <Mic />
        </IconButton>
      )}
      <IconButton
        disableRipple
        onClick={handleStopAndSendAudio}
        sx={{ color: 'text.disabled' }}
      >
        <SendIcon />
      </IconButton>
    </Box>
  );
};

export default AudioBox;
