// Translate
import { useTranslation } from "react-i18next"
// Components/ui
import {
  Box,
  Typography
} from "@mui/material"
// Icons
import { Image as ImageIcon } from "@mui/icons-material"
// Types
import { MessageImage } from "@trii/types/dist/Common/Messages"

interface ImageProps {
  image: MessageImage
}

const Image = ({ image }: ImageProps) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "1rem",
        gap: 1,
      }}
    >
      <ImageIcon
        sx={{
          color: (theme) => theme.palette.text.disabled,
          fontSize: "1rem",
          marginTop: ".2rem",
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.disabled,
            fontSize: ".8rem",
          }}
        >
          {image?.caption || t("conversations.message.image")}
        </Typography>
      </Box>
    </Box>
  )
}

export default Image