import { useTranslation } from 'react-i18next';
// Components/ui
import {
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Context
import { useContext } from 'react';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';

interface Props {
  data: any;
  index: number;
}
const FormItem = ({ data }: Props) => {
  const { sendFormMessage, formMessageLoading } = useContext(messagesContext);
  const itemLoading = formMessageLoading.some((id) => id === data.id);
  const otherItemLoading = !itemLoading && formMessageLoading.length > 0;

  const { t } = useTranslation();

  return (
    <ListItem
      secondaryAction={
        <ListItemText
          primary={
            <LoadingButton
              disabled={otherItemLoading}
              loading={itemLoading}
              onClick={() => sendFormMessage(data.id)}
              variant="contained"
            >
              {t('global.send')}
            </LoadingButton>
          }
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        />
      }
      sx={{
        padding: 0,
      }}
    >
      <ListItemButton divider>
        <ListItemText
          primary={data.name}
          secondary={data.description}
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
export default FormItem;
