// Components/ui
import { Box, CircularProgress } from '@mui/material';
import { ChatItem } from './components';
//Redux
import { useSelector } from 'react-redux';
import {
  selectChats,
  selectChatsFetchStatus,
} from 'redux/features/conversationsSlice/conversationsSlice';

interface ChatsProps {
  nameAscending: boolean;
}

const Chats = ({ nameAscending }: ChatsProps) => {
  const chats = useSelector(selectChats);
  const chatsFetchLoading = useSelector(selectChatsFetchStatus) === 'loading';

  // Sort chats based on nameAscending
  const sortedChats = chats?.slice().sort((a, b) => {
    if (nameAscending) {
      return a.chatName.localeCompare(b.chatName);
    } else {
      return b.chatName.localeCompare(a.chatName);
    }
  });

  const ShowChats = sortedChats?.map((chat) => (
    <ChatItem key={chat.id} chat={chat} />
  ));

  return (
    <Box
      width="100%"
      sx={{
        overflowY: 'auto',
        height: '100%',
        position: 'relative',
      }}
    >
      {!chatsFetchLoading ? (
        ShowChats
      ) : (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </Box>
  );
};

export default Chats;
