import { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import { IconButton } from '@mui/material';
// Icons
import { AccessTime, Check, DoneAll } from '@mui/icons-material';
// Types
import { IMessage, MessageAck } from '@trii/types/dist/Common/Messages';

type Props = {
  message: IMessage;
};

const MessageStatus = ({ message }: Props) => {
  const { setModalTitle, setOpenModalList } = useContext(conversationsContext);
  const { setMessage } = useContext(messagesContext);
  const { t } = useTranslation();
  const { ack } = message;

  const handleOpenModalList = () => {
    setModalTitle(t('conversations.message.status.title'));
    setOpenModalList(true);
    setMessage(message);
  };

  return (
    <IconButton
      size="small"
      onClick={handleOpenModalList}
      sx={{
        padding: "0px",
        "&:hover": {
          backgroundColor: "unset",
        },
      }}
    >
      {ack === MessageAck.ACK_PENDING && (
        <AccessTime
          sx={{
            fontSize: 15,
            color: "text.disabled",
          }}
        />
      )}
      {ack === MessageAck.ACK_SERVER && (
        <Check
          sx={{
            fontSize: 15,
            color: "text.disabled",
          }}
        />
      )}
      {ack === MessageAck.ACK_DEVICE && (
        <DoneAll
          sx={{
            fontSize: 15,
            color: "text.disabled",
          }}
        />
      )}
      {ack === MessageAck.ACK_ERROR && (
        <Check
          sx={{
            fontSize: 15,
            color: (theme) => theme.palette.error.main,
          }}
        />
      )}
      {ack === MessageAck.ACK_READ && (
        <DoneAll
          sx={{
            fontSize: 15,
            color: "#2196f3",
          }}
        />
      )}
    </IconButton>
  );
};

export default MessageStatus;
