import { EventNotificationPeriod } from '@trii/types/dist/Calendar';

export const selectOptions = [
  {
    title: 'minute',
    value: EventNotificationPeriod.MINUTE,
  },
  {
    title: 'hour',
    value: EventNotificationPeriod.HOUR,
  },
  {
    title: 'day',
    value: EventNotificationPeriod.DAY,
  },
];
