import { useContext } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, Divider, List } from '@mui/material';
import { Status } from './components';
// Moment
import moment from 'moment';

const MessageStatus = () => {
  const { setOpenModalList } = useContext(conversationsContext);
  const { message } = useContext(messagesContext);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenModalList(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <List
        sx={{
          width: '100%',
          overflow: 'auto',
        }}
      >
        {message.ackLogs.map((ackLog, index) => (
          <Status key={index} data={ackLog} />
        ))}
      </List>
      <Divider
        sx={{
          width: '100%',
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '.5rem',
        }}
      >
        <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          onClick={handleClose}
        >
          {t('global.close')}
        </Button>
      </Box>
    </Box>
  );
};

export default MessageStatus;
