import { ChannelType } from "@trii/types/dist/Common/Channels";

interface ChannelList {
  id: number;
  name: string;
}
export const channelList: ChannelList[] = [
  {
    id: ChannelType.EMAIL,
    name: 'conversations.channel.email.title',
  },
  {
    id: ChannelType.SMS,
    name: 'conversations.channel.sms',
  },
  {
    id: ChannelType.SIP,
    name: 'conversations.channel.sip',
  },
  {
    id: ChannelType.WEBCHAT,
    name: 'conversations.channel.webchat',
  },
  {
    id: ChannelType.WHATSAPP,
    name: 'conversations.channel.whatsapp',
  },
  {
    id: ChannelType.FACEBOOK,
    name: 'conversations.channel.facebook',
  },
  {
    id: ChannelType.INSTAGRAM,
    name: 'conversations.channel.instagram',
  },
  {
    id: ChannelType.MERCADOLIBRE,
    name: 'conversations.channel.mercadolibre',
  },
  {
    id: ChannelType.PBX,
    name: 'conversations.channel.pbx',
  },
  {
    id: ChannelType.FORM,
    name: 'conversations.channel.form',
  },
  {
    id: ChannelType.GOOGLEBUSINESS,
    name: 'conversations.channel.googlebusiness',
  }
]