import { ReactNode } from 'react';
// Components/ui
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

interface Props {
  title: string;
  icon: ReactNode;
  onClick: () => void;
}

const Item = ({
  title,
  icon,
  onClick,
}: Props) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={onClick}
      >
        <ListItemIcon
          sx={{
            color: 'text.disabled',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  )
}

export default Item;