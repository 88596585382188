import getRequestConfig from 'redux/functions/getRequestConfig';
import axios from 'axios';
// Types
import { IMessage, Template } from '@trii/types/dist/Common/Messages';
async function fetch(
  URL: string,
  jwt: string,
  channelId: string
): Promise<Template[]> {
  const config = getRequestConfig.basic(jwt);
  const response = await axios.get(`${URL}/${channelId}`, config);

  return response.data;
}

async function post(
  URL: string,
  jwt: string,
  template: Template
): Promise<IMessage> {
  const config = getRequestConfig.contentTypeJson(jwt);
  const response = await axios.post<IMessage>(
    `${URL}/Messages/templates`,
    template,
    config
  );

  return response.data;
}

export default {
  fetch,
  post,
};
