import axios from 'axios';
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
import { RootState } from '../../store';
// Types
import { InitialState, Group, NewGroup, IEditGroupData } from './types';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { UserInfo } from '@trii/types/dist/Users';

const initialState: InitialState = {
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
    edit: 'idle',
  },
  data: [],
  selectedGroup: null,
  dataContacts: [],
  selectedContact: null,
};

export const fetchGroup = createAsyncThunk<Group[]>(
  'groups/fetchGroup',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };
    const { data } = await axios.get(`${URL_CONVERSATIONS}/groups`, config);
    return data;
  }
);

export const fetchContacts = createAsyncThunk<UserInfo[]>(
  'contacts/fetchContacts',
  async (_, { dispatch }) => {
    const { jwtToken, URL_USERS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };
    const { data } = await axios.get(`${URL_USERS}/users`, config);

    return data;
  }
);

export const createGroup = createAsyncThunk(
  'groups/createGroup',
  async (newGroup: NewGroup, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const newGroupJSON = JSON.stringify(newGroup);
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `${URL_CONVERSATIONS}/groups`,
      newGroupJSON,
      config
    );

    return data as Group;
  }
);

export const deleteGroup = createAsyncThunk(
  'groups/deleteGroup',
  async (groupId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    await axios.delete(`${URL_CONVERSATIONS}/groups/${groupId}`, config);

    return groupId;
  }
);

export const editGroup = createAsyncThunk(
  'groups/editGroup',
  async (editData: IEditGroupData, { dispatch }) => {
    const { groupId, editedGroup } = editData;
    const editedGroupJson = JSON.stringify(editedGroup);
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.put(
      `${URL_CONVERSATIONS}/groups/${groupId}`,
      editedGroupJson,
      config
    );

    return editData;
  }
);

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    selectGroupById: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const group = state.data.find((group) => group.id === id);
      state.selectedGroup = group;
    },
    removeSelectedGroup: (state) => {
      state.selectedGroup = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroup.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchGroup.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchGroup.rejected, (state, action) => {
        state.status.fetch = 'idle';
      })
      .addCase(createGroup.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.data.push(action.payload);
      })
      .addCase(deleteGroup.pending, (state, action) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        const deletedGroupId = action.payload;

        state.status.delete = 'succeeded';
        state.data = state.data.filter((group) => group.id !== deletedGroupId);
      })
      .addCase(editGroup.pending, (state, action) => {
        state.status.edit = 'loading';
      })
      .addCase(editGroup.fulfilled, (state, action) => {
        const { editedGroup, groupId } = action.payload;
        const editedGroupIndex = state.data.findIndex(
          (group) => group.id === groupId
        );

        state.status.edit = 'succeeded';
        state.data[editedGroupIndex].name = editedGroup.name;
        state.data[editedGroupIndex].description = editedGroup.description;
        state.data[editedGroupIndex].users = editedGroup.users;
      })
      .addCase(fetchContacts.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.dataContacts = action.payload;
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.status.fetch = 'idle';
      });
  },
});

const selectGroup = (state: RootState) => state.Group;
export const selectGroupData = createSelector(
  selectGroup,
  (groups: InitialState) => groups.data
);
export const selectContactData = createSelector(
  selectGroup,
  (contacts: InitialState) => contacts.dataContacts
);
export const selectGroupStatus = createSelector(
  selectGroup,
  (groups: InitialState) => groups.status.fetch
);
export const selectContactsStatus = createSelector(
  selectGroup,
  (groups: InitialState) => groups.status.fetch
);
export const selectGroupCreateStatus = createSelector(
  selectGroup,
  (groups: InitialState) => groups.status.create
);
export const selectSelectedGroup = createSelector(
  selectGroup,
  (groups: InitialState) => groups.selectedGroup
);
export const selectGroupDeleteStatus = createSelector(
  selectGroup,
  (groups: InitialState) => groups.status.delete
);
export const selectGroupEditStatus = createSelector(
  selectGroup,
  (groups: InitialState) => groups.status.edit
);
export const getGroup = (groupId: string) =>
  createSelector(selectGroupData, (groups) =>
    groups.find((group) => group.id === groupId)
  );

// Actions
export const { selectGroupById, removeSelectedGroup } = groupsSlice.actions;

export default groupsSlice.reducer;
