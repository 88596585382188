import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { SmsEditState } from './types/SmsEditState';
import { PermissionReadMessagesPayload } from 'redux/types/PermissionReadMessagesPayload';
import { PermissionStartConversationsPayload } from 'redux/types/PermissionStartConversationsPayload';
import {
  SmsConectionType,
  Permission,
  IChannel
} from '@trii/types/dist/Common/Channels';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ISchedule } from '@trii/types/dist/Common/Schedules'; 
// Service
import smsEditService from './smsEditService';

const initialState: SmsEditState = {
  sms: null,
  smsName: null,
  status: {
    fetch: 'idle',
    update: 'idle',
  },
};

export const fetchSms = createAsyncThunk(
  'smsEdit/fetchSms',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await smsEditService.fetchSms(jwtToken, URL_CONVERSATIONS, id);

    return response;
  }
);

export const updateSms = createAsyncThunk<IChannel, void, { state: RootState }>(
  'smsEdit/updateSms',
  async (_, { getState, dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const sms = getState().SmsEdit.sms;
    await smsEditService.updateSms(jwtToken, URL_CONVERSATIONS, sms);

    return sms;
  }
);

const smsEditSlice = createSlice({
  name: 'smsEdit',
  initialState,
  reducers: {
    changeName(state, action: PayloadAction<string>) {
      state.sms.name = action.payload;
    },
    changeUseInCampaigns(state, action: PayloadAction<boolean>) {
      state.sms.sms.useInCampaigns = action.payload;
    },
    changeToken(state, action: PayloadAction<string>) {
      state.sms.sms.token = action.payload;
    },
    changeReOpenTime(state, action: PayloadAction<number>) {
      state.sms.sms.reOpenTime = action.payload;
    },
    changePauseBetweenMessages(state, action: PayloadAction<number>) {
      state.sms.sms.pauseBetweenMessages = action.payload;
    },
    changeReassign(state, action: PayloadAction<boolean>) {
      state.sms.sms.reassign = action.payload;
    },
    changeReassignGroup(state, action: PayloadAction<string>) {
      state.sms.sms.reassignGroupId = action.payload;
    },
    changeAutoResponseBusinessHours(state, action: PayloadAction<boolean>) {
      state.sms.sms.autoResponseBusinessHours = action.payload;
    },
    changeAutoResponseOutOfBusinessHours(state, action: PayloadAction<boolean>) {
      state.sms.sms.autoResponseOutOfBusinessHours = action.payload;
    },
    changeAutoResponseHoliday(state, action: PayloadAction<boolean>) {
      state.sms.sms.autoResponseHoliday = action.payload;
    },
    changeSchedule(state, action: PayloadAction<ISchedule>) {
      const schedule = action.payload;

      state.sms.sms.schedule = schedule;
    },
    changeConectionType(state, action: PayloadAction<SmsConectionType>) {
      state.sms.sms.conectionType = action.payload;
    },
    changeSynwayUser(state, action: PayloadAction<string>) {
      state.sms.sms.synwayUser = action.payload;
    },
    changeSynwayPass(state, action: PayloadAction<string>) {
      state.sms.sms.synwayPass = action.payload;
    },
    changeSynwayUrl(state, action: PayloadAction<string>) {
      state.sms.sms.synwayUrl = action.payload;
    },
    changeSynwayPort(state, action: PayloadAction<string>) {
      state.sms.sms.synwayPort = action.payload;
    },
    changeDinstarUser(state, action: PayloadAction<string>) {
      state.sms.sms.dinstarUser = action.payload;
    },
    changeDinstarPass(state, action: PayloadAction<string>) {
      state.sms.sms.dinstarPass = action.payload;
    },
    changeDinstarUrl(state, action: PayloadAction<string>) {
      state.sms.sms.dinstarUrl = action.payload;
    },
    changeDinstarPort(state, action: PayloadAction<string>) {
      state.sms.sms.dinstarPort = action.payload;
    },
    changeYeastarUser(state, action: PayloadAction<string>) {
      state.sms.sms.yeastarUser = action.payload;
    },
    changeYeastarPass(state, action: PayloadAction<string>) {
      state.sms.sms.yeastarPass = action.payload;
    },
    changeYeastarUrl(state, action: PayloadAction<string>) {
      state.sms.sms.yeastarUrl = action.payload;
    },
    changeYeastarPort(state, action: PayloadAction<string>) {
      state.sms.sms.yeastarPort = action.payload;
    },
    changeShortSmsToken(state, action: PayloadAction<string>) {
      state.sms.sms.shortSmsToken = action.payload;
    },
    addPermission: {
      prepare: (groupId: string): { payload: Permission } => ({
        payload: {
          groupId,
          readMessages: false,
          startConversations: false,
        },
      }),
      reducer: (state, action: PayloadAction<Permission>) => {
        state.sms.permissions.push(action.payload);
      },
    },
    removePermission(state, action: PayloadAction<string>) {
      const groupId = action.payload;
      const index = state.sms.permissions.findIndex(
        (permission) => permission.groupId === groupId
      );

      state.sms.permissions.splice(index, 1);
    },
    changePermissionReadMessages(
      state,
      action: PayloadAction<PermissionReadMessagesPayload>
    ) {
      const { permissionGroupId, readMessages } = action.payload;
      const permission = state.sms.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      ) as Permission;

      permission.readMessages = readMessages;
    },
    changePermissionStartConversation(
      state,
      action: PayloadAction<PermissionStartConversationsPayload>
    ) {
      const { permissionGroupId, startConversations } = action.payload;
      const permission = state.sms.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );

      permission.startConversations = startConversations;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSms.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchSms.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('Fetched Sms: ', action.payload);
        state.sms = action.payload;
        state.smsName = action.payload.name;
      })
      .addCase(updateSms.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateSms.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        state.smsName = action.payload.name;
      });
  },
});

const selectSmsEditState = (state: RootState) => state.SmsEdit;
export const selectSms = createSelector(selectSmsEditState, (state) => state.sms);
export const selectSmsName = createSelector(
  selectSmsEditState,
  (state) => state.smsName
);
export const selectSmsFetchStatus = createSelector(
  selectSmsEditState,
  (state) => state.status.fetch
);
export const selectSmsUpdateStatus = createSelector(
  selectSmsEditState,
  (state) => state.status.update
);

export const {
  changeName,
  changeUseInCampaigns,
  changeToken,
  changeReOpenTime,
  changePauseBetweenMessages,
  changeReassign,
  changeReassignGroup,
  changeAutoResponseBusinessHours,
  changeAutoResponseOutOfBusinessHours,
  changeAutoResponseHoliday,
  changeSchedule,
  changeConectionType,
  changeSynwayUser,
  changeSynwayPass,
  changeSynwayUrl,
  changeSynwayPort,
  changeDinstarUser,
  changeDinstarPass,
  changeDinstarUrl,
  changeDinstarPort,
  changeYeastarUser,
  changeYeastarPass,
  changeYeastarUrl,
  changeYeastarPort,
  changeShortSmsToken,
  addPermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
} = smsEditSlice.actions;

export default smsEditSlice.reducer;
