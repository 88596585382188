// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Chip } from '@mui/material';

interface Props {
  day: string;
  today: string;
  yesterday: string;
  containerRef: React.RefObject<HTMLDivElement>;
}

const Date = ({
  day,
  today,
  yesterday,
  containerRef,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Chip
        label={
          day === today ? (
            t('conversations.today').toUpperCase()
          ) : (
            day === yesterday ? (
              t('conversations.yesterday').toUpperCase()
            ) : (
              day
            )
          )
        }
        sx={{
          backgroundColor: 
          //@ts-ignore
          (theme) => theme.palette.background.dropdownMenu,
          fontWeight: 'normal',
          fontSize: '1em',
          color: (theme) => theme.palette.text.secondary,
          borderRadius: '0.3rem',
          height: 'max-content',
          padding: '.2rem'
        }}
      />
    </Box>
  )
}

export default Date